import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit, OnChanges  
{
  @Input('Data') data
  status
  dataStatus
  statusValue
  listData
  dataSource = new MatTableDataSource();
  dataSourceState = new MatTableDataSource();

  constructor() 
  { }
  ngOnChanges(changes: SimpleChanges): void 
  {
   
  }

  ngOnInit(): void 
  {
    const stRes= this.data?.["children"]?.metadata?.states || [];

    this.status  = this.getStatus(stRes);
    this.listData =  this.data?.["children"]?.["files"];
    
  }
  
  
  getStatus(status) 
  {
    let res;
    const sortedindex = Object.values(status).sort(
      (first, second) => 0 - (first["index"] > second["index"] ? -1 : 1)
    );

    res = sortedindex;

    return res;

    
  }

  loadDataSource(status: string) 
  {
    const listeData = this.listData?.filter((res) => 
    {
      return res?.properties?.state == status;
    });

    return listeData;
  }
}
