import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumn, TableRow } from 'src/design-system/core/interfaces/table';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-details',
  templateUrl: 'bootstrap/document-details.component.html',
  styleUrls: ['bootstrap/document-details.component.scss'],
})
export class DocumentDetailsComponent implements OnInit 
{
   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();

   title: string = environment.stringsFile.docInfos;
   currentTab: number = 0;
   step: number = 0;

   columns: TableColumn[] = [
     {
       id: 'title',
       type: 'TextWithIcon',
       width: 260,
     },
     {
       id: 'client',
       type: 'Text',
       label: 'Client',
       canSort: true,
     },
     {
       id: 'lastupdate',
       type: 'Date',
       label: 'Last update',
       canSort: true,
     },
     {
       id: 'owner',
       type: 'Text',
       label: 'Owner',
       canSort: true,
     },
     {
       id: 'state',
       type: 'Text',
       label: 'State',
       canSort: true,
     },
     {
       id: 'version',
       type: 'Text',
       label: 'Version',
       canSort: true,
     },
     {
       id: 'menu',
       type: 'Menu',
       width: 55,
     },
   ];
   data: TableRow[][] = [
     [
       {
         id: 'title',
         value: 'Content instagram',
         iconImg: '/assets/images/icon-word.svg',
       },
       {
         id: 'client',
         value: 'Abdel A',
       },
       {
         id: 'lastupdate',
         value: '11/09/2022',
       },
       {
         id: 'owner',
         value: 'Simo YY',
       },
       {
         id: 'state',
         value: 'Done',
       },
       {
         id: 'version',
         value: '1.0.0',
       },
       {
         id: 'menu',
         value: '',
         menuItems: [
           {
             label: 'Edit',
             callback: function () 
             {
               console.log('Edit');
             },
           },
           {
             label: 'Delete',
             callback: function () 
             {
               console.log('Delete');
             },
           },
         ],
       },
     ],
     [
       {
         id: 'title',
         value: 'Content instagram',
         iconImg: '/assets/images/icon-word.svg',
       },
       {
         id: 'client',
         value: 'Abdel B',
       },
       {
         id: 'lastupdate',
         value: '11/09/2022',
       },
       {
         id: 'owner',
         value: 'Simo YY',
       },
       {
         id: 'state',
         value: '2',
       },
       {
         id: 'version',
         value: '1.0.0',
       },
       {
         id: 'menu',
         value: '',
         menuItems: [
           {
             label: 'Edit',
             callback: function () 
             {
               console.log('Edit');
             },
           },
           {
             label: 'Delete',
             callback: function () 
             {
               console.log('Delete');
             },
           },
         ],
       },
     ],
     [
       {
         id: 'title',
         value: 'Content instagram',
         iconImg: '/assets/images/icon-word.svg',
       },
       {
         id: 'client',
         value: 'Abdel C',
       },
       {
         id: 'lastupdate',
         value: '11/09/2022',
       },
       {
         id: 'owner',
         value: 'Simo YY',
       },
       {
         id: 'state',
         value: '1',
       },
       {
         id: 'version',
         value: '1.0.0',
       },
       {
         id: 'menu',
         value: '',
         menuItems: [
           {
             label: 'Edit',
             callback: function () 
             {
               console.log('Edit');
             },
           },
           {
             label: 'Delete',
             callback: function () 
             {
               console.log('Delete');
             },
           },
         ],
       },
     ],
   ];
   history: TableRow[][] = [
     [
       {
         id: 'title',
         value: 'Content instagram',
         iconImg: '/assets/images/icon-word.svg',
       },
       {
         id: 'client',
         value: 'Abdel B',
       },
       {
         id: 'lastupdate',
         value: '11/09/2022',
       },
       {
         id: 'owner',
         value: 'Simo YY',
       },
       {
         id: 'state',
         value: '2',
       },
       {
         id: 'version',
         value: '1.0.0',
       },
       {
         id: 'menu',
         value: '',
         menuItems: [
           {
             label: 'Edit',
             callback: function () 
             {
               console.log('Edit');
             },
           },
           {
             label: 'Delete',
             callback: function () 
             {
               console.log('Delete');
             },
           },
         ],
       },
     ],
     [
       {
         id: 'title',
         value: 'Content instagram',
         iconImg: '/assets/images/icon-word.svg',
       },
       {
         id: 'client',
         value: 'Abdel C',
       },
       {
         id: 'lastupdate',
         value: '11/09/2022',
       },
       {
         id: 'owner',
         value: 'Simo YY',
       },
       {
         id: 'state',
         value: '1',
       },
       {
         id: 'version',
         value: '1.0.0',
       },
       {
         id: 'menu',
         value: '',
         menuItems: [
           {
             label: 'Edit',
             callback: function () 
             {
               console.log('Edit');
             },
           },
           {
             label: 'Delete',
             callback: function () 
             {
               console.log('Delete');
             },
           },
         ],
       },
     ],
   ];

   constructor() 
   {}

   closeModal() 
   {
     this.close.emit();
   }
   ngOnInit(): void 
   {}
}
