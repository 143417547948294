import { Component, OnInit } from '@angular/core';
// import { ConfigService } from './../../config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState, OauthService } from '../../services/oauth.service';
@Component({
  selector: 'app-handle-success',
  templateUrl: './handle-success.component.html',
})
export class HandleSuccessComponent implements OnInit 
{

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: OauthService
  ) 
  { }
  token: AuthState
  redirectTo = '' //this.configService.google.successLoginRedirectTo || '/auth/success'
  ngOnInit() 
  {
    this.token = this.route.snapshot.params['token'];
    if (this.token)
    {
      this.authService.state = this.token
    }


    const redirectTo = localStorage.getItem('redirectTo')

    this.router.navigate([redirectTo ? redirectTo : this.redirectTo]);
    localStorage.removeItem('redirectTo')

  }









}
