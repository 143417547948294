
<mat-card class="example-card" >
  <mat-card-title> <span class="md-headline">{{'documentRevisions' | translate}}</span> </mat-card-title>
  <mat-card-content>
    <div *ngIf="revisions?.length> 0">
      <mat-nav-list  *ngFor="let revision of revisions">
        <mat-list-item (click)="revisionDrive(revision?.webViewLink)">
          <img src="{{revision.iconLink}}" style="margin-left:10px">
          <div class="linkItem"> <span style="margin-left:15px"> {{revision.name}} </span> </div>
        </mat-list-item>
      </mat-nav-list>
    </div>
    <div *ngIf="revisions?.length ===0" style="margin-top: 18px;">
      <span style="text-align: center;">pas de révisions</span>
    </div>
  </mat-card-content>

  
</mat-card>

