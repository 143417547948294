import { Injectable } from '@angular/core';
import { TableRowFile } from "../../models/table/tableRowFile.model";
import { Space,FileItem } from "../../models/space/space.model";

import { DebugLog } from "src/modules/app-common/services/log/log.service"
import { FieldDesc } from 'src/modules/form-manager/modeles/field-desc.model';
const trace = DebugLog.build("space search");

// import {FieldDesc,FieldDescEnum} from "../../../form-manager/modeles/field-desc.model";

@Injectable({
  providedIn: 'root'
})

export class SpaceSearchService
{
  constructor() 
  { }

  getSpaceTable(space : Space)
  {
    return new SpaceTable(space);
  }
}

export class SpaceSearchFacet
{
  options : [{}];
  fname : string;
  label : string;

  constructor(fname,desc)
  {
    this.fname = fname;
    this.label = desc.label;
  }

  getLabel() 
  {
    return this.label || this.fname;
  }

  addOption(value,label) 
  {
    this.options.push({value,label})
  }

  addOptions(options) 
  {
    this.options = options;
  }

  getOptions() 
  {
    return this.options;
  }
}

export class SpaceTable
{
  space : Space;
  items = [];
  files = [];
  view:string;
  searchForm = {};
  enumValuesByFname = {};
  searchFacets : Record<string,SpaceSearchFacet> = {};
  searchFields = [];
  metadata;

  groupField;
  itemsGroups;
  sortField;
  reverseOrder;

  i8n = { _ : (s) => s}

  constructor(space : Space)
  {
    this.space = space;

    this.files = space.getFiles();
    const children = space.getChildren();

    this.metadata = children?.metadata || {};

    this.initSpaceData(this.files,space)
  }

  getItems() 
  {
    return this.items;
  }

  getEnumValues(fname) 
  {
    return this.space.getEnumValues(fname);
  }

  getSearchForm(location='main')
  {
    return this.searchForm;
  }

  getSearchFacets() : Record<string,SpaceSearchFacet>
  {
    return this.searchFacets;
  }

  getFieldDesc(fname : string) 
  {
    return this.metadata.fields[fname] || null;
  }

  getFacetInfos(fname : string) 
  {
    return this.space.getFacetInfos(fname);
  }

  setFormValue(fname,field) 
  {
    this.searchForm[fname] = field && field.value || field || "";
  }

  initSpaceData(files,space: Space)
  {
    this.items = [...files];

    // list fields in search form
    this.searchForm = {};
    this.searchFields = [];

    // display states ?
    const hasStates = space.hasStates();

    let fieldDescs = space.getFieldDescs();

    if(!fieldDescs)
    {
      fieldDescs = {};
    }

    const facets = space.getFacetsFields();

    if(hasStates)
    {
      fieldDescs['state'] = new FieldDesc({
        label:this.i8n._('State'),
        type:'string'
      },'state');
    }

    fieldDescs['owner'] = new FieldDesc({
      label:this.i8n._('Owner'),
      type:'string'
    },'owner');

    const enumValues = {};

    // manage states : group files by states groups (with colours)
    const colorLookup = {};

    if(hasStates)
    {
      const stateFname = 'state';

      fieldDescs[stateFname].enumValues = enumValues[stateFname] = {data:{}};
      fieldDescs[stateFname].groupBy = {};
      colorLookup[stateFname]={};

      const states = space.getStates();

      for (const state of states)
      {
        const value = state.id();
        const label = state.label();
        const color = state.color();

        colorLookup[stateFname][value] = color;

        const stateDesc =
          {
            value: state.id(),
            label:state.label(),
            color : state.color(),
            description:state.description(),
            role:state.role(),
            empty:state.emptyListString(),
            icon:state.icon()
          };

        // add state information to the field metadata
        enumValues[stateFname].data[value] = stateDesc;
        fieldDescs[stateFname].groupBy[value] = {...stateDesc, items: []};
      }
    }

    const searchFieldsDescs = {};

    for (const p in facets)
    {
      this.searchForm[p] = '';
      this.searchFields.push(p);

      if(!enumValues[p])
      {
        enumValues[p] = {data:{}}
      }

      if(!searchFieldsDescs[p])
      {
        enumValues[p] = {data:{}};

        searchFieldsDescs[p] = {
          enumValues: enumValues[p]
        };
      }

      if(fieldDescs[p] && !fieldDescs[p].enumValues)
      {
        fieldDescs[p].enumValues = enumValues[p];
      }
      // enumValues[p].data['']={value:'',label:'All'};
    }

    // populate enum values
    const fnameCol = 'state';

    this.items.forEach( item =>
    {
      const appProps = item.appProperties || {};
      const props = {...item.properties,...appProps};

      this.searchFields.forEach(fname =>
      {
        // get field value for item
        const value = props[fname];

        if(!enumValues[fname])
        {
          enumValues[fname] = {data:{}};
        }

        if(value && value != '-' && value!="||")
        {
          const enums = enumValues[fname].data;

          const aV = value.split && value.split(',') || [value];

          aV.forEach(v =>
          {
            // add enum option to the search field
            enums[v]=
            {
              value:v,
              label:v
            };
          });
        }

        if(fname == fnameCol)
        // add color to item for value in state field
        {
          item.color = colorLookup[fname] && colorLookup[fname][value];
        }
      });
    });

    // set values as arrays for select filter
    this.searchFields.forEach(fname =>
    {
      // get values as [{value,label}]
      enumValues[fname].data = Object.values(enumValues[fname].data);

      // sort options by label
      enumValues[fname].data = enumValues[fname].data
        .sort( (a,b) =>
        {
				  return (a.label && (((a.label < b.label) && -1) || ((a.label > b.label) && 1) || 0)) || 0;
			  });

      const infos = this.space.getFacetInfos(fname);

      this.searchFacets[fname] = new SpaceSearchFacet(fname,infos);
      this.searchFacets[fname].addOptions(enumValues[fname].data);
    });

    this.enumValuesByFname = enumValues;

    // set display view
    this.view = 'list';
    const [view,fname1] = this.view.split('.');

    const sortOrder = this.space.getContentSortOrder();
    let {fname,reverse} = sortOrder || {};

    fname = fname1 || fname || this.searchFields[0];

    if(view == 'list')
    {
      this.sortBy(fname,reverse);
    }
    else if(view == 'group') // group
    {
      fname = fname || 'state';
      this.groupBy(fname);
    }
    else
    {
      if(hasStates)
      {
        fname = fname || 'state';
        this.groupBy(fname);
      }
      else
      {
        this.sortBy(fname);
      }
    }
  }

  /** Sort file items array */
  sortBy(f,reverse=null)
  {
    this.groupField = null;
    this.itemsGroups = null;
    if(f == 'state')
    {
      return this.groupBy(f);
    }

    if(reverse!==null)
    {
      this.sortField = f;
      this.reverseOrder = reverse;
    }
    else
    {
      if(f == this.sortField)
      {
        this.reverseOrder = !this.reverseOrder;
      }
      else
      {
        this.sortField = f;
        this.reverseOrder = false;
      }
    }

    this.items = itemOrderBy(this.files, {field:this.sortField,reverse:this.reverseOrder});

    trace.log("itemOrderBy "+this.sortField,this.items);
  }

  // populate itemsGroups[group]
  groupBy(f)
  {
    // get group and sort order (asc/desc)
    if(f == this.groupField)
    {
      this.reverseOrder = !this.reverseOrder;
    }
    else
    {
      this.groupField = f;
      this.reverseOrder = false;
    }

    // order items by group
    this.items = itemOrderBy(this.files, {field:this.groupField,reverse:this.reverseOrder});

    // reuse group order if any (ex. states)
    if(this.metadata.fields[f].groupBy)
    {
      this.itemsGroups = {...this.metadata.fields[f].groupBy };
    }
    else
    {
      this.itemsGroups = {};
    }

    // create groups
    for (const g in this.itemsGroups)
    {
      if(this.itemsGroups[g])
      {
        this.itemsGroups[g].items = [];
      }
    }

    // populate groups
    const by = this.groupField;

    this.items.forEach(item =>
    {
      const g = item.docProp(by);

      if(!this.itemsGroups[g])
      {
        this.itemsGroups[g] = {label:g, items: []};
      }

      this.itemsGroups[g].items.push(item);
    });
  }

  /** toggle sort icon */
  sortIcon(f : string)
  {
    if(f == this.sortField)
    {
      return this.reverseOrder ? 'arrow_drop_up' : 'arrow_drop_down';
    }

    return '';
  }

  filterFields;
  searchValues;

  filterBy()
  {
    if(this.filterFields)
    {
      for (const p in this.metadata)
      {
        this.searchForm[p] = '';
      }

      this.filterFields = false;
    }
    else
    {
      this.searchValues={};
      this.filterFields = true;
    }
  }

  // ============= FILTER ================
  FilterItems(items,formValues = null)
  {
    if(!formValues)
    {
      formValues = this.searchForm;
    }

    // get list of selected filters
    const filters = {};
    let isFiltered = false;

    for(const n in formValues)
    {
      const f = formValues[n]
      const v = (typeof f === 'object') && f.value || f;

      if(v && v!="--all--" && v !== "")
      {
        filters[n]=v;
        isFiltered = true;
      }
    }

    let items2;

    if(!isFiltered)
    {
      // no filters
      items2 = [...items];
    }
    else
    {
      // apply filters on file list
      items2 = items.filter(item =>
      {
        for (const p in filters)
        {
          const v = item.prop(p)||'';
          const selected = filters[p];
          const selectLc = selected.toLowerCase()  ;
          const vlc = v.toLowerCase();

          if(selectLc)
          {
            if(v.split)
            {
              // string => check multiple values
              const aV = vlc.split(',');

              if (aV.some(item => item.includes(selectLc)))
              {
                return true;
              }
            }
            else
            {
              // other types
              if(vlc != selectLc)
              {
                return false;
              }

            }
          }
        }

        return true;
      });

      return items2;
    }


    return items2;
  }

  /** checks if csv list of value includes a selected string */
  containsWord(aV : string, selected : string)
  {
    // Convert both strings to lowercase for case-insensitive comparison
    const selectedLower = selected.toLowerCase();

    // Split the aV string in lowercase into words
    return aV
      .toLowerCase()
      .split(' ') // csv -> words
      .some(word => word.includes(selectedLower));
  }

  /** Filter table of documents using form values */
  FilterTable(items : TableRowFile[],formValues = null)
  {
    if(!formValues)
    {
      formValues = this.searchForm;
    }

    // get list of selected filters
    const filters = {};
    let isFiltered = false;

    for(const n in formValues)
    {
      const f = formValues[n];
      const v = (typeof f === 'object') && f.value || f;

      if(v && v!="--all--" && v !== "")
      {
        filters[n]=v;
        isFiltered = true;
      }
    }

    let items2;

    if(!isFiltered)
    {
      // no filters
      items2 = items.map(row => row.columns());
    }
    else
    {
      // apply filters on file list
      items2 = [];
      items.forEach(item =>
      {
        for (const p in filters)
        {
          const v = item.prop(p).toLowerCase()||'';
          const selected = filters[p].toLowerCase();

          if(selected)
          {
            if(v.split)
            {
              // string => check multiple values
              const aV = v.split(',');

              if(!aV.includes(selected))
              {
                return false;
              }
            }
            else
            {
              // other types
              if(v != selected)
              {
                return false;
              }
            }
          }
        }

        items2.push(item.columns());
      });
    }

    return items2;
  }
}

// ================== SORT TOOLS =====================

// comp by name
const compByAlph = function(
  a : FileItem,
  b : FileItem,
  by : string,
  reverse=null)
{
  const direction = reverse ? -1 : 1;

  const alc = a.prop(by,'').toLowerCase(),
	  blc = b.prop(by,'').toLowerCase();

  const res = alc > blc ? direction : alc < blc ? -direction : 0;
  // trace.log("compByAlph","["+alc+"]","["+blc+"]"," res=",res);

  return res;
}

function itemOrderBy(items : FileItem[],options)
{
  const items1 = [...items];

  // 1) sort by name
  if(options.field)
  {
    items1.sort(function(a, b)
    {
      return compByAlph(a,b,options.field,options.reverse);
    });
  }

  return items1;

  /*
	if(options.reverse)
	{
		let items2 = [];
		items1.forEach(item => items2.unshift(item));
		return items2;
	}
	else
		return items1;
  */
}
