import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ListItem } from '@interfaces/list-item';
import { TableColumn, TableRow } from '@interfaces/table';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Crumb } from 'src/modules/app-common/components/bread-crumb/bread-crumb.component';
import { TableElement } from 'src/modules/app-common/models/table-element/table-element.model';
import { GedService, DateService } from 'src/modules/app-common/services';
import { GedTask } from 'src/modules/tasks/model/ged-task.model';
import { TaskService } from 'src/modules/tasks/services/task.service';
const CURRENT_URL = "/t/all/view/grid";

@Component({
  selector: 'app-task-grid',
  templateUrl: './task-grid.component.html',
  styleUrls: ['./task-grid.component.scss']
})
export class TaskGridComponent implements OnInit 
{

  displayedColumns: string[] = ['Name', 'Owner', 'Type', 'State'];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  @ViewChild('drawer') public drawer: MatDrawer;

  showMytasks = true;
  showTeamtasks = true;

  isLoading = true;
  bc = []
  tasksList;
  myTasks = [];
  teamTasks = []
  tempMytasks;
  tempTeamtasks;

  selectedElement: TableElement;
  gedSelectedElement;
  hostdomain = localStorage.host_domain

  constructor(
    private gedService: GedService,
    public dateService: DateService,
    private taskService: TaskService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,

  )
  {
  }

  async ngOnInit()
  {
    // await for the translate sce to be ready (after that, we can use instant() )
    await this.translate.get('Name').toPromise();
    const _home = environment.stringsFile.homeSideNav;
    const homeCrumb = new Crumb(_home,
      (event, self) => 
      {
        this.router
          .navigateByUrl("workspace/main");
      });



    this.bc = [homeCrumb, 'Tasks'];



    this.getTasks();
  }

  _(s : string) : string 
  {
    return this.translate.instant(s);
  }

  // tasks list view
  TasksListe: ListItem[] = [];
  TasksTeam: ListItem[] = []

  // tasks table view
  columns: TableColumn[];
  taskTableData: TableRow[][] = [];
  teamTaskTableData: TableRow[][] = []

  async getTasks()
  {
    this.isLoading = true;

    const tasksResponse = await this.taskService.getTasks();

    this.isLoading = false;
    this.tasksList = tasksResponse;

    // get tasks
    const myTasks = this.tasksList?.myTasks || [];
    const teamTasks = this.tasksList?.groupTasks || [];

    // prepare lists
    this.TasksListe = this.prepareTaskList(myTasks);
    this.TasksTeam = this.prepareTaskList(teamTasks);

    // prepare tables
    this.columns = this.getTableColumns();

    this.taskTableData = this.prepareTaskTable(myTasks);
    this.teamTaskTableData = this.prepareTaskTable(teamTasks);
  }

  prepareTaskList(tasks: GedTask[]): ListItem[]
  {
    const taskList: ListItem[] = []
    let index = 0;

    for (const t of tasks)
    {
      taskList.push( this.getDisplayListProperties(t,index++,CURRENT_URL) );
    }

    return taskList;
  }

  prepareTaskTable(tasks: GedTask[]): TableRow[][]
  {
    const taskTable: TableRow[][] = []

    for (const t of tasks)
    {
      taskTable.push( this.getDisplayDataTable(t,CURRENT_URL) );
    }

    return taskTable;
  }

  /*
  prepareTable(taskList: GedTask[]): TableElement[]
  {
    let element: TableElement;
    let resultList = [];

    for (let t of taskList)
    {
      this.TasksListe.push(this.getDisplayListProperties(t,CURRENT_URL));

      //this.dataTask.push(this.getDisplayDataTable(t,CURRENT_URL));

      element =
      {
        Name: t.getName(),
        Owner: t.getOwner(),
        Type: t.getType(),
        State: t.getState(),
        uid: t.getUid(),
        path: t.getPath(),
        date: t.getDate()
      }

      resultList.push(element)
    }

    return resultList
  }
  */

  // prepare infos for list view
  getDisplayListProperties(t : GedTask, index, prefixURL: string = "/t/all") : ListItem
  {
    const displayProperties =
    {
      id: index,
      // id: t.getUid(),
      subtitle: this._("from")+" : " + t.getOwner(),
      title: t.getName(),
      text:   this._('state') +" : " + t.getState(),
      image: t.iconUrl(),
      redirectTo: prefixURL+'/file/'+t.getUid(),

      menuItems :
      [
        {
          label: this._('Open'),
          callback: function ()
          {

            this.openDocument2(prefixURL , t.getUid());
          },
        }
      ]
    }

    return displayProperties;
  }

  // prepare infos for table view
  getDisplayDataTable(t: GedTask, prefixURL: string = "/t/all") : TableRow[]
  {
    const dataTable : TableRow[] =
      [
        {
          id: 'title',
          value: t.getName(),
          //iconImg: t.iconUrl(),
          link: prefixURL+'/file/'+t.getUid(),
        },
        {
          id: 'owner',
          value: t.getOwner() || "",
        },
        {
          id: 'type',
          value: t.getType() || "",
        },
        {
          id: 'state',
          value: t.getState(),
        },
        {
          id: 'menu',
          value: '',
          menuItems:
            [
              {
                label: this._('Ouvrir'),
                callback: function (row: TableRow)
                {
                  this.openDocument(self);
                },
              }
            ]
        }
      ];

    return dataTable;
  }

  openMyDrawer(element, list: any[])
  {
    this.selectedElement = element;

    for (const item of list)
    {
      if (element.Name == this.gedService.getFileName(item.path))
      {
        this.gedSelectedElement = item;

        if (!this.drawer.opened)
        {
          this.drawer.open();
        }
      }
    }
  }

  closeDrawer()
  {
    this.drawer.close();
  }

  openDocument(task : GedTask) 
  {
    this.taskService.openDocument(task,CURRENT_URL);
  }
  openDocument2(prefixURL ,id ) 
  {
    this.router.navigate([prefixURL+'/file/'+id], { queryParams: {d: localStorage.host_domain}});
  }

  rowIsSelected(row) 
  {
    //return this.selectedRowIds.has(id);
    return this.selectedElement == row;
  }

  toggleView(view: string)
  {
    this.router.navigate(['/t/all/view/table'])  ;     // this.activeTab = view;
  }

  activeTab: string = 'grid';

  globalMenu: {
    label: string;
    callback: Function;
  }[] =
      [
        {
          label: 'Sort by name',
          callback: function ()
          {
            console.log('Sort by name');
          },
        },
        {
          label: 'Sort by date',
          callback: function () 
          {
            console.log('Sort by date');
          },
        },
        {
          label: 'Sort by type',
          callback: function () 
          {
            console.log('Sort by type');
          },
        }
      ];

  getTableColumns() : TableColumn[]
  {
    const cols : TableColumn[] =
    [
      {
        id: 'title',
        type: 'TextWithIcon',
      },
      {
        id: 'owner',
        type: 'Text',
        label: this._('Owner'),
        canSort :true
      },
      {
        id: 'type',
        type: 'Text',
        label: this._('Type'),
        canSort :true
      },
      {
        id: 'state',
        type: 'Text',
        label: this._('State'),
        canSort :true
      },

      {
        id: 'menu',
        type: 'Menu',
      },
    ];

    return cols;
  }

}
