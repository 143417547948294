import { Pipe, PipeTransform } from '@angular/core';

import { ITableColumn } from './table.interface';
import { TTableColumn } from './table.type';


@Pipe({
  name: '_tableType',
  standalone: true
})
export class TableType implements PipeTransform 
{

  transform(value: ITableColumn[], id: string): TTableColumn 
  {
    return value.find((column: ITableColumn) => column.id === id)?.type ?? 'Text';
  }

}
