import { Component, OnInit, AfterViewInit } from '@angular/core';

import { environment } from 'src/environments/environment';

declare let google: any;
//import { accounts } from 'google-one-tap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit 
{


  loginURL
  constructor() 
  {
  }

  log(s, s2 = '') 
  {
    console.log("LoginComponent", s, s2);
  }

  ngOnInit(): void 
  {

    this.loginURL = environment.BACKEND_OAUTH_URL + '?' + environment.BACKEND_OAUTH_PARAMS.join('&')

  }

}
