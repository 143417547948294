import { Component, Input, OnInit } from '@angular/core';
import { WorkflowUserTask } from 'src/modules/workflow/models';
import { WorkflowService } from 'src/modules/workflow/services';

@Component({
  selector: 'app-doc-list-historic-approval-activity',
  templateUrl: './doc-list-historic-approval-activity.component.html',
  styleUrls: ['./doc-list-historic-approval-activity.component.scss']
})
export class DocListHistoricApprovalActivityComponent implements OnInit 
{

  @Input() tasks: WorkflowUserTask[];
  

  constructor(private workflowService: WorkflowService) 
  { }

  ngOnInit(): void 
  {
    //this.getPhotoByMail(this.tasks[0].from_user?.email  )
  }

  async getPhotoByMail(mail: string): Promise<string | null> 
  {
    const picture =  await this.workflowService.getImageByMail(mail);

    return picture;
  }

}
