import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tell-us-why',
  templateUrl: 'bootstrap/tell-us-why.component.html',
  styleUrls: ['bootstrap/tell-us-why.component.scss'],
})
export class TellUsWhyComponent implements OnInit 
{
   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();

   title: string = 'Tell us why';

   constructor() 
   {}
   closeModal() 
   {
     this.close.emit();
   }
   ngOnInit(): void 
   {}
}
