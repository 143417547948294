import { AdvancedListItem } from '@interfaces/advanced-list-item';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { EventAnswerComponent, EventsComponent } from '../../components';
import { EventItemComponent } from '../../components/event-item/event-item.component';
import { WorkspaceMainComponent } from '../../containers';
import { EventPerson } from './event-person.model';
import { UserEvent } from './user-event.model';
const gCalendarLinkTxt = environment.stringsFile.gCalendarLinkTxt ;
const gMeetLinkTxt = environment.stringsFile.gMeetLinkTxt  ;

export class GcalEvent 
{

  // event ==> event
  constructor(event) 
  {
    this.setId(event);
    this.setDate(event);
    this.setAltDate(event);
    this.setTitle(event);
    this.setDesc(event);
    this.setAttendees(event);
    this.setCanSeeGuests(event);
    this.setLink(event);
    this.setMeetLink(event)
    this.setSummary(event);
    this.setSelfAttendee();
    this.getDisplayProperties()
    this.getDisplayMeetings();
  }

  eventId: string;
  datetime: string;
  altDate: string;
  title: string;
  description: string;
  attendees: EventPerson[] = [];
  canSeeGuests: boolean;
  htmlLink: string;
  meetLink: string;

  monthList = environment.stringsFile.monthList;
  tomorrow = environment.stringsFile.tomorrow + ' ';
  today = environment.stringsFile.today + ' ';
   

    

  summary;
  displayMeetings;

  getDisplayMeetings() 
  {
    const self = this;

    this.displayMeetings = 
        [
          {
            id: this.getId(),
            title: this.getTitle(),
            text: this.getDate(),
            image: "/assets/images/icon-google-calendar.png",
            question: {
              label: environment.stringsFile.event_participation_question,
              callback: function (response: string, meeting: AdvancedListItem) 
              {
                EventsComponent.instance.meetOptionClickStatus(self, response)
                //console.log(response, meeting);
              },
            },
            /*
                if (status == 'needsAction') {
                    this.displayedSelfStatus = environment.stringsFile.event_participation_question;
                    }
                    if (status == 'accepted') {
                    this.displayedSelfStatus = environment.stringsFile.acceptStatus;
                    }
                    if (status == 'declined') {
                    this.displayedSelfStatus = environment.stringsFile.declineStatus;
                    }
                    if (status == 'tentative') {
                    this.displayedSelfStatus = environment.stringsFile.maybeStatus;
                    }
                */
            answers: [
              { label: environment.stringsFile.acceptStatus , value: 'accepted' },
              { label:  environment.stringsFile.declineStatus, value: 'declined' },
              { label:  environment.stringsFile.maybeStatus, value: 'tentative' },
            ],
            rightIcons: [
              {
                icon: '/assets/images/icon-external.svg',
                callback: function (meet: AdvancedListItem) 
                {
                  EventsComponent.instance.toLink(self.getLink())
                  console.log(meet);
                },
                width: 24,
              },
              {
                icon: '/assets/images/icon-google-meet.png',
                callback: function (meet: AdvancedListItem) 
                {
                  EventsComponent.instance.toLink(self.getHangoutLink())
                  console.log(meet);
                },
                width: 28,
              },

              {
                icon: '/assets/images/icon-info.svg',
                callback: function (meet: AdvancedListItem) 
                {
                  // EventItemComponent.instance.toLink(self.getHangoutLink())
                  //  WorkspaceMainComponent.instant.hello()
                  EventsComponent.instance.openPopup(self)
                  // console.log(meet);
                },
                width: 28,
              },
                     
            ],
              
            users: this.getUsers(this.getDisplayAttendees())
          }
        ];
    if(self.getHangoutLink() == null)
    {
      this.displayMeetings[0].rightIcons.splice(1, 1);
    }
   
    return this.displayMeetings;
  }

  setSummary(event) 
  {
    this.summary = event?.summary;
  }

  getStatus() 
  {
    return this.getAttendees().find(item => item.isSelf == true).getStatus()
  }

  acceptStatus = environment.stringsFile.acceptStatus;
  declineStatus = environment.stringsFile.declineStatus;
  maybeStatus = environment.stringsFile.maybeStatus;
  needsAction = '';

  meetingsResponse = 
    [
      { status: 'accepted', displayedStatus: this.acceptStatus },
      { status: 'declined', displayedStatus: this.declineStatus },
      { status: 'tentative', displayedStatus: this.maybeStatus },
      { status: 'needsAction', displayedStatus: this.needsAction }
    ]

  getDisplayStatus() 
  {
    return this.meetingsResponse.find(item => item.status == this.getStatus())?.displayedStatus;
  }

  getHtmlLink() 
  {
    return this.getLink()
  }

  getHangoutLink() 
  {
    return this.getMeetLink()
  }
  getSummary() 
  {
    return this.getTitle();
  }

  getId() 
  {
    return this.eventId;
  }

  setId(event) 
  {
    this.eventId = event.id;
  }

  getLink() 
  {
    return this.htmlLink;
  }

  setLink(event) 
  {
    this.htmlLink = event.htmlLink;
  }

  setMeetLink(event) 
  {
    this.meetLink = event?.hangoutLink || null;
  }

  getMeetLink() 
  {
    return this.meetLink;
  }

  setDate(event) 
  {
    let startDate, endDate;
    let temps = true;

    if (event.start.dateTime) 
    {
      startDate = event.start.dateTime; 
    }
    if (event.start.date) 
    {
      startDate = event.start.date; temps = false; 
    }


    if (event.end.dateTime) 
    {
      endDate = event.end.dateTime; 
    }
    if (event.end.date) 
    {
      endDate = event.end.date; temps = false; 
    }

    const newStartDate = this.convertDate(startDate, temps);
    const newEndDate = this.convertDate(endDate, temps);

    this.datetime = this.whenn(newStartDate, newEndDate, temps);
  }

  whenn(newStartDate, newEndDate, temps) 
  {
    let finalString = '';
    const startString = this.relativee(newStartDate);
    const endString = this.relativee(newEndDate);

    if (startString == endString) 
    {
      const relStart = startString;
      const relEnd = startString;

      if (!temps) 
      {
        if (relStart == 'today') 
        {
          newStartDate.date = [newStartDate.date[0], this.today];
          finalString += this.today;
        }
        if (relStart == 'tomorrow') 
        {
          newStartDate.date = [newStartDate.date[0], this.tomorrow];
          finalString += this.tomorrow;
        }

        if (relStart == 'other') 
        {
          if (newStartDate.date[2] + ' ' + this.monthList[newStartDate.date[1] - 1] == newEndDate.date[2] + ' ' + this.monthList[newEndDate.date[1] - 1]) 
          {
            finalString += newStartDate.date[2] + ' ' + this.monthList[newStartDate.date[1] - 1];
          } 
          else 
          {
            finalString += newStartDate.date[2] + ' ' + this.monthList[newStartDate.date[1] - 1] + ' - ';
            finalString += newEndDate.date[2] + ' ' + this.monthList[newEndDate.date[1] - 1];
          }
        }
      } 
      else 
      {
        if (relStart == 'today') 
        {
          finalString += this.today;
        }

        if (relStart == 'tomorrow') 
        {
          finalString += this.tomorrow;
        }

        if (relStart == 'other') 
        {
          if (newStartDate.date[2] + ' ' + this.monthList[newStartDate.date[1] - 1] == newEndDate.date[2] + ' ' + this.monthList[newEndDate.date[1] - 1]) 
          {
            finalString += newStartDate.date[2] + ' ' + this.monthList[newStartDate.date[1] - 1] + ' ';
            finalString += newStartDate.time.substring(0, 5);
            finalString += ' - ';
            finalString += newEndDate.time.substring(0, 5);

          }
          else 
          {
            finalString += newStartDate.date[2] + ' ' + this.monthList[newStartDate.date[1] - 1] + ' ';
            finalString += newStartDate.time.substring(0, 5);
            finalString += ' - ';
            finalString += newEndDate.date[2] + ' ' + this.monthList[newEndDate.date[1] - 1] + ' ';
            finalString += newEndDate.time.substring(0, 5);
          }
        }
      }
    }

    return finalString;
  }

  relativee(d) 
  {
    const dd = new Date();

    dd.setFullYear(d[0]);
    dd.setMonth(d[1]);
    dd.setDate(d[2]);

    const now = new Date();

    if (dd.toDateString() === now.toDateString()) 
    {
      return 'today';
    }

    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    if (tomorrow.getFullYear() == dd.getFullYear() && tomorrow.getMonth() == dd.getMonth() && tomorrow.getDate() == dd.getDate()) 
    {
      return 'tomorrow'; // date2 is one day after date1.
    }

    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    if (yesterday.getFullYear() == dd.getFullYear() && yesterday.getMonth() == dd.getMonth() && yesterday.getDate() == dd.getDate()) 
    {
      return 'yesterday'; // date2 is one day after date1.
    }

    return 'other';
  }

  convertDate(dateString, temps) 
  {
    if (temps) 
    {
      const dateArray = dateString?.split('T')[0]?.split('-');
      const timeArray = dateString?.split('T')[1]?.split('+')[0];
      const timeZone = dateString?.split('T')[1]?.split('+')[1]?.split(':')[0];

      return {
        date: dateArray,
        time: timeArray,
        zone: timeZone
      };
    } 
    else 
    {
      const dateArray = dateString?.split('T')[0]?.split('-');

      return {
        date: dateArray
      };
    }
  }

  parseDate(date) 
  {
    const parsed = Date.parse(date);

    if (!isNaN(parsed)) 
    {
      return parsed;
    }

    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
  }

  setAltDate(event) 
  {
    let startDate, endDate: string;
    let temps = true;

    if (event.start.dateTime) 
    {
      startDate = event.start.dateTime.slice(0, 10); 
    }
    if (event.start.date) 
    {
      startDate = event.start.date.slice(0, 10); temps = false; 
    }

    if (event.end.dateTime) 
    {
      endDate = event.end.dateTime.slice(0, 10); 
    }
    if (event.end.date) 
    {
      endDate = event.end.date.slice(0, 10); temps = false; 
    }

    this.altDate = startDate + ' - ' + endDate;
  }

  getAltDate() 
  {
    return this.altDate;
  }
  getDate() 
  {
    if (this.datetime.toLowerCase() == 'nan') 
    {
      return this.getAltDate(); 
    }

    return this.datetime;
  }

  setTitle(event) 
  {
    this.title = (event.summary) ? event.summary : environment.stringsFile.noTitleEvent;
  }

  getTitle() 
  {
    return this.title;
  }

  setDesc(event) 
  {
    this.description = event.description;
  }

  getDesc() 
  {
    return this.description;
  }

  setAttendees(event) 
  {
    let attendees = (event.attendees || []).map(person => new EventPerson(person)) || [];

    if (attendees.length == 0) attendees = ([event?.organizer] || []).map(person => new EventPerson(person)) || [];
    const sortedAttendees = []
    const selfPerson = attendees.find((person) => person.getIsSelf() == true);
    const tempAttendees = attendees.filter(person => person.isSelf == false)

    sortedAttendees.push(...(tempAttendees.filter(person => person.getStatus() != 'needsAction')));
    sortedAttendees.push(...(tempAttendees.filter(person => person.getStatus() == 'needsAction')));

    this.attendees.push(selfPerson);
    this.attendees.push(...sortedAttendees);
    this.setDisplayAttendees(this.attendees);
    this.setAdditionalAttendees(this.attendees);
  }

  additionalAttendees = ''
  getAdditionalAttendees() 
  {
    return this.additionalAttendees;
  }

  setAdditionalAttendees(attendees: any[]) 
  {
    const additionalAttendees = attendees.slice(3).map(person => person.getName()).join(' , ');

    this.additionalAttendees = additionalAttendees
  }

  selfAttendee: EventPerson
  setSelfAttendee() 
  {
    this.selfAttendee = this.getAttendees().find(person => 
    {
      if(!person)
      {
        return false;
      }

      return person.isSelf == true || null;
    });
  }

  getSelfAttendee() 
  {
    return this.selfAttendee;
  }

  setDisplayAttendees(attendees: any[]) 
  {
    this.displayAttendees = attendees.slice(0, 3)
  }

  displayAttendees;
  getDisplayAttendees() 
  {
    return this.displayAttendees;
  }

  getAttendees() 
  {
    return this.attendees;
  }

  setCanSeeGuests(event) 
  {
    const temp = event.guestsCanSeeOtherGuests;

    if (temp == false || temp == true) 
    {
      this.canSeeGuests = event?.guestsCanSeeOtherGuests; 
    }
    else 
    {
      this.canSeeGuests = true; 
    }
  }

  getCanSeeGuests() 
  {
    return this.canSeeGuests;
  }

  when(startDate, endDate, temps) 
  {
    let finalString = '';
    const start = new Date(moment(startDate).format('YYYY-MM-DD HH:mm:ss'));
    const end = new Date(moment(endDate).format('YYYY-MM-DD HH:mm:ss'));

    const st = this.relative(start);

    const e = this.relative(end);

    if (st == e) 
    {
      const rel = st;

      if (rel == 'today') 
      {
        finalString += this.today;
      }
      if (rel == 'tomorrow') 
      {
        finalString += this.tomorrow;
      }

      if (rel == 'other') 
      {
        finalString += start.getDate() + ' ' + start.toLocaleString('default', { month: 'long' }); + ' - ';
      }

      if (temps) 
      {
        const startTime = start.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');

        finalString += ' ' + startTime;
      }

      finalString += ' - ';
      if (end.getDate() != start.getDate() || end.toLocaleString('default', { month: 'long' }) != start.toLocaleString('default', { month: 'long' })) 
      {
        finalString += end.getDate() + ' ' + end.toLocaleString('default', { month: 'long' }); + ' - ';
      }

      if (temps) 
      {
        const endTime = end.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');

        finalString += ' ' + endTime;
      }

      // let s = d.toLocaleDateString() + " à " + d.toLocaleTimeString();
    } 
    else 
    {
    }

    return finalString;
  }

  relative(d) 
  {
    const now = new Date();

    if (d.toDateString() === now.toDateString()) 
    {
      return 'today';
    }

    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    if (tomorrow.getFullYear() == d.getFullYear() && tomorrow.getMonth() == d.getMonth() && tomorrow.getDate() == d.getDate()) 
    {
      return 'tomorrow'; // date2 is one day after date1.
    }

    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    if (yesterday.getFullYear() == d.getFullYear() && yesterday.getMonth() == d.getMonth() && yesterday.getDate() == d.getDate()) 
    {
      return 'yesterday'; // date2 is one day after date1.
    }

    return 'other';
  }

  displayProperties;
  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties = [
      {
        id: 'name',
        value: this.getTitle(),
        iconImg: 'assets/images/icon-google-calendar.png',
        onClick: function () 
        {
          window.open(self.getHtmlLink(), '_blank');
        },
      },
      {
        id: 'date',
        value: this.getDate(),
      },
      {
        id: 'Menu',
        value: '',
        menuItems: 
                [
                  {
                    label: gCalendarLinkTxt,
                    callback: function () 
                    {
                      window.open(self.getHtmlLink(), '_blank');
                    },
                  },
                  {
                    label: gMeetLinkTxt,
                    callback: function () 
                    {
                      window.open(self.getMeetLink(), '_blank');

                    }
                  }
                ],
      }
    ]

    return this.displayProperties;
  }
  usersAttendes: UserEvent[] = []
  getUsers(user) 
  {


    this.usersAttendes = (user || [])?.map(user => new UserEvent(user)) || [];
         
    return this.usersAttendes;
  }
}

