export class SearchFacetEntry 
{
  label: string;
  id?: string;
  type: string;
  values: Set<any>;
}

export class SearchFacet
{
  categoryId: string;
  engine;
  label: string;
  id: string;
  selectedValue: string;
  values: Set<any>;
  translatedName: string;
  type: string;

  constructor(obj : SearchFacetEntry)
  {
    this.label = obj.label;
    this.type = obj.type;
    this.values = obj.values;
    this.selectedValue = ''
    this.id = obj.id || obj.label;
  }

  getId() 
  {
    return this.id || this.label;
  }

  getCategoryId() 
  {
    return this.categoryId;
  }

  getEngine() 
  {
    return this.engine;
  }

  getLabel() 
  {
    return this.label;
  }

  getValues() 
  {
    return this.values;
  }

  sort()
  {
    const sorted = [...this.values].sort((a, b) => a - b);

    this.values = new Set<any>(sorted);
  }

  hasValues() : boolean 
  {
    return this.values.size > 0;
  }

  getSelectedValue() 
  {
    return this.selectedValue;
  }

  setSelectedValue(value) 
  {
    this.selectedValue = value;
  }

  _addValue(v) 
  {
    this.values.add(v);
  }

  unsetSelectedValue(value=null) 
  {
    this.selectedValue = '';
  }

  setValues() 
  {
  }

  setCategoryId() 
  {

  }

  setEngine() 
  {
  }

  setValuesFromResults(results, type) 
  {
  }

  getRouteParam(value=null)
  {
    if(!value)
    {
      value = this.selectedValue;
    }

    if(value)
    {
      return `${this.getLabel()}:${value};`
    }
    else
    {
      return "";
    }
  }

}
