import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

})
export class DialogComponent 
{

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) 
  { }

  submit() 
  {
    this.dialogRef.close({
      action: this.data.action,
      message: this.data.options
        .filter(option => !!option?.selected)
        .map(option => 
        {
          delete option.selected

          return option
        })
    })
  }

  close() 
  {
    this.dialogRef.close();
  }

  isButtonEnabled() 
  {
    if (this.data.isSingleOptionEnough) 
    {
      return this.data.options.some(option => option.selected);
    }
    else 
    {
      return this.data.options.every(option => option.selected);
    }
  }
}

