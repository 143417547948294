<!----------------------------------------------------------WORKFLOW ----------------------------------------------------------------------------------------->
<mat-card class="flow-card" *ngIf="showWorkflow || showActions ">
  <mat-card-header>
    <mat-card-title>{{'workflow' | translate}}</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="row" *ngIf="showActions">
    <app-doc-actions [path]="path" (emitState)="doSomething($event)" [_gedDoc]="gedDoc"></app-doc-actions>
  </mat-card-actions>


  <!-------------------- (click)="showContent= !showContent"-----------------------------------WORKFLOW DISPLAY----------------------------------------------------------------------------------------->

  <mat-card-content *ngIf="showContent && showWorkflow">
    <div class="spinner-div">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <mat-list  class="Workflow">
      <mat-list-item
        *ngFor="let role of workflowRoles; let i=index ;let j=last"
        class="listeWorkflow">

        <div class="box">
          <div  *ngIf="role.getId() == pending.getId()">
            <div
              class="button-container "
              [ngClass]="{'doneItem': workflowState == 'Pour validation'}"
              mat-mini-fab style="vertical-align: middle;">
              <span class=""  >{{i+1}}</span></div>
          </div>

          <div  *ngIf="role.getId() != pending.getId()" >
            <div class="button-container" mat-mini-fab color="white" style="vertical-align: middle;">
              <span>{{i+1}}</span>
              </div>
          </div>

          <div  style="display: inline; vertical-align: middle;">
            <p style="vertical-align: middle; font-size: 12px; color: #0000008A;display: inline;
            line-height: 50px;"> {{role.getName()}} :</p>

            <p style="vertical-align: middle; font-size: 12px; font-weight: bold;color: #0000008A;display: inline;
            line-height: 50px;"> {{role.getUserName()}}
              <mat-icon class="doneIcon"
                *ngIf=" role.getId() != pending.getId() && workflowState == 'Pour validation' "  style="color: green; ">
                done
                </mat-icon>
              </p>
                 <p  class="dateWorkflow" *ngIf=" role.getId() != pending.getId() && workflowState == 'Pour validation' " >
                {{role.getActionDate()}}
              </p>

          </div>
        </div>
        <span class="lined" *ngIf="!j"></span>

      </mat-list-item>

    </mat-list>

  </mat-card-content>


  <!-------------------------------------------------------WORKFLOW EDITING STEPPER----------------------------------------------------------------------------------------->

 <!--  <mat-card-content class="editFlow" *ngIf="!showContent">
    <mat-vertical-stepper #stepper [linear]="isLinear">
      <form #f="ngForm">
        <mat-form-field *ngFor="let item of workflow">

          <mat-step>
            <ng-template matStepLabel>{{item.role.html}}</ng-template>
            <input matInput [(ngModel)]="item.role_user.html" name="{{item.role.html}}">
          </mat-step>
        </mat-form-field>

      </form>

    </mat-vertical-stepper>
    <div class="editflowBtns">
      <button mat-raised-button class="flowBtn" (click)="showContent=!showContent">{{'cancelBtn' | translate}}</button>
      <button mat-raised-button class="flowBtn saveChange" (click)="editWorkflow(f.value)"
        [disabled]="!f.dirty">{{'saveEditBtn2' | translate}}</button>
    </div>
  </mat-card-content> -->
</mat-card>


<!----------------------------------------------DOCUMENT STATE DATA----------------------------------------------------------------------------------------->

<mat-card class="chip-card" *ngIf="showState">
  <mat-card-header>
    <mat-card-title>{{'metaDoc' | translate}}</mat-card-title>
  </mat-card-header>


  <mat-card-content>
    <div class="spinner-div">
      <mat-spinner *ngIf="isLoadingState"></mat-spinner>
    </div>
    <mat-list>
      <div *ngFor="let d of allData; let i=index">
        <mat-divider *ngIf="i!=0"></mat-divider>
        <mat-list-item class="listitem metadataListe" role="listitem" *ngIf="d?.val?.length >0">
          <span class="label">{{d.label}}</span>
          <span>{{d.val}}</span>
        </mat-list-item>
      </div>
    </mat-list>
  </mat-card-content>
</mat-card>

