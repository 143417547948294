import { AfterContentChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDrawer } from '@angular/material/sidenav';

import { SpaceDocAddComponent } from '../../space-doc-add/space-doc-add.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-doc-modal',
  templateUrl: './doc-modal.component.html',
  styleUrls: ['./doc-modal.component.scss']
})
export class DocModalComponent implements OnInit, OnChanges, AfterContentChecked 
{
  @Input('autoComplete') autoComplete;
  @Input('listeTemplates') listeTemplates;
  @Input('docType') docType;
  @Input('currentStep') currentStep;
  @Input() checkedValues: any[];

  @Input('spaceUid') spaceUid;
  @ViewChild("drawer") public drawer: MatDrawer;
  @ViewChild('f') public docFormulaire: NgForm;
  @Output() templateSelected = new EventEmitter<string>();

  listeSpaces: any[];
  previewLink: string;
  formFields: any[];
  showdrawer: boolean = false;
  isLoading: boolean = false;
  isCheckedA = false;
  selectedOption: any;
  isChecked2: FormControl = new FormControl();


  showForm: boolean = false;
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
  //@Output() scrollPosition: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('optionsContainer', { static: false }) optionsContainer: ElementRef;
  @Input() scrollPositionStep2: number;
  checkedTemplateId: string; // Declare the property


  static instance: DocModalComponent;

  constructor(
    private SpaceDocAddComponent: SpaceDocAddComponent,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) 
  {
    DocModalComponent.instance = this;
  }
  ngAfterContentChecked(): void 
  {



  }




  ngOnChanges(changes: SimpleChanges): void 
  {

    if (changes.currentStep) 
    {

      this.isLoading = false;

      setTimeout(() => 
      {
        this.isLoading = true;

      }, 2000);
    }

  }

  ngOnInit(): void 
  {

  }



  optionControl: FormControl = new FormControl(false); // Set the initial value to false


  onCheckboxChange2(control: FormControl) 
  {
    // Perform any additional logic here if needed
    this.optionControl.patchValue(control.value)
    // control.setValue(!control.value);
  }
  ngAfterViewInit() 
  {


    const storedScrollPosition = localStorage.getItem('scrollPosition');

    if (storedScrollPosition) 
    {
      const optionsContainer = this.optionsContainer.nativeElement;

      if (optionsContainer) 
      {
        setTimeout(() => 
        {
          optionsContainer.scrollLeft = parseInt(storedScrollPosition, 10);
        }, 100); // Adjust the delay if needed
      }
    }


  }
  @Output() scrollPosition: EventEmitter<number> = new EventEmitter<number>();


  isScrollAtStart = true;
  isScrollAtEnd = false;
  scrollLeft() 
  {
    const container = document.querySelector('.options-container') as HTMLElement;

    if (container) 
    {
      container.scrollLeft -= 100;
      this.updateScrollButtons(container);
      this.scrollPosition.emit(container.scrollLeft);
    }
  }

  scrollRight() 
  {
    const container = document.querySelector('.options-container') as HTMLElement;

    if (container) 
    {
      container.scrollLeft += 100;
      this.updateScrollButtons(container);
      this.scrollPosition.emit(container.scrollLeft);
    }
  }


  onContainerScroll() 
  {
    const container = this.optionsContainer.nativeElement;


    if (container) 
    {
      this.scrollPosition.emit(container.scrollLeft);
      this.updateScrollButtons(container);
    }
  }
  checkboxesChange(event) 
  {
    this.isChecked2.patchValue(event.value)

  }




  private updateScrollButtons(container) 
  {
    const maxScrollLeft = container.scrollWidth - container.clientWidth;

    this.isScrollAtStart = container.scrollLeft === 0;
    this.isScrollAtEnd = container.scrollLeft + 1 >= maxScrollLeft;
    //this.scrollPosition = container.scrollLeft;


  }

  defaultSelected() 
  {
    // this.formFields =  this.SpaceDocAddComponent.onSelectedType(this.docType);

    this.showForm = true;
    this.showdrawer = true;


    // this.drawer.open();
  }


  // Document type select
  onSelectedType(event) 
  {
    //this.formFields =  this.SpaceDocAddComponent.onSelectedType(event);
    this.showForm = true;
    this.showdrawer = true;

    //this.drawer.open();

  }
  onSelectedField(event: MatAutocompleteSelectedEvent, element) 
  {
    this.SpaceDocAddComponent.onSelectedField(event, element);
  }

  doFilterField(field) 
  {
    this.SpaceDocAddComponent.doFilterField(field);
  }

  filter(options, value) 
  {
    this.SpaceDocAddComponent.filter(options, value);
  }
  onSelectedValue(field, value) 
  {
    return this.SpaceDocAddComponent.onSelectedValue(field, value);
    /*  this.previewLink = this.SpaceDocAddComponent.onSelectedValue(field, value);
     
    return  this.previewLink */

  }
  checkedOptions = {}
  onChangeEnumsChecked(field, name, event) 
  {
    this.SpaceDocAddComponent.onChangeEnumsChecked(field, name, event);

  }



  onCheckboxChange(option: any, event: any) 
  {

    if (event.target.checked) 
    {
      option.checked = true;
      // If the option is checked, update the selectedOption property
      this.selectedOption = option;
      option.isChecked = true;
      // Uncheck other options
      this.listeTemplates.fieldAutoList.forEach((item: any) => 
      {
        if (item !== option) 
        {
          item.checked = false;
        }
      });
    }
    else 
    {
      option.checked = false;
      // If the option is unchecked, clear the selectedOption property
      this.selectedOption = null;
    }

    this.optionSelected.emit(option.uid);
    this.templateSelected.emit(this.selectedOption);
  }

  isChecked(option: any): boolean 
  {
    return this.checkedValues && this.checkedValues.some((item) => item.uid === option.uid && item.checked);
  }


}
