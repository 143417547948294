import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { TableColumn, TableRow } from 'src/design-system/core/interfaces/table';

@Component({
  selector: 'app-contracts',
  templateUrl: 'bootstrap/contracts.component.html',
  styleUrls: ['bootstrap/contracts.component.scss'],
})
export class ContractsComponent implements OnInit 
{
  activeTab: string = 'table';
  isOpen: boolean = false;

  columns: TableColumn[] = [
    {
      id: 'title',
      type: 'TextWithIcon',
      width: 260,
    },
    {
      id: 'client',
      type: 'Text',
      label: 'Client',
      canSort: true,
    },
    {
      id: 'lastupdate',
      type: 'Date',
      label: 'Last update',
      canSort: true,
    },
    {
      id: 'owner',
      type: 'Text',
      label: 'Owner',
      canSort: true,
    },
    {
      id: 'state',
      type: 'Text',
      label: 'State',
      canSort: true,
    },
    {
      id: 'version',
      type: 'Text',
      label: 'Version',
      canSort: true,
    },
    {
      id: 'menu',
      type: 'Menu',
      width: 55,
    },
  ];
  data: TableRow[][] = [
    [
      {
        id: 'title',
        value: 'Content instagram',
        iconImg: '/assets/images/icon-word.svg',
        link: '/groups/spaces/instagram',
      },
      {
        id: 'client',
        value: 'Abdel A',
      },
      {
        id: 'lastupdate',
        value: '11/09/2022',
      },
      {
        id: 'owner',
        value: 'Simo YY',
      },
      {
        id: 'state',
        value: 'Done',
      },
      {
        id: 'version',
        value: '1.0.0',
      },
      {
        id: 'menu',
        value: '',
        menuItems: [
          {
            label: 'Edit',
            callback: function () 
            {
              console.log('Edit');
            },
          },
          {
            label: 'Delete',
            callback: function () 
            {
              console.log('Delete');
            },
          },
        ],
      },
    ],
    [
      {
        id: 'title',
        value: 'Content instagram',
        iconImg: '/assets/images/icon-word.svg',
        link: '/groups/spaces/instagram',
      },
      {
        id: 'client',
        value: 'Abdel B',
      },
      {
        id: 'lastupdate',
        value: '11/09/2022',
      },
      {
        id: 'owner',
        value: 'Simo YY',
      },
      {
        id: 'state',
        value: '2',
      },
      {
        id: 'version',
        value: '1.0.0',
      },
      {
        id: 'menu',
        value: '',
        menuItems: [
          {
            label: 'Edit',
            callback: function () 
            {
              console.log('Edit');
            },
          },
          {
            label: 'Delete',
            callback: function () 
            {
              console.log('Delete');
            },
          },
        ],
      },
    ],
    [
      {
        id: 'title',
        value: 'Content instagram',
        iconImg: '/assets/images/icon-word.svg',
        link: '/groups/spaces/instagram',
      },
      {
        id: 'client',
        value: 'Abdel C',
      },
      {
        id: 'lastupdate',
        value: '11/09/2022',
      },
      {
        id: 'owner',
        value: 'Simo YY',
      },
      {
        id: 'state',
        value: '1',
      },
      {
        id: 'version',
        value: '1.0.0',
      },
      {
        id: 'menu',
        value: '',
        menuItems: [
          {
            label: 'Edit',
            callback: function () 
            {
              console.log('Edit');
            },
          },
          {
            label: 'Delete',
            callback: function () 
            {
              console.log('Delete');
            },
          },
        ],
      },
    ],
  ];

  contracts: ListItem[] = [
    {
      id: 1,
      title: 'Facebook content',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-word.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Document name',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-word.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Excel document',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-word.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Pdf file',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-pdf.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Document 1',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 1,
      title: 'Facebook content',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-word.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Document name',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-word.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Excel document',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-word.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Pdf file',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-pdf.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Document 1',
      redirectTo: '/document/1',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
  ];

  constructor(private titleService: Title, private _location: Location) 
  {
    this.titleService.setTitle('Contracts');
  }
  open() 
  {
    this.isOpen = true;
  }
  close() 
  {
    this.isOpen = false;
    console.log('5555');
  }
  ngOnInit(): void 
  {}
  toggleView(view: string) 
  {
    this.activeTab = view;
  }
  goback() 
  {
    this._location.back();
  }
}
