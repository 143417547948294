<div *ngIf="status && !dataStatus">
           <mat-card class="itemcard" *ngFor="let item of status  | keyvalue ">
            <mat-card-header [ngStyle]="{'background-color': '#eee' }" class="cardHeader">

              <mat-card-title>

                <mat-card-subtitle>
                  <label class="label-status" [ngStyle]="{'background-color': item.value?.color}">
                    {{item?.value?.label}}
                    <mat-icon class="status-icon"> {{ item?.value?.icon }} </mat-icon>
                  </label>
                </mat-card-subtitle>
                <span fxFlex></span>
                <span [ngStyle]="{ 'color': '#aaa','font-size':'13px','float':'right','margin-top':'-30px' }">
                  {{item?.value?.description}}
                </span>
              </mat-card-title>

            </mat-card-header>

           <!--  <mat-card-content    [hidden]="loadDataSource(item?.value?.label)?.length == 0" >
            
              <table mat-table [dataSource]="loadDataSource(item?.value?.label)" style="width: 100%"    matSort  
                class="mat-elevation-z8">

                <ng-container [matColumnDef]="column" 
                  *ngFor="let column of displayedColumnsTable ; let i=index ; let j =last">

                  <th  mat-header-cell *matHeaderCellDef   >

                    <span *ngIf="i>0 && !j"   (click)=" getSortValue(column) " mat-sort-header  class="hide-arrow"> {{displayedColumnsTableHeader[i]}} </span> <span *ngIf="j"> {{displayedColumnsTableHeader[i]}} </span>
                  </th>
                  <td mat-cell *matCellDef="let element" style="width: 30px"> 
                    <img [src]="element.iconLink" style="width: 15px" *ngIf="i==0" />
                    {{element.properties[column]}}
                    <div style=" display: flex">
                      <button mat-icon-button *ngIf="j" (click)="openDocum(element)"
                        style="cursor: pointer;  display: inline-block">
                        <mat-icon> description</mat-icon>
                      </button>
                      <button mat-icon-button *ngIf="j" color="warn" (click)="toFolder(element.parents)"
                        style="cursor: pointer; display: inline-block">
                        <mat-icon>folder</mat-icon>
                      </button>

                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay3" ></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay3;" (dblclick)="openDocum(row)"  [ngStyle]="{'border-left': '3px solid' +  item.value?.color} "></tr>
              </table>

            </mat-card-content>
 -->
           <!--  <mat-card-content *ngIf="loadDataSource(item?.value?.label)?.length === 0" class="content-empty">
              <p class="p-emty"> {{ item?.value?.empty }}</p>
            </mat-card-content>
 -->
          </mat-card>
         
        </div>