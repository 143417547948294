<aura-modal
  [isOpen]="isOpen"
  (close)="closeModal()"
  [title]="typeDocStr"
  [fullscreen]="false"
  [hasFooter]="true"
  width="90%"


>
  <div class="row justify-content-center spinnerLoading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
    <div class="in-loader">
      <h2 class="aura-h2">{{ "attende_doc_insert" | translate }}</h2>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <form #f="ngForm">
    <ng-container #container>
      <div class="steps step-1" *ngIf="currentStep == 1">
        <h2 class="aura-h2">  {{ "step1" | translate }}  {{ "step1_a" | translate }}   </h2>


          <mat-form-field
            class="form-dev"
            appearance="fill"
            style="display: block; text-justify: center"
          >
            <mat-label>{{'DocumentType' | translate}}</mat-label>
           
            <mat-select
              [(ngModel)]="docType.title"
              [(value)]="docType.title"
              name="docType"
              (selectionChange)="onSelectedType($event)"
            >
            <mat-option value="">{{'selectDocumentType' | translate}}</mat-option>
              <mat-option
                *ngFor="let option of autoComplete"
                [value]="option.title"
              >
                {{ option.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        <!-- <div class="row justify-content-between gx-2">
          <div class="col-md-4 col-6">
              <button auraButton label="Next" (onClick)="nextStep()" [full]="true" tag="button" type="primary"></button>
          </div>
      </div> -->
      </div>
    </ng-container>
    <div *ngIf="currentStep === 2">
      <h2 class="aura-h2" *ngIf="currentStep == 2"> {{ "step2" | translate }}  {{ selectedTabLabel }}</h2>

      <div *ngIf="currentStep === 2">
      <aura-tabs [activeTabIndex]="selectedTabIndex"  (onChange)="getSelectedTab($event)">

        <aura-tab title="Recent documents">
          <app-doc-recent  (documentSelected)="onSelectDocument($event)"></app-doc-recent>
        </aura-tab>

        <aura-tab title="Document model">
          <ng-container>
            <app-doc-modal
            (scrollPosition)="saveScrollPositionStep2($event)"
            *ngIf="currentStep == 2"

            [autoComplete]="autoComplete"
            [currentStep]="currentStep"
            [docType]="docType"
            [spaceUid]="spaceUid"
            [listeTemplates]="templateListe"
            [checkedValues]="formFieldValues.template"
            (templateSelected)="onTemplateSelected($event)"
            ></app-doc-modal>
          </ng-container>
        </aura-tab>

        <aura-tab title="Drives">
          <app-doc-drive
            (documentSelected)="onSelectDocument($event)"
            (lastSearch)="lastSearch($event)"
            (lastPath)="lastPath($event)"
            [CurrentRoute]="CurrentRoute"
            [CurrentSearch]="CurrentSearch"></app-doc-drive>
        </aura-tab>

        <aura-tab title="Upload">
          <app-doc-upload
            (documentUploaded)="onUploadDocument($event)" ></app-doc-upload>
        </aura-tab>

      </aura-tabs>
      </div>
    </div>


    <div *ngIf="currentStep >= 3">
      <!-- Step 3 content -->
      <h2 class="aura-h2" *ngIf="currentStep == 3"> {{ "step3" | translate }}  {{ "step3_a" | translate }}</h2>
        <div class="form-content">
          <app-form-manager
            [inputData]="inputData"
            [displayButtons]="false"
            [type]="'form'"
            (onFormCancelEvent)="prevStep()"
            (onFormSubmitEvent)="onCreateDocWizard($event)"
            (formValidityChanged)="handleFormValidityChanged($event)"></app-form-manager>
    <!-- <ng-container
          appearance="fill"
          style="display: block; text-justify: center"
          *ngFor="let field of formFields; let i = index"
        >
          <mat-form-field
            class="form-dev"
            *ngIf="
              field.auto == null &&
              field.key != 'planned_date_publication' &&
              field.control !== 'checkbox' &&
              !field.isEnum &&
              field.control !== 'select'éaxdw 
            "
          >
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [(ngModel)]="formFieldValues[field.key]"
              name="{{ field.key }}"
              type="{{ field.type }}"
              required="{{ field.required }}"
            />
          </mat-form-field>

          <mat-form-field
            class="form-dev"
            *ngIf="field.key == 'planned_date_publication'"
          >
            <mat-label>{{ field.label }}</mat-label>

            <input
              matInput
              [(ngModel)]="field.value"
              name="{{ field.key }}"
              type="date"
              required="{{ field.required }}"
            />
          </mat-form-field>
          <mat-form-field
            class="form-dev"
            *ngIf="
              field.auto != null &&
              field.key != 'space' &&
              field.control != 'select' &&
              field.control != 'checkbox' &&
              field.key != 'template' &&
              field.key != 'for_type_document' &&
              field.control != 'select' &&
              field.control !== 'checkbox'
            "
          >
            <mat-label>{{ field.label }}</mat-label>

            <input
              matInput
              [(ngModel)]="field.value.html"
              name="{{ field.key }}"
              type="{{ field.type }}"
              required="{{ field.required }}"
              [matAutocomplete]="autofield"
              (ngModelChange)="doFilterField(field)"
              #optionfield="ngModel"
            />

            <mat-autocomplete
              #autofield="matAutocomplete"
              (optionSelected)="onSelectedField($event, field)"
              class="autoOption"
            >
              <mat-option
                *ngFor="let option of field.fieldAutoList"
                [value]="option.html"
              >
                {{ option.html }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            class="form-dev"
            *ngIf="
              field.key == 'for_type_document' &&
              field.control != 'select' &&
              field.control != 'checkbox'
            "
          >
            <mat-label>{{ field.label }}</mat-label>
            <input
              matInput
              [(ngModel)]="field.value"
              name="{{ field.key }}"
              type="{{ field.type }}"

              required="{{ field.required }}"
              [matAutocomplete]="autofield"
              (ngModelChange)="doFilterField(field)"
              #optionfield="ngModel"
            />

            <mat-autocomplete
              #autofield="matAutocomplete"
              (optionSelected)="onSelectedField($event, field)"
              class="autoOption"
            >
              <mat-option
                *ngFor="let option of field.fieldAutoList"
                [value]="option.html"
              >
                {{ option.html }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            class="form-dev"
            *ngIf="
              field.key !== 'space' &&
              field.key !== 'template' &&
              field.auto !== null &&
              field.enumValues === null &&
              field.control !== 'checkbox' &&
              !field.isMultiple &&
              !field.isEnum &&
              field.control === 'select'
            "
          >
            <mat-label>{{ field.label   }}</mat-label>
            <mat-select
              [(ngModel)]="field.value.html"
              [(value)]="field.value.html"
              required="{{ field.required }}"
              (selectionChange)="onSelectedValue(field, field.value)"
              name="{{ field.key }}"
            >
              <mat-option
                *ngFor="let option of field.fieldAutoList"
                [value]="option.html"
              >
                {{ option.html }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf="field.control ==='checkbox' && field.isEnum  "  class="checkbox-dev">
            <mat-label >{{field.label}}</mat-label>
            <section  *ngFor="let item of field.enumValues | keyvalue"  class="checkboxSection" >
                <input type="checkbox"
                [checked]="isSelectedValue(item,field)"
                (change)="onChangeEnumsChecked(item, field,$event)"
                class="checkboxItem" > {{item.value}}<br>
                <mat-checkbox
                [checked]="isSelectedValue(option,field)"
                (change)="onChangeEnumsChecked(item, field, $event)"
                class="checkboxItem"
                >
                {{ item.value }}
                </mat-checkbox>
          </section>

        </div >
        <mat-form-field *ngIf="field.control ==='checkbox'  && field.isMultiple && !(field.isEnum)"  class="form-dev select-mult">
          <mat-label>{{field.label}}</mat-label>
          <mat-select
          [(ngModel)]="field.value.html"
          [multiple]="true"
          [ngModelOptions]="{standalone: true}" >
           <mat-option *ngFor="let item of field.fieldAutoList" [value]="item" (onSelectionChange)="onChangeEnumsChecked(item,field,$event)"  >
             {{ item.html }}
           </mat-option>
         </mat-select>

        </mat-form-field>

          <mat-form-field
            class="form-dev"
            *ngIf="
              field.isEnum &&
              !field.isMultiple &&
              field.control !== 'select' &&
              field.control !== 'checkbox'
            "
          >
            <mat-label>{{ field.label  }}  </mat-label>
            <mat-select
              [(ngModel)]="field.value.html"

              name="{{ field.key }}"
            >
              <mat-option
                *ngFor="let item of field.enumValues | keyvalue"
                [value]="item.key"
              >
                {{ item.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>-->
        </div>


    </div>
  </form>


   <!--  -->




  </div>


  <div auraModalFooter>
    <div class="row justify-content-between gx-2 ">
      <div class="col-md-4 col-6">
        <button
          auraButton
          label="Back"
          *ngIf="currentStep !== 1"
          (click)="prevStep()"
          [hasShadow]="true"
          [full]="true"
          tag="button"
          type="light"
        ></button>
      </div>
       
      <div class="col-md-4 col-6" *ngIf="currentStep !== 3 && !(selectFromDrive) ">
        <button
          auraButton
          label="Next"
          (click)="nextStep()"
          [full]="true"
          tag="button"
          type="primary"
          [disabled]="(currentStep === 2 && !(isUploaded || templateSelected)) || !goToStep2 "
        ></button>
      </div>
      
      <div class="col-md-4 col-6" *ngIf="currentStep == 3 ">
        <button
          auraButton
          label="{{'saveDoc' | translate}}"
          (click)="addDocument()"
          [full]="true"
          tag="button"
          type="success"
          [disabled]="!formValid" 
        ></button>
      </div>
    </div>
  </div>


</aura-modal>
