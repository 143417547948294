import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subscription } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';

import { environment } from 'src/environments/environment';


const searchRoute = environment.searchRoute
const themePath = environment.themePath;

@Component({
  selector: 'app-search-bar',
  templateUrl: `./${themePath}/search-bar.component.html`,
  styleUrls: [`./${themePath}/search-bar.component.scss`]
})
export class SearchBarComponent implements OnDestroy, OnInit 
{

  keywords;
  loaded: boolean = false;
  control: FormControl = new FormControl('');
  isNuLL = false;

  private _routeSubscription?: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) 
  { }

  ngOnInit() 
  {
    // Extract the 'q' query param (for a new search)
    const queryParams$: Observable<string> = this.route.queryParams
      .pipe(
        pluck('q'),  // Extract the 'q' parameter from each emitted value
        filter<string>(Boolean)  // Ignore values that are empty or undefined
      );

    // Also check the 'query64' URL parameter in case we come back from document result
    const params$: Observable<string> = this.route.params
      .pipe(
        pluck('query64'),  // Extract the 'query64' parameter from each emitted value
        filter(Boolean),  // Ignore values that are empty or undefined
        map((param: string) => 
        {
          // Decode the base64 -> json
          const decoded: string = atob(param);

          //  get 'q' property from the json object
          const { q }: Record<'q', string> = JSON.parse(decoded);

          return q;
        })
      );

    // Combine the 2 observables (q + query64)
    this._routeSubscription = merge(
      queryParams$,
      params$,
    )
      .subscribe((q: string) => 
      {
        // Extract only the part of the query that has text (/xxx are used for facets)
        this.keywords = q.split("/")[0];

        // Avoid an infinite loop
        const value = (this.control.value || '').split("/")[0];

        if (value != q) 
        {
          // update form control
          this.control.patchValue(q);
          this.control.updateValueAndValidity();
        }
      });

    // Check if the current URL contains the string '/search'
    if (this.route["_routerState"].snapshot.url.includes('/search')) 
    {
      // If it does, set the 'isSearch' flag to true
      this.route.queryParams.forEach(queryParams => 
      {
        // this.keywords = queryParams["q"];
        this.isSearch = true; 
      });
    }
    else 
    {
      // If it doesn't, set the 'isSearch' flag to false
      this.isSearch = false;
    }

    // Set the 'loaded' property of the component to true
    this.loaded = true;
  }

  ngOnDestroy(): void 
  {
    this._routeSubscription?.unsubscribe();
  }


  clearSearch() 
  {
    this.keywords = '';
    this.control.setValue(this.keywords);
    this.isSearch = false;
    this.router.navigate(['/workspace/main'],
      {
        queryParams: {
          d: localStorage.host_domain
        }
      })
  }

  isSearch = false;
  spaceId = ''

  // if search, check the route and navigate to the right page with search params added to the URL
  toSearchRes(keywords) 
  {
    if (keywords == "") 
    {
      this.clearSearch();
    }
    else 
    {
      this.router.navigate(searchRoute, { queryParams: { q: keywords, d: localStorage.host_domain } })
    }
  }

}

