import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { Document } from '../../models';
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'app-doc-display',
  templateUrl: './doc-display.component.html',
  styleUrls: ['./doc-display.component.scss']
})

export class DocDisplayComponent  implements OnInit , OnChanges
{
  @Input('path') path;
  @Input('docId') docId;
  @Input('document') document;

  currentDoc : Document;
  url: any;
  urlSafe: SafeResourceUrl;

  constructor(
      public sanitizer: DomSanitizer,
      private route: ActivatedRoute,
      private gdocService: GDocService)
  {
  }

  idDoc

  ngOnChanges(changes: SimpleChanges): void
  {
    this.getDocwebViewLink(this.idDoc);
  }

  showIframe=false;
  ngOnInit() 
  {

    this.route.params.forEach(params => 
    {

      this.idDoc= params["docId"];
      localStorage.setItem("docId",this.idDoc); //line not needed; a revoir

    });
  }

  mimeUrls = {
    "application/pdf": "https://drive.google.com/file/d/%id%/preview",
    "application/x-gzip": "https://drive.google.com/file/d/%id%/preview",
    "application/zip": "https://drive.google.com/file/d/%id%/preview",
  };
  mimeBaseUrls = {
    "image": "https://drive.google.com/file/d/%id%/preview",
    "application": "https://drive.google.com/file/d/%id%/preview",
    "text": "https://drive.google.com/file/d/%id%/preview",
    "video": "https://drive.google.com/file/d/%id%/preview",
    "*": "https://drive.google.com/file/d/%id%/preview",
  };

  // managed by drive editor
  extByDrive = {docx:true,doc:true,xls:true,xlsx:true,ppt:true,pptx:true}

  getDocwebViewLink(id)
  {
    //this.gdocService.getFile(id).then(res=>{
    const doc = this.document;

    if(!doc)
    {
      return null;
    }

    const ext = doc.fileExtension||null;
    const mime = doc.mimeType;

    localStorage.setItem("file", doc)

    let url = doc.webViewLink || "https://docs.google.com/document/d/"+id;

    if(mime)
    {
      if (this.mimeUrls[mime])
      // check full mime mapping (ex. image/png)
      {
        url = this.mimeUrls[mime];
      }
      else
      {
        if(ext && !this.extByDrive[ext])
        {
          // check partial mime mapping (ex. image/)
          const base = mime.split("/")[0];

          if (this.mimeBaseUrls[base])
          {
            url = this.mimeBaseUrls[base];
          }
          else
          {
            url = this.mimeBaseUrls['*'];
          }
        }
      }
    }

    if(url)
    {
      url = url.replace("%id%",id);
    }
    else
    {
      url = doc?.webViewLink;
    }

    this.url = url;

    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
