<div class="container-fluid">
    <div class="row mb-3">
        <div class="col">
            <img src="/assets/images/icon-left-arrow-circle.svg" width="33" (click)="goback()" />
            <span class="align-middle d-inline-block ms-2 primary-color fw-bold">Retour</span>
        </div>
    </div>
    <div class="row my-2 mb-md-5">
        <div class="col mb-3 mb-md-5">
            <h3 class="text-center">Get help about our products, basic knowledge and more</h3>
        </div>
    </div>
    <form class="row mb-3 mb-md-5">
        <div class="col-7 col-md-10 pe-0 me-0">
            <aura-input-text className="border-0 shadow-sm" size="large"
                placeholder="Search for features, basic knowledge or options…" id="search"></aura-input-text>
        </div>
        <div class="col-5 col-md-2 me-0">
            <button auraButton label="Search" size="large" type="primary" [full]="true"></button>
        </div>
    </form>
    <div class="row">
        <div class="col-lg-3 mt-5" *ngFor="let tuto of tutos">
            <aura-video [id]="tuto.id" [title]="tuto.title" [link]="tuto.link"></aura-video>
        </div>
    </div>

</div>
