import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-aurax-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aurax-button.component.html',
  styleUrls: ['./aurax-button.component.scss']
})
export class AuraxButtonComponent implements OnInit 
{
  @Input() label
  @Input() icon
  @Input() disabled = false
  @Input() active = false

  constructor() 
  { }

  ngOnInit(): void 
  {
  }

}
