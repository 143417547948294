import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorsRoutingModule } from './errors-routing.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaterialModule } from '../material/material.module';
import { AppCommonModule } from '../app-common/app-common.module';


@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    AppCommonModule,
    CommonModule,
    ErrorsRoutingModule,
    MaterialModule
  ],
  exports: [PageNotFoundComponent, ErrorsRoutingModule]
})
export class ErrorsModule 
{ }
