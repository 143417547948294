
import { environment } from "src/environments/environment";
import { SpaceGroupsComponent } from "../../components/space-groups/space-groups.component";
import { SpaceTypeComponent } from "../../components/space-type/space-type.component";
const open = environment.stringsFile.open;

export class Spacegroup
{
  name: string;
  icon: string;
  initialLetter : string;
  displayProperties;

  constructor(space)
  {
    this.setName(space);

    // this.setIcon(space);

    this.getDisplayProperties();
  }

  getDisplayProperties()
  {
    const self = this;

    this.displayProperties =
    [
      {
        title: this.getName(),
        image: '/assets/images/spaces-icon.svg',
        redirectTo: '/s/space/groups/'+self.getName(),

        menuItems :
        [
          {
            label: open,
            callback: function ()
            {
              SpaceGroupsComponent.instance.openGroupe(self.getName())
            }
          }

        ]
      }
    ];

    return this.displayProperties;
  }


  getName() 
  {
    return this.name;
  }




  getIcon() 
  {
    return this.icon;
  }




  getInitialLetter()
  {
    return this.initialLetter
  }

  setName(space) 
  {
    this.name = space?.title || space?.html || '';
  }


  setIcon(space) 
  {
 
    this.icon = space?.iconLink || null ;
  }


  setInitialLetter(space) 
  {
    this.initialLetter = space?.name.charAt(0);
  }
}
