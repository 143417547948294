import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
  selector: 'app-right-aside',
  templateUrl: $theme + '/right-aside.component.html',
  styleUrls: [$theme + '/right-aside.component.scss'],
})
export class RightAsideComponent implements OnInit 
{
  isToggled: boolean = false;

  constructor() 
  {}

  ngOnInit(): void 
  {}

  toggleRightSidebar() 
  {
    this.isToggled = !this.isToggled;
  }
}
