import { Injectable } from '@angular/core';
import { ContactService } from 'src/modules/contact/services/contact.service';

@Injectable({
  providedIn: 'root'
})
export class SearchGcalService 
{

  constructor() 
  {
    SearchGcalService.instance = this;
  }
  static instance: SearchGcalService

}
