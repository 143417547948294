import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PeopleDialogData{
  name: string;
  email: string;
  phone: number;
}
@Component({
  selector: 'app-people-dialog',
  templateUrl: './people-dialog.component.html',
  styleUrls: ['./people-dialog.component.scss']
})
export class PeopleDialogComponent implements OnInit 
{

  constructor(public dialogRef: MatDialogRef<PeopleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PeopleDialogData,
  ) 
  {}

  onCancel(): void 
  {
    this.dialogRef.close();
  }

  ngOnInit(): void 
  {
  }

}
