<div style="display: flex; flex-flow: row nowrap">
  <ng-container *ngFor="let p of parents">
    <ng-container>
      <button mat-button class="parentPath" (click)="toFolder(p)">
        {{ p.name }}
      </button>
      <button mat-icon-button style="vertical-align: middle">
        <mat-icon class="chevronIcon">chevron_right</mat-icon>
      </button>
    </ng-container>
  </ng-container>
</div>
