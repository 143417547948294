import { Component, Input, Output, EventEmitter, OnInit,OnChanges,SimpleChanges } from '@angular/core';

import { environment } from "src/environments/environment";
const themePath = environment.themePath;

@Component({
  selector: 'app-bread-crumb',
  templateUrl: `./${themePath}/bread-crumb.component.html`,
  styleUrls: [`./${themePath}/bread-crumb.component.scss`]
})
export class BreadCrumbComponent implements OnInit, OnChanges 
{

  @Input('title') title : string = ''; 
  @Input() separator : string = ' > '; 
  @Input() parents : any[] = null;
  @Input() filter : Function = null; 
  @Output() click = new EventEmitter();

  crumbs : any[] = null;

  constructor() 
  { }

  ngOnInit(): void 
  {
    if(!this.filter)
    {
      this.filter = this._filter;
    }

    this.initCrumbs(this.parents);
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    // only run when property "data" changed
    if (changes['parents']) 
    {
      this.initCrumbs(this.parents);
    }
  }

  _filter(crumb) 
  {
    if (crumb.id == 'root')
    {
      return false;
    }
    
    return true;
  }
  
  initCrumbs(parents) 
  {
    if(parents && parents.map)
    {
      this.crumbs = parents
        .map(p => 
        {
          if(p.onClick)
          {
            return p;
          }
          else
          {
            return new Crumb(p.name||p.label);
          }
        })
        .filter( p => 
        {
          p.canDisplay ? 
            p.canDisplay(p) :
            this.filter ? 
              this.filter(p) :
              true;
        })
    }
  }

  toFolder(p) 
  {
    if(p.onClick)
    {
      return p.onClick();
    }

    this.click.emit(p);
  }
}

export class Crumb 
{
  name : string;
  label : string;
  callback;

  constructor(name,click=null) 
  {
    this.name = name; 
    this.label = name;
    this.callback = click;
  }

  onClick(event) 
  {
    if(this.callback)
    {
      return this.callback(event,this);
    }
      
    return false;
  }

  canDisplay() 
  {
    return true;
  }

  canClick() 
  {
  }
}

export class BreadCrumbHandler 
{
  crumbs : Crumb[];

  BreadCrumbHandler(crumbs) 
  {
    this.crumbs = crumbs;
  }

  filter(crumb : any) 
  {
    return true;
  }
}