import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/modules/user/services';

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class LHeaderComponent implements OnInit, OnDestroy 
{
  profilePic = ''
  avatarActions = [
    { label: environment.stringsFile.USER.LOGOUT, icon: 'gg-log-off', callback: this.logout.bind(this) }
  ];
  @Input() breadcrumb: any[] = null;
  private onDestroy$: Subject<void> = new Subject<void>();

  isUrlSearch: boolean = false;
  logout() 
  {
    this.auth.logout()
  }
  constructor(
    private auth: AuthService, private router: Router
  ) 
  { }

  ngOnInit(): void 
  {
    if(this.router.url.includes('/search'))
    {
      this.isUrlSearch = true;
    }
    this.auth.profileSubject
      .pipe(takeUntil(this.onDestroy$),
        filter(value => !!value),
        tap(user => 
        {

          this.profilePic = user.picture
        }))
      .subscribe();
  }
  ngOnDestroy(): void 
  {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
