<div class="mt-2 parent-container">
  <div class="options-container-wrapper">
    <button
      mat-icon-button
      class="scroll-button left-button"
      (click)="scrollLeft()"
      [disabled]="isScrollAtStart"
      *ngIf="listeTemplates?.fieldAutoList?.length > 5"
    >
      <i class="gg-chevron-left custom-icon"></i>
    </button>
    <div
      class="options-container"
      #optionsContainer
      [ngClass]="{
        'horizontal-scroll': listeTemplates?.fieldAutoList?.length > 5
      }"
      (scroll)="onContainerScroll()"
      [scrollLeft]="scrollPosition"
    >
      <div class="templates row mb-5">
        <div
          class="col-4 custom-col"
          *ngFor="let option of listeTemplates?.fieldAutoList"
          [attr.data-id]="option.uid"
        >
          <div class="shadow rounded" [ngClass]="{ selected: option.checked }">
            <label class="label-checkbox">
              <div class="check-box">
                <div class="spinner-container">
                  <mat-spinner
                    class="customColorSpinner"
                    *ngIf="!isLoading"
                  ></mat-spinner>
                </div>
                <img
                  [src]="
                    'https://drive.google.com/thumbnail?sz=w300&id=' +
                    option.uid
                  "
                  class="previewImg"
                />
                <div class="checkbox-wrapper">
                  <aura-input-checkbox
                    [checked]="option.checked"
                    (change)="onCheckboxChange(option, $event)"
                    label=""
                  ></aura-input-checkbox>
                </div>
              </div>
            </label>
            <div class="card border-0 p-3 border-top tmp-desc">
              <div class="row align-items-center">
                <div class="col d-flex justify-content-between">
                  <h5 class="card-title mb-0 h3 primary-font">
                    <span class="limited-text" title="{{ option.html }}">{{
                      option.html
                    }}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      auraButton
      class="scroll-button right-button"
      (click)="scrollRight()"
      [disabled]="isScrollAtEnd"
      *ngIf="listeTemplates?.fieldAutoList?.length > 3"
    >
      <i class="gg-chevron-right"></i>
    </button>
  </div>
  

  <div  class="chose-text">
    <h2 class="aura-h3 text-center">
      {{ "Select a template from the list for your new document." | translate }}
    </h2>
  </div>
</div>
