<div class="dropzone" appDragNDrop (fileDropped)="onFileDropped($event)" *ngIf="pdoc">
  <div class="spinerContent">
    <mat-spinner class="spinner" *ngIf="uploadLoading"></mat-spinner>
  </div>
  <!--
  <div *ngIf="activeTab === 'grid'" class="row grid">
      <app-list [hasMenu]="true" [columns]="3" verticalAlign="center" [data]="docListData">
      </app-list>
  </div>
  -->
  <div class="row list hide container-table" (scroll)="onWindowScroll()" id="divScroll" *ngIf="activeTab === 'table' && !uploadLoading">
      <div class="col">
          <aura-table-row [data]="data" [columns]="columns"></aura-table-row>
      </div>
  </div>
</div>
