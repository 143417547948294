import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import {
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "src/environments/environment";

import { APISce } from "src/modules/app-common/services";


@Injectable({
  providedIn: "root",
})
export class GenericPageService 
{
  constructor(
    private api: APISce
  ) 
  { }


  static handleError(error: HttpErrorResponse) 
  {
    if (error.status === 0) 
    {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    }
    else 
    {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }

    // Return an observable with a user-facing error message.
    return throwError("Something bad happened; please try again later.");
  }

  getById(url: string, id: string): Promise<any> 
  {
    let fullPath = "";

    if (id) 
    {
      fullPath = url + `/${id}` + "?$withMeta=true";
    }
    else 
    {
      fullPath = url + "?$withMeta=true";
    }

    const options = this.api.httpConfig();

    return this.api
      .get(fullPath, options)
  }

  updateRecord(data: any, url: string, id: string): Promise<any> 
  {

    const fullPath = url + `/${id}`;
    const options = this.api.httpConfig();

    return this.api
      .put(fullPath, { data: { ...data, oid: id } }, options)

  }

  addRecord(data: any, url: string): Promise<any> 
  {
    const fullPath = url;
    const options = this.api.httpConfig();

    return this.api
      .post(fullPath, { data }, options)

  }
}
