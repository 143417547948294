import { SearchFiltersComponent } from './search-filters/search-filters.component';
import { SearchPageComponent } from './search-page/search-page.component';

export const containers = [
  SearchFiltersComponent,
  SearchPageComponent,
];

export * from './search-filters/search-filters.component';
export * from './search-page/search-page.component';

