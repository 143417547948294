import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription, fromEvent, interval, merge, of } from 'rxjs';
import { tap, catchError, take, switchMap, filter, throttleTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { DebugLog } from 'src/modules/app-common/services/log/log.service';
const debug = DebugLog.build("OAuth","green,white");
const debug2 = DebugLog.build("OAuth","orange,white");

const log = true;

@Injectable({
  providedIn: 'root',
})
/**
 * manage Google token and profile:
 * - get token from oauth server
 * - manage token renewal when expired (at least every 10mn if token is 45mn old)
 */
export class OauthService implements OnDestroy
{
  /** token subject */
  tokenSubject: BehaviorSubject<AccessToken>;

  /** user profile subject */
  profileSubject: BehaviorSubject<User>;

  /** client id */
  private _client_id: BehaviorSubject<string>;

  /** local storage key for token */
  private _stateKey = 'token';

  /** local storage key for time of last refresh */
  private _issKey = 'token-iss';

  /** local storage key for token validity */
  private _validUntilKey = 'token-validUntil';

  /** mouse event observable */
  private mouseRate_ms = 5 * 60 * 1000 // 10 minutes
  mouseMouvement$ : Observable<any>;

  /** interval events */
  private intervalRate_ms = 30 * 60 * 1000 // 30 minutes in ms
  private interval$ : Observable<any>;

  /** duration between 2 token expiration checks */
  private triggerEach_ms = 20 * 60 * 1000; // 20 minutes in milliseconds
  private refreshTrigger$ : Observable<number>;


  /** store observable subscription for later cleanup */
  subscriptions: Subscription[] = []
  initTokenCheckEvents() : void
  {
    /** creates an observable sequence that
     * - emits mouse movement events,
     * - limit the frequency of events (every 10mn if moved)
     * - only if there is already a token in localstorage
     * */
    this.mouseMouvement$ = fromEvent(window, 'mousemove')
    .pipe(
      tap(val => { log && debug.log('m') }),
      throttleTime(this.mouseRate_ms),
      tap(val => { log && debug.log('m ok') }),
      filter(() => this.isTokenInLocalstorage())
    );

    /** creates an observable that :
     * - emit an event every 30mn
     * - only if there is already a token in localstorage
     * */
    this.interval$ = interval(this.intervalRate_ms)
    .pipe(
      tap(val =>
      {
        log && debug.log('i: ' + val)
      }),
      filter(() => this.isTokenInLocalstorage())
    );

    /** creates refreshTrigger subject that:
     * - is triggered when user move the mouse (every 10mn)
     * - or every 30mn.
     * - only if already a token in locastorage
     *  */
    this.refreshTrigger$ = merge(this.mouseMouvement$, this.interval$)
    .pipe(
      throttleTime(this.triggerEach_ms),
      tap(val =>
        {
          log && debug.log('refreshTimer: ' + val, new Date().toISOString())
        }),
    )
  }

  constructor
  (
    private http: HttpClient,
    // private config: ConfigService,
    private router: Router
  )
  {
    /** init token observable */
    this.tokenSubject = new BehaviorSubject<AccessToken>(undefined);

    /** init client id observable */
    this._client_id = new BehaviorSubject<string>('');

    /** load token + client id from local storage */
    this.loadAccessTokenFromLocalStorage();

    /** refresh token periodically (every 30mn) and when mouse moves (every 10mn) */
    this.refreshTokenPeriodically();
  }


  /** refresh token periodically (every 30mn) and when mouse moves (every 10mn) */
  refreshTokenPeriodically(): void
  {
    this.initTokenCheckEvents();

    // Créez un observable qui émet une valeur à intervalles réguliers
    const periodicTrigger = interval(5000); // émet une valeur toutes les 5 secondes, ajustez selon vos besoins

    // Utilisez switchMap pour déclencher le rafraîchissement du token à chaque intervalle
    const sub: Subscription = periodicTrigger.pipe(
      tap(() =>
      {
        debug.log('check token refresh');
        // Code à exécuter avant chaque rafraîchissement
      }),
      switchMap(() => this.refresh()) // Refresh the token
    ).subscribe(
      () => {
        // Code à exécuter après chaque rafraîchissement réussi
      },
      (error) => {
        debug.error('Error refreshing token:', error);
      }
    );

    // Ajoutez l'abonnement à votre tableau d'abonnements
    this.subscriptions.push(sub);
  }


  /**
   * check if time is > 45mn
   *
   * NB. a token duration is 60mn
   */
  isExpired(time: number, validUntil:number): boolean
  {
    const maxAge_ms = 45 * 1000 *60; // 45 minutes in ms
    const iss = time + maxAge_ms;
    const currentTime = new Date().getTime();

    if(iss)
    {
      if(currentTime - iss < 0)
        debug.log("isExpired : iss = "+this._showTime(iss));
      else
        debug2.log("isExpired : INVALID iss = "+this._showTime(iss));
    }

    if(validUntil)
    {
      if(currentTime - validUntil < 0)
        debug.log("isExpired : validUntil token = "+this._showTime(validUntil));
      else
        debug2.log("isExpired : EXPIRED validUntil token = "+this._showTime(validUntil));
    }

    debug.log("isExpired : currentTime = "+this._showTime(currentTime));

    validUntil = validUntil || time + maxAge_ms;

    const timeDiff = currentTime - validUntil;
    return timeDiff > 0;
  }

  /**
   * check if token is expired by loading the "iss" value (time of last refresh)
   * from local storage and checking if more than 45mn old.
   */
  isTokenExpired() : boolean
  {
    const iss = localStorage.getItem(this._issKey) || "";
    const validUntil = localStorage.getItem(this._validUntilKey) || "";

    if (iss || validUntil)
    {
      // debug.log('iss :', iss)
      return this.isExpired(
          parseInt(iss),
          parseInt(validUntil)
        );
    }

    return true;
  }

  /**
   *
   */
  redirectToAuth()
  {
    const currentUrl = this.router.routerState.snapshot.url;

    localStorage.setItem('redirectTo', currentUrl);

    debug.log("login requested on target URL = "+currentUrl);

    this.router.navigateByUrl('/in/login');// to revisit this
  }

  /**
   * load token and client_id from local storage, and checks if they are
   * expired.
   *
   * When loaded, updates :
   * - client_id : _client_id subject
   * - token : "state" local variable.
   *
   */
  private loadAccessTokenFromLocalStorage(): void
  {
    // load client id from local storage (dynamic)
    let clientid =
      localStorage.getItem('client_id')
        || environment.BACKEND_OAUTH_CLIENT_ID;

    if (clientid)
    {
      this._client_id.next(clientid)
    }

    // load token and store it in "state" variable
    const token = localStorage.getItem(this._stateKey);
    if (!token)
    {
      log && debug.log("user not connected in localstore");
      return;
    }

    // check if token still valid
    if (this.isTokenExpired())
    {
      // expired => refresh it
      log && debug.log("token expired => refresh it");
      const sub = this.refresh().pipe(take(1)).subscribe()
      return;
    }
    else
    {
      log && debug.log("token from local storage ok");
      // valid : store it in local variable
      // this.state = token;
      this.setCurrentToken(token);
    }
  }

  /**
   * check if a token is in local storage
   */
  isConnected(): boolean
  {
    return this.isTokenInLocalstorage()
  }

  /**
   * returns current token from stateSubject.
   */
  get state(): AccessToken
  {
    return this.tokenSubject.value;
  }

  /**
   * returns current token from local variable.
   */
  get token(): AccessToken
  {
    return this.state;
  }

  /**
   * return current client_id (provided by oAuth server) as string.
   * Gets it from _client_id subject.
   */
  get client_id(): AccessToken
  {
    // debug.log('get client_id: ', this._client_id.value)

    return this._client_id.value;
  }

  /**
   * set new token/state only if token has changed.
   * If changed, updates token "stateSubject" and save it in local storage.
   */
  set state(newState: AccessToken)
  {
    if (!this.areStatesEqual(this.tokenSubject.value, newState))
    {
      this.tokenSubject.next(newState);
      this.saveTokenInLocalStorage(newState);
    }
  }

  /**
   * set access token + validity in memory + localstore
   * @param token
   * @param validUntil
   */
  setCurrentToken(
    token : string,
    validUntil: number = null)
  {
    if (!this.areStatesEqual(this.tokenSubject.value, token))
      {
        this.tokenSubject.next(token);
        this.saveTokenInLocalStorage(token);
      }
  }

  /**
   * set access token + validity in memory + localstore
   * @param token
   * @param expires_in_s
   */
  setNewToken(
    token : string,
    expires_in_s: number)
  {
    if (!this.areStatesEqual(this.tokenSubject.value, token))
      {
        this.tokenSubject.next(token);
        this.saveTokenInLocalStorage(token,expires_in_s);
      }
  }


  /** check if token are the same */
  private areStatesEqual(state1: AccessToken, state2: AccessToken): boolean
  {
    return state1 === state2;
  }

  /** store token in local storage[token] and add current time in [token-iss] */
  private saveTokenInLocalStorage(
    token: string,
    expires_in_s:number = null): void
  {
    localStorage.setItem(this._stateKey, token);

    const now = new Date().getTime();
    localStorage.setItem(this._issKey, now.toString());
    debug.log("Now = "+this._showTime(now));
    debug.log("Now + 1h = "+this._showTime(now+3600*1000));

    if(expires_in_s)
    {
      let validUntil = now + expires_in_s*1000;
      debug.log("Token expiry = "+this._showTime(validUntil));
      localStorage.setItem(this._validUntilKey, validUntil.toString());
    }
    else {
      let validUntil = parseInt(localStorage.getItem(this._validUntilKey) ||"");
      debug.log("Token expiry from local strore = "+this._showTime(validUntil));
    }
  }

  _showTime(ms)
  {
    // Create a new Date object from the timestamp
    const date = new Date(ms);

    // Get the hours, minutes, seconds, and milliseconds from the Date object
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();

    // Format hours, minutes, seconds, and milliseconds to ensure two digits for hours, minutes, and seconds, and three digits for milliseconds
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    const formattedMilliseconds = String(milliseconds).padStart(3, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
  }

  /**
   * request a new token from oAuth server.
   *
   * store new token and client_id that are used by backend APIs.
   * They are stored in :
   * - localstorage
   * - client id : in Observable subject (_client_id).
   * - token : in "this.state"
   *
   * In case of 403 error, cleanup auth data.
   */
  refresh(force = false)
  {
    debug.log("refresh()...");

    // return EMPTY;
    if(force || this.isTokenExpired())
    {
      debug.log("expired => call oauth server...");

      return this.get<RefreshTokenInfos>('refresh').pipe(
        tap((newTokenInfos) =>
        {

          const clientId = newTokenInfos?.user?.client_id || '';
          if(!clientId)
            return ;

          const validUntil = newTokenInfos.expires_in;
          debug.log("got new token infos, valid unitl "+validUntil);

          // get client id and store in local storage
          if (!clientId)
          {
            debug.error('client_id should have a valid value');
          }
          this._client_id.next(clientId);
          localStorage.setItem('client_id', clientId);

          // keep token in "state" member variable
          // this.state = newTokenInfos.access_token;
          this.setNewToken(newTokenInfos.access_token,newTokenInfos.expires_in);

          debug.log("got new token");
        }),
        catchError((error: HttpErrorResponse) =>
        {
          debug.error("token error",error);

          if (error.status === 403)
          {
            // cleanup token, profile, in subjects and local store + force logout
            this.unloadUserToken();

            // go to login page
            this.redirectToAuth();
          }

          return of(null);
        })
      );
    }
    else
    {
      debug.log("not expired : keep it");
      return of({access_token: this.state})
    }
  }

  /** request my profile information from oauth server and update profile subject with it. */
  me()
  {
    return this.get<User>('me')
      .pipe(
        tap((me) =>
        {
          this.profileSubject.next(me);
        }),
        catchError(() =>
        {
          this.unloadUserToken();

          return of(null)
        }
        )
      );
  }

  /**
   * calls the server for a session logout, and cleanup local auth data.
   *
   * @returns
   */
  logout()
  {
    return this.get<LogoutResponse>('logout')
      .pipe(
        tap((status) =>
        {
          if (status && status.done === true)
          {
            this.unloadUserToken();
          }
        }),
        catchError((err) =>
        {
          return of(this.unloadUserToken())
        })
      )
  }

  /** request oauth session closing on oauth server */
  forceLogout()
  {
    return this.get<LogoutResponse>('force-logout')
  }

  /** check if local storage has a token + iss */
  isTokenInLocalstorage(): boolean
  {
    const token = localStorage.getItem(this._stateKey);
    const iss = localStorage.getItem(this._issKey);
    return !!token && !!iss
  }

  /**
   * cleanup user token data.
   */
  unloadUserToken()
  {
    // cleanup token in memory
    this.tokenSubject.next(undefined);

    // cleanup local storage
    localStorage.removeItem(this._issKey);
    localStorage.removeItem(this._stateKey);

    // request session logout from oauth server
    this.forceLogout().pipe(take(1)).subscribe();

    // get back to home page
    this.router.navigate(['/']);
  }

  /** cleanup observable subscriptions (interval/mouse events)*/
  unsubscribe()
  {
    // Unsubscribe when needed
    if (this.subscriptions)
    {
      this.subscriptions.forEach(sub =>
      {
        sub.unsubscribe();
      });

    }
  }

  ngOnDestroy(): void
  {
    // cleanup subscriptions to mouse + interval
    this.unsubscribe()
  }

  /** call oAuth backend with a request.
   * Use operation suffix to complete the URL */
  get<T>(suffix: string)
  {
    const base = environment.BACKEND_OAUTH_URL;
    const options = { withCredentials: true };
    const url = base + suffix;

    return this.http.get<T>(url, options);
  }
}

export type AccessToken = string;
export type AuthState = string;

export type scope = string | string[];

/**
 * User profile retreived from oauth server /me
 */
export interface User {
  id: string;
  accessToken?: string;
  localId: string;
  role?: 'user' | 'admin' | 'workplace.admin';
  provider: string;
  scope?: scope;
  name: string;
  client_id?: string;
  email: string;
}

/**
 * Refresh token infos retreived from oauth server
 */
export type RefreshTokenInfos = {
  access_token: string
  expires_in: number
  id_token: string
  scope: scope
  token_type: 'Bearer'
  user: User
}
export type LogoutResponse = { done: boolean }
