<mat-list-item class="eventInofs">
    <mat-icon matListAvatar >event</mat-icon>

    <div class="wrapper" style="width: 100%;">
        
        <div style="display: inline-flex;width: 100%; height: 40px;">
            <div class="event-description-div" style="width: 69%;">
                <p matLine [ngStyle]="{'font-size':'13px' ,'line-height':'1.5'}" style="font-weight: 500!important;width: 100%;">{{eventItem.getSummary()}}</p>
                <p matLine  [ngStyle]="{'font-size':'12px' ,'line-height':'1.5'}">{{eventItem?.getDate()}}</p>  
                <!-- <ng-container *ngIf="showStatus">
                    <ng-container *ngFor="let o of meetingOptions" style="display: inline;">
                        <p *ngIf="eventItem.getSelfAttendee().getSelfStatus()=='needsAction'" class="meetOptions clickableData" (click)="meetOptionClick(eventItem,o)">{{o}}</p>
                    </ng-container>
                    <p matLine *ngIf="eventItem.getSelfAttendee().getSelfStatus()!='needsAction' && showStatus" class="meetOptions clickableData" (click)="cancelAnswer(eventItem.getSelfAttendee())">{{eventItem.getSelfAttendee().getDisplayedSelfStatus()}}</p>
                    
                </ng-container> -->
                
            </div>

            <div fxFlex class="flex"></div>

            <div class="event-actions" style="align-items: center; display: flex;">
                <div class="md-secondary-container">
                    <span class="secondaryButton" mat-flat-button>
                        <mat-icon [ngClass]="{'disabled-link': eventItem?.getHangoutLink()==undefined || eventItem?.getHangoutLink()==null,
                        'active-link': eventItem?.getHangoutLink()!=undefined && eventItem?.getHangoutLink()!=null}"
                        (click)="toLink(eventItem?.getHangoutLink())" matTooltip="{{'rejoindre' | translate}}" >videocam</mat-icon>
                        </span>
                    <span class="secondaryButton" mat-flat-button>
                        <mat-icon (click)="toLink(eventItem?.getHtmlLink())" matTooltip="{{'openEvent' | translate}}" >open_in_new</mat-icon>
                    </span>
                    <span class="secondaryButton" mat-flat-button>
                        <mat-icon (click)="eventSearch(eventItem.getSummary())" matTooltip="{{'searchBar' | translate}}">search</mat-icon>
                    </span>
                    <span class="secondaryButton" mat-flat-button>
                        <mat-icon (click)="openPopup(eventItem)" matTooltip="{{'editEvent' | translate}}">edit</mat-icon>
                    </span>
            
                </div>
            </div>
        
            
        </div>
    
        <div style=" width: 100%; display: flex;padding-bottom: 8px;">



            <div  style="display: flex; margin-right: 2px; position: relative;width: 23px;"  *ngFor="let person of eventItem.getDisplayAttendees(); let i=index" (click)="answerMeeting(person)">

                <img src="{{person?.getPhoto()}}" *ngIf="!!person?.getPhoto()" class="personPhoto" matTooltip="{{person.getName()}}"
                [ngClass]="{'cursorPointer': person?.getId()!=null || person?.getMail()!=null}">

                <svg *ngIf="person.getStatus()=='accepted'" class="status-svg" xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#CEEAD6" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#137333" stroke-width="1.5" d="M3.5 6.5L6 9l5-5"></path></svg>
                <svg *ngIf="person.getStatus()=='declined'" class="status-svg" xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#FAD2CF" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#B31412" stroke-width="1.5" d="M4 10l6-6M10 10L4 4"></path></svg>
                <svg *ngIf="person.getStatus()=='tentative'" class="status-svg" xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#E8EAED" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#3C4043" stroke-width="1.5" d="M5 5c0-1.1.9-2 2-2s2 .9 2 2-1 1.5-1.5 2S7 8 7 9M7 10v1.5"></path></svg>
                
                <div class="spareImg center" *ngIf="!person.getPhoto()" style="display: inline-grid;"
                [ngClass]="{'cursorPointer': person?.getId()!=null || person?.getMail()!=null}">
                  <span class="imgInitial imgInitial_web" matTooltip="{{person.getName()}}">{{person.getInitial() | uppercase}}</span>
                </div>
            </div>
            <p style="margin-bottom: 0px;
              margin-left: 6px;
              font-size: 12px;
              color: #5B737D;
              align-self: center;" *ngIf="eventItem?.getAttendees()?.length>3" [matTooltip]="eventItem.additionalAttendees"> + {{eventItem?.getAttendees()?.length-3}} person
            </p>
            <p style="margin-bottom: 0px;
              word-break: break-word;
              line-break: inherit;
              width: 40%;
              white-space: break-spaces;
              margin-left: 6px;
              font-size: 12px;
              color: #5B737D;
              /* white-space: nowrap; */
              overflow: hidden;
              text-overflow: ellipsis;
              align-self:center;" *ngIf="!eventItem?.getCanSeeGuests()" >maskedlist
            </p>
        
        
        
        
            
        </div>


        <!-- <div style=" width: 100%; display: flex;padding-bottom: 8px;"*ngIf="showStatus">       
            <div *ngIf="eventItem.getSelfAttendee().getSelfStatus()=='needsAction'" style="align-items: baseline;    display: contents;">
                {{'event_going_question' | translate}}
                <span class="app-spacer"></span>
                <ng-container *ngFor="let o of meetingOptions" style="display: inline;">
                    <p *ngIf="eventItem.getSelfAttendee().getSelfStatus()=='needsAction'" class="meetOptions clickableData" (click)="meetOptionClick(eventItem,o)">{{o}}</p>
                </ng-container>
            </div>
            <div *ngIf="eventItem.getSelfAttendee().getSelfStatus()!='needsAction'" style="align-items: baseline;    display: contents;">
                <span>{{'event_answer_title' | translate}}</span>
                <span class="app-spacer"></span>
                <p matLine class="meetOptions clickableData"
                 (click)="cancelAnswer(eventItem.getSelfAttendee())">
                    {{eventItem.getSelfAttendee().getDisplayedSelfStatus()}}
                </p>
                    
            </div>


        </div> -->

    </div>


    <mat-divider></mat-divider>



</mat-list-item>

