import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpacesRoutingModule } from './spaces-routing.module';
import * as spaceComponents from './components';
import * as spaceContainers from './containers';
import * as spaceServices from './services';


import { AppCommonModule } from '../app-common/app-common.module';
import { MaterialModule } from '../material/material.module';
import { DocumentsModule } from '../documents/documents.module';
import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { SpaceTypeComponent } from './components/space-type/space-type.component';
import { SpaceGroupsComponent } from './components/space-groups/space-groups.component';
import { SpaceDocAddComponent } from './components/space-doc-add/space-doc-add/space-doc-add.component';
import { SpaceNewComponent } from './components/space-new/space-new.component';
import { SpacePermissionComponent } from './components/space-permission/space-permission.component';
import { DocRecentComponent } from './components/space-doc-add/components/doc-recent/doc-recent.component';
import { DocModalComponent } from './components/space-doc-add/components/doc-modal/doc-modal.component';
import { DocDriveComponent } from './components/space-doc-add/components/doc-drive/doc-drive.component';
import { DocUploadComponent } from './components/space-doc-add/components/doc-upload/doc-upload.component';
import { CreateDocComponent } from './components/space-doc-add/create-doc/create-doc.component';
import { FormManagerModule } from '../form-manager/form-manager.module';



@NgModule({
  declarations: [...spaceContainers.containers, ...spaceComponents.components, DragNDropDirective, DocumentViewComponent, SpaceTypeComponent, SpaceGroupsComponent, SpaceDocAddComponent, SpaceNewComponent, SpacePermissionComponent, DocRecentComponent, DocModalComponent, DocDriveComponent, DocUploadComponent ,CreateDocComponent],
  providers: [...spaceServices.services],
  imports: [
    AppCommonModule,
    CommonModule,
    SpacesRoutingModule,
    MaterialModule,
    DocumentsModule,
    FormManagerModule
  ],
  exports: [SpacesRoutingModule, ...spaceContainers.containers, ...spaceComponents.components]
})
export class SpacesModule 
{ }
