<app-sidenav title="{{'tasksString' | translate}}" [breadcrumb]="bc">
    <div class="" >
        <!--<div class="row pb-3">
            <div class="col icons d-flex justify-content-end gap-2 view-icons">
                <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                    (click)="toggleView('table')">
                <img [src]="'/assets/images/view-cols.svg'"
                    (click)="toggleView('grid')">
            </div>
        </div>-->

        <ng-container *ngIf="!isLoading">
            <div class = 'title'>{{'Approbations' | translate}}</div>

            <div  class="row list hide">
                <div class="col">
                    <aura-table-row
                        data-title="{{'My tasks' | translate}}"
                        [disableHead]="true"
                        [columns]="columns"
                        [data]="taskTableData" ></aura-table-row>

                    <!--
                    <aura-table-row
                    [disableHead] = "true && teamTaskTableData.length == 0"
                    data-title = "{{'Team tasks' | translate}}"
                    [columns] = "columns"
                    [data] = "teamTaskTableData"></aura-table-row>

                    <p  *ngIf="teamTaskTableData.length ==0">{{'no_tasks_team_left' | translate}}</p>-->

                <!--   <app-rowtable [disableHead]="true" title="To Edit" [columns]="columns" [data]="data"></app-rowtable>
                    <app-rowtable [disableHead]="true" title="Done" [columns]="columns" [data]="data"></app-rowtable>
                    <button class="btn gray-500 w-100 border bg-transparent border-2 border-dashed">+ Add New row</button> -->
                </div>
            </div>
        </ng-container>

        <div class="flagged-content">
            <app-flagged-content></app-flagged-content>
        </div>
    </div>
</app-sidenav>

<ng-template #myTemRef let-title="title" let-class="class">
    <span class="tag {{class}}">{{title}}</span>
</ng-template>
