import { Injectable } from "@angular/core";

import
{
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";

import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "src/modules/user/services";
import { OauthService } from 'src/modules/auth/services/oauth.service';
const BACKEND_URL = environment.BACKEND_URL;
const VERTEX_BE_URL = environment.VERTEX_BE_URL;
const CLIENT_ID_KEY = 'Client_id'

type TOptions =
{
  params?,
  headers?
};

/**
 * The API Service used for http calls to backend server.
 * It manages the base url and tokens, and check if the request was ok.
 * If it receives a 401 or 403, it requests a new token and retry the request
 */
@Injectable({
  providedIn: "root",
})
export class APISce
{
  /**
   *
   * @param http
   * @param auth
   */
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private oauth: OauthService
  )
  {
  }

  /**
   * http put with Promise
   * sends headers GTOKEN and jwt BEARER if no options are provided
   *
   * @param url
   * @param data
   * @param params
   * @param options
   * @param withJwt
   * @param withGoogleToken
   * @returns
   */
  public async put(
    url,
    data,
    params?,
    options? : TOptions,
    withJwt = true,
    withGoogleToken = true
  ): Promise<any>
  {
    url = BACKEND_URL + url;

    if (!options)
    {
      options = await this.httpConfig(withGoogleToken, withJwt);
    }

    if (params)
    {
      options.params = params;
    }
    if (!options.headers) 
    {
      options.headers = new HttpHeaders();
    }
    if (this.oauth.client_id)
    {
      if (false)
      {
        options.headers = options.headers.append(CLIENT_ID_KEY, this.oauth.client_id);
      }
    }

    try
    {
      const res = await this.http
        .put(url, data, options)
        .toPromise();

      return res;
    }
    catch (error)
    {
      console.error("error in api get", error, url);
      throw error;
    }
  }

  /**
   * http GET with promise.
   *
   * sends headers GTOKEN and jwt BEARER if no options are provided
   *
   * @param url (partial url without doùmain part)
   * @param params
   * @param options
   * @param withJwt  add ged jwt to http headers?
   * @param withGoogleToken add google token to http headers?
   * @param useVertexBE changes the url to VERTEX_BE_URL if true
   * @returns
   */
  public async get(
    url,
    params?,
    options? : TOptions,
    withJwt = true,
    withGoogleToken = true,
    useVertexBE = false
  ): Promise<any>
  {
    url = (useVertexBE ? VERTEX_BE_URL : BACKEND_URL) + url;

    if (!options) options = await this.httpConfig(withGoogleToken, withJwt);

    if (params) options.params = params;

    try
    {
      const res = await this.http
        .get(url, options)
        .toPromise();

      return res;
    }
    catch (error)
    {
      if (error.status != 404)
      {
        // http 404 is not an error in GET (but it is in POST/PUT/DELETE)

        console.error("error in api get", error, url);
        throw error;
      }

      return null;
    }
  }

  /**
   * http post with promise.
   *
   * sends headers GTOKEN and jwt BEARER if no options are provided
   *
   * @param url (partial url without doùmain part)
   * @param data
   * @param params
   * @param options
   * @param withJwt  add ged jwt to http headers?
   * @param withGoogleToken add google token to http headers?
   *
   * @param useVertexBE changes the url to VERTEX_BE_URL if true
   * @returns
   */
  public async post(
    url,
    data,
    params?,
    options? : TOptions,
    withJwt = true,
    withGoogleToken = true,
    useVertexBE = false
  ): Promise<any>
  {
    url = (useVertexBE ? VERTEX_BE_URL : BACKEND_URL) + url;

    // set auth headers
    if (!options)
    {
      options = await this.httpConfig(withGoogleToken, withJwt);
    }

    if (!options.headers) 
    {
      options.headers = new HttpHeaders();
    }
    if (this.oauth.client_id)
    {
      if (false)
      {
        options.headers = options.headers.append(CLIENT_ID_KEY, this.oauth.client_id);
      }
    }

    // add get params if any
    if (params)
    {
      options.params = params;
    }

    try
    {
      const res = await this.http
        .post(url, data, options)
        .toPromise();

      return res;
    }
    catch (error)
    {
      console.error("error in api post", error, url);
      throw error;
    }
  }

  /**
   * http update with promise.
   *
   * sends headers GTOKEN and jwt BEARER if no options are provided.
   * manages google token renewal
   *
   * @param url (partial url without doùmain part)
   * @param data payload
   * @param params get params
   * @param options headers options
   * @param withJwt  add ged jwt to http headers?
   * @param withGoogleToken add google token to http headers?
   *
   * @returns
   */
  public async update(
    url,
    data,
    params?,
    options? : TOptions,
    withJwt = true,
    withGoogleToken = true
  )
  {
    url = BACKEND_URL + url;

    // set auth headers
    if (!options)
    {
      options = await this.httpConfig(withGoogleToken, withJwt);
    }

    // add auth headers (manages token renewal)
    if (!options)
    {
      options = await this.httpConfig(true);
    }
    if (!options.headers) 
    {
      options.headers = new HttpHeaders();
    }
    if (this.oauth.client_id)
    {
      if (false)
      {
        options.headers = options.headers.append(CLIENT_ID_KEY, this.oauth.client_id);
      }
    }


    if (params)
    {
      options.params = params;
    }

    try
    {
      const res = await this.http
        .put(url, data, options)
        .toPromise();

      return res;
    }
    catch (error)
    {
      console.error("error in api post", error, url);
      throw error;
    }
  }

  /**
   * http delete (with promise)
   *
   * sends headers GTOKEN and jwt BEARER if no options are provided.
   * manages google token renewal
   *
   * @param url
   * @param params get params
   * @param options
   * @param withJwt  add ged jwt to http headers?
   * @param withGoogleToken add google token to http headers?
   * @returns
   */
  public async delete(
    url,
    params?,
    options?,
    withJwt = true,
    withGoogleToken = true
  )
  {
    url = BACKEND_URL + url;
    if (!options) options = await this.httpConfig(withGoogleToken, withJwt);
    if (!options.headers) 
    {
      options.headers = new HttpHeaders();
    }
    if (this.oauth.client_id)
    {
      if (false)
      {
        options.headers = options.headers.append(CLIENT_ID_KEY, this.oauth.client_id);
      }
    }

    if (params) options.params = params;

    try
    {
      const res = await this.http
        .delete(url, options)
        .toPromise();

      return res;
    }
    catch (error)
    {
      console.error("error in api delete", error, url);
      throw error;
    }
  }


  // ======================= CONFIG =============

  /**
   * define options / headers
   *
   * @param withGoogleToken
   * @param withJWT
   * @param withGoogleCookie
   * @returns
   */
  async httpConfig(
    withGoogleToken = true,
    withJWT = true,
    withGoogleCookie = false
  )
  {
    let headers = new HttpHeaders();
    headers = headers.set("X-CLIENT-ID", this.oauth.client_id);

    if (withJWT)
    {
      // headers.set
      headers = headers.set("Authorization", "Bearer " + this.getJWT());

      if (withGoogleToken)
      {
        const gtoken = await this.getCurrentToken();

        if (gtoken)
        {
          headers = headers.set("GTOKEN", "Bearer " + gtoken);
        }
        else
        {
          console.error("missing token in API");
        }
      }
    }
    else if (withGoogleToken)
    {
      const gtoken = await this.getCurrentToken();

      if (gtoken)
      {
        headers = headers.set("Authorization", "Bearer " + gtoken);
      }
      else
      {
        console.error("missing token in API");
      }
    }

    if (withGoogleCookie)
    {
      headers = headers.set("Cookie", "G_ENABLED_IDPS=google");
    }

    const options = { headers };

    return options;
  }

  /* ####################################### LOGIN ################################### */

  getJWT() 
  {
    return this.auth.jwt;

    // return this.gedLoginService.getJWT(); // ag
    return "";
  }

  getCurrentToken(): Promise<any> 
  {
    return this.auth.getTokenAsync();
  }

  getToken() 
  {
    return this.auth.token;

    // return this.googleLoginService.getToken(); // ag
    return "";
  }

  createAuthorizationHeader(headers: Headers, token) 
  {
    headers.set("Authorization", "Bearer " + token);

    return headers;
  }

  errorHandler(error: HttpErrorResponse) 
  {
    if (error.status != 404)
    {

    }
    // Traitement de l'erreur avec errorHandler
    throw new Error(error.message);
  }
}
