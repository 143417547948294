import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchPageComponent } from './containers';


const routes: Routes = [
  {path: ':keywords',component: SearchPageComponent, data:{title:environment.stringsFile.searchPage}},
  {path: '', component: SearchPageComponent, data:{title: environment.stringsFile.searchPage}}      

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule 
{ }
