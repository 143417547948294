import { SpaceDescComponent } from "./space-desc/space-desc.component";
import { TableSpaceComponent } from "./table-space/table-space.component";
import { FolderAddComponent } from "./folder-add/folder-add.component";
import { FolderDeleteComponent } from "./folder-delete/folder-delete.component";
import { FolderShareComponent } from "./folder-share/folder-share.component";
import { SpaceDocDescComponent } from "./space-doc-desc/space-doc-desc.component";
import { SpaceDoclinksComponent } from "./space-doclinks/space-doclinks.component";
import { SpaceDocMetaComponent } from "./space-doc-meta/space-doc-meta.component";

export const components = [
  FolderAddComponent,
  FolderShareComponent,
  FolderDeleteComponent,
  SpaceDocDescComponent,
  SpaceDocMetaComponent,
  SpaceDoclinksComponent,
  SpaceDescComponent,
  TableSpaceComponent,
];

export const entryComponents = [FolderShareComponent, FolderDeleteComponent];

export * from "./folder-add/folder-add.component";
export * from "./folder-delete/folder-delete.component";
export * from "./folder-share/folder-share.component";
export * from "./table-space/table-space.component";
export * from "./space-doc-desc/space-doc-desc.component";
export * from "./space-doc-meta/space-doc-meta.component";
export * from "./space-doclinks/space-doclinks.component";
export * from "./space-desc/space-desc.component";
