import { Injectable } from '@angular/core';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { GedDocService } from 'src/modules/documents/services';
import { FlaggingService } from 'src/modules/documents/services/flagging.service';
import { Workflow } from 'src/modules/workflow/models';
import { WorkflowService } from 'src/modules/workflow/services';

@Injectable({
  providedIn: 'root'
})
export class HttpService
{

  constructor(
    private gdocService: GDocService,
    private gedService: GedService,
    private gedDocService: GedDocService,
    private workflowService: WorkflowService,
    private flaggingService: FlaggingService,
  )
  { }

  getFile(docId)
  {
    return this.gdocService.getFile(docId)
  }

  getDoc(id)
  {
    return this.gedService.getDoc(id, null)
  }

  async rateDocument(uid, index)
  {
    return this.gedService.rateDocument(uid, index);
  }

  addDocToGed(document, form)
  {
    return this.gedService.addDocToGed(document, { curTypeDoc: undefined, data: form })
  }

  onUpdateGedDocMeta(form, documentCurent)
  {
    return this.gedDocService.onUpdateGedDocMeta(
      form,
      undefined,
      undefined,
      documentCurent
    )
  }

  getsteps(uid) : Promise<Workflow>
  {
    return this.workflowService.getSteps(uid)
  }

  status(docId)
  {
    return this.flaggingService.status(docId);
  }

  verify(docId, verifyOptions)
  {
    return this.flaggingService.verify(docId, verifyOptions);
  }

  flag(documentId, flagOptions)
  {
    return this.flaggingService.flag(documentId, flagOptions);
  }

  getRatingDoc(uid:string)
  {
    return this.gedService.getRatingDoc(uid);
  }

  getMyRatingDoc(uid:string)
  {
    return this.gedService.getMyRatingDoc(uid);
  }

}
