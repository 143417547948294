<div  class="add-space-drawer">
  <mat-card class="meta-card">
    <mat-card-content>
      <form #f="ngForm">
        <div  class="form-create">
          <div>
            <mat-form-field  appearance="fill" style="display: block; text-justify: center;" >
              <mat-label>Folder Type</mat-label>
              <input matInput [(ngModel)]="folderType" name="folderType" required
                [matAutocomplete]="auto" (ngModelChange)="doFilter(f.value.folderType)" #optionfield="ngModel">

              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectedType($event)">
                <mat-option *ngFor="let option of autoComplete" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngIf="showForm">
            <mat-form-field  appearance="fill" style="display: block; text-justify: center;"
            *ngFor="let field of formFields; let j=index">
              <mat-label>{{field.label}}</mat-label>
              <div *ngIf="field.auto==null && field.key!=='icon' && field.key!=='color'">
                <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"
                required="{{field.required && field.key!='description' && field.key!='app_url' }}">
              </div>
              <div  *ngIf="field.key==='color'">

                <mat-select   [(ngModel)]="field.value" name="{{field.key}}"  class="color-select" [ngStyle]="{ 'background-color': field.value }">

                  <mat-option *ngFor="let color of colors_ged" [value]="color" >
                    <div class="color-div"  [ngStyle]="{'background-color': color}" ></div>
                  </mat-option>
                </mat-select>
              </div>

              <div  *ngIf="field.key==='icon'" class="iconContent">
                <mat-icon class="childContent">{{field.value}}</mat-icon>
                <mat-select   [(ngModel)]="field.value" name="{{field.key}}" >

                  <mat-option *ngFor="let icon of material_icons" [value]="icon" >
                    <mat-icon aria-hidden="false" aria-label="icon">{{icon}}</mat-icon>
                    {{icon}}</mat-option>
                </mat-select>
              </div>

              <div *ngIf="field.auto!=null">
                <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"

                [matAutocomplete]="autofield" (ngModelChange)="doFilterField(field)" #optionfield="ngModel">

                <mat-autocomplete #autofield="matAutocomplete">
                  <mat-option *ngFor="let option of fieldAutoList" [value]="option.html">
                    {{option.html}}
                  </mat-option>
                </mat-autocomplete>

              </div>
            </mat-form-field>
          </div>

         
        </div>
        <div class="col-md-12 actions">
          <button auraButton  *ngIf="showForm"    (click)="cancelForm()"  label="{{'cancelBtn' | translate}}"  class="cancelBtn"> </button>
         <button auraButton type="light" [hasShadow]="true" label="{{ 'addBtn' | translate }}" (click)="onAddtoGed(f.value)" [disabled]="!f.valid" ></button>
        </div>
       
      </form>

    </mat-card-content>
  </mat-card>

</div>
