<div class="ps_bg-color rounded">
    <div class="table-responsive">
    <table class="w-100 solid-table table-hover">
      <thead *ngIf="!disableHead">
        <tr>
          <th *ngFor="let td of columns"  [ngClass]="{
              'canSort': td.canSort,
              'up': td.order === 'DESC',
              'down': td.order === 'ASC'
            }" [class]="td.class + ' col-md-2 col-sm-3'">
            <span (click)="td.canSort ? sortBy(td.id, td) : false">{{ td.label }}</span>
          </th>
        </tr>
      </thead>

      <tbody *ngIf="!loading">
        <tr *ngIf="title" class="title  d-md-none">
          <td colspan="7">
            <h5 class="mb-0 aura-h2">{{ title }}</h5>
          </td>
        </tr>

        <tr *ngFor="let row of data; let rowIndex = index" [class]="'hover-effect'" 
        (click)="!row[0]?.file?.id ? (row[0].onClick ? row[0].onClick(row) : '') : ''"
          [attr.data-id]="row[0]?.file?.id" class="bloc-info" [attr.data-type]="row[0]?.file?.mimeType">
          <ng-container *ngFor="let td of row" >
            <td [class]="data.class + ' col-s cursor-pointer ' "
            [attr.title]="data.type === 'Text' || data.type === 'TextWithIcon' ? td.longValue : null"
            *ngIf="{
              type: columns | _tableType:td.id,
              class: columns | _tableClass:td.id
            } as data" [ngClass]="{
                'ps_text-center': ['Menu', 'Popup'].includes(data.type)
              }" (click)="data.type !== 'Menu' && onClickRowEventHandler(td)"
              [ngClass]="{'max-content': data.type == 'Menu'}">
              <ng-container [ngSwitch]="data.type">
                <ng-template *ngSwitchCase="'TextWithIcon'" [ngTemplateOutlet]="CellTextWithIcon"></ng-template>

                <ng-template *ngSwitchCase="'Text'" [ngTemplateOutlet]="CellText"></ng-template>

                <ng-template *ngSwitchCase="'Checkbox'" [ngTemplateOutlet]="CellCheckbox"></ng-template>

                <ng-template *ngSwitchCase="'Date'" [ngTemplateOutlet]="CellDate"></ng-template>

                <ng-template *ngSwitchCase="'Action'" [ngTemplateOutlet]="CellAction"></ng-template>

                <ng-template *ngSwitchCase="'Menu'" [ngTemplateOutlet]="CellMenu"></ng-template>

                <ng-template *ngSwitchCase="'HTML'" [ngTemplateOutlet]="CellHTML"></ng-template>
              </ng-container>

              <ng-template #CellTextWithIcon>
                <div class="bloc ps_d-flex align-items-center col-m cursor-pointer">
                  <!-- [routerLink]="td.link || ''"> -->
                  <img *ngIf="td.iconImg" class="icon" [ngClass]="{ 'avatar': td.isCircle }" [src]="td.iconImg" />

                  <i *ngIf="td.iconClass" [class]="td.iconClass"></i>

                  <h6 class="mb-0 aura-h3">{{ td.value }}</h6>
                </div>
              </ng-template>

              <ng-template #CellText>
                <div class="bloc ps_d-flex align-items-center cursor-pointer">
                  <span>{{ td.value }}</span>
                </div>
              </ng-template>

              <ng-template #CellHTML>
                <div class="bloc ps_d-flex align-items-center html" (click)="td.onClick ? td.onClick(row) : ''">
                  <ng-container *ngTemplateOutlet="td.html || null; context: td.context || null"></ng-container>
                </div>
              </ng-template>

              <ng-template #CellCheckbox>
                <div class="bloc ps_d-flex align-items-center">
                  <div class="form-check">
                    <input class="form-check-input" id="flexCheckDefault" type="checkbox" />
                  </div>
                </div>
              </ng-template>

              <ng-template #CellDate>
                <div class="bloc ps_d-flex align-items-center">
                  <span>{{ td.value | date }}</span>
                </div>
              </ng-template>

              <ng-template #CellAction>
                <div class="bloc ps_d-flex align-items-center px-2 cursor-pointer"
                  (click)="!hasAction ? false : hasAction(td, row)">
                  <img *ngIf="td.iconImg" class="icon" [ngClass]="{
                      'avatar': td.isCircle
                    }" [src]="td.iconImg" />

                  <i *ngIf="td.iconClass" [class]="'m-auto ' + td.iconClass"></i>

                  <span>{{ td.value }}</span>
                </div>
              </ng-template>

              <ng-template #CellMenu>
                <aura-dropdown position="bottom-right" [items]="getMenuItems(td.menuItems, row)">
                  <div aria-expanded="false" id="dropdownMenuButton" ngbDropdownToggle type="button">
                    <i class="bi bi-three-dots-vertical" style="font-size: 1.4rem;"></i>
                  </div>
                </aura-dropdown>
              </ng-template>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    
  </div>

  <div *ngIf="(!data || data.length === 0) && !loading" class="empty_list">
    <p>{{ emptyTableMssage }}</p>
  </div>
  <div class="separator-line"></div>
  <div class="loader-wrap" *ngIf="loading">
    <aura-loader [show]="loading || false"></aura-loader>
  </div>
</div>