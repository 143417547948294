<div class="fullscreen">
    <div class="">
        <div class="row">
            <div class="col position-relative" [ngClass]="{'pe-5': isSideBarOpened}">
                <iframe class="file"
                    src="https://docs.google.com/document/d/1RB6NjIgEjYofBmrTtK8Hsk_zoU5JhGukIykQBWgh3hU/edit?usp=sharing"></iframe>
            </div>

            <div class="sidebar col-lg-4 position-relative" [ngClass]="{'isSideBarOpened': isSideBarOpened}">
                <div class="toggle-button" (click)="isSideBarOpened = !isSideBarOpened">
                    <img src="/assets/images/icon-collapse-sidebar.svg" alt="">
                </div>
                <div class=" px-0 right-side d-flex flex-column" id="collapseWidthExample">
                    <section class="title bg-white px-3 py-4">
                        <div class="row justify-content-between align-items-center mb-3">
                            <div class="col-2">
                                <img style="cursor: pointer;" (click)="goback()"
                                    src="/assets/images/icon-arrow-back.svg" alt="">
                            </div>
                            <div class="col">
                                <h3 class="mb-0 text-center">Document title</h3>
                            </div>
                            <div class="col-2">
                                <div class="dropdown" ngbDropdown>
                                    <img class="dropdown-toggle ms-auto me-0 d-block"
                                        src="/assets/images/icon-three-dots-circle.svg" alt="" data-bs-toggle="dropdown"
                                        aria-expanded="false" ngbDropdownToggle>
                                    <ul class="dropdown-menu" ngbDropdownMenu>
                                        <li><a class="dropdown-item" ngbDropdownItem href="#">Archive</a></li>
                                        <li><a class="dropdown-item" ngbDropdownItem href="#">Send By email</a></li>
                                        <li><a class="dropdown-item text-danger" ngbDropdownItem href="#">Delete</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="path">
                            <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
                            <ng-template #iconTemplate>
                                >
                            </ng-template>
                        </div>
                    </section>

                    <section class="main flex-fill d-flex flex-column">

                        <div class="controls py-4  d-flex justify-content-evenly">
                            <button (click)="currentTab = 0" [ngClass]="{'active': currentTab == 0}"
                                class="btn infos px-0 mx-2 active">Infos</button>
                            <button (click)="currentTab = 1" [ngClass]="{'active': currentTab == 1}"
                                class="btn linked px-0 mx-2">Linked
                                documents</button>
                            <button (click)="currentTab = 2" [ngClass]="{'active': currentTab == 2}"
                                class="btn comments px-0 mx-2">Comments</button>
                        </div>

                        <div *ngIf="currentTab == 0" class="tabs m-3 mt-0 bg-white rounded flex-fill">

                            <div class="px-3 py-4 mb-5">
                                <section class="steps-line row justify-content-between gx-0 "
                                    [ngClass]="'step-' + step">
                                    <div class="progress"><span [style.width.%]="100/(3 - 1) * step"></span></div>
                                    <div class="col position-relative number-step" data-label="Contrats all">
                                        <span
                                            class="d-flex justify-content-center align-items-center shadow-sm active">1</span>
                                    </div>
                                    <div class="col"></div>
                                    <div class="col position-relative number-step" data-label="Contrats Juridique">
                                        <span
                                            class="d-flex justify-content-center align-items-center shadow-sm">2</span>
                                    </div>
                                    <div class="col"></div>
                                    <div class="col position-relative number-step" data-label="Contrats DG">
                                        <span
                                            class="d-flex justify-content-center align-items-center shadow-sm">3</span>
                                    </div>
                                </section>
                            </div>

                            <section class="lists px-3 mb-3">
                                <ngb-accordion #acc="ngbAccordion" activeIds="panel-0">
                                    <ngb-panel class="py-4 border-0 border-top border-bottom shadow-none" id="panel-0">
                                        <ng-template ngbPanelTitle>
                                            <div class="py-3"><img class="align-text-bottom me-3"
                                                    src="/assets/images/icon-info-gris.svg" alt=""> Info
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <table class="w-100 tbl">
                                                <tbody>
                                                    <tr>
                                                        <td width="100">Client :</td>
                                                        <th class="value">Client Name</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="100">Date :</td>
                                                        <th class="value">12/09/2022</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="100">Montant :</td>
                                                        <th class="value">5000$</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="100">Reference :</td>
                                                        <th class="value">338639</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-template>
                                    </ngb-panel>

                                    <ngb-panel class="py-4 border-0 border-top border-bottom shadow-none" id="panel-1">
                                        <ng-template ngbPanelTitle>
                                            <div class="py-3"><img class="align-text-bottom me-3"
                                                    src="/assets/images/icon-file-gris.svg" alt=""> Etat de
                                                documents</div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <table class="w-100 tbl">
                                                <tbody>
                                                    <tr>
                                                        <td width="100">Client :</td>
                                                        <th class="value">Client Name</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="100">Date :</td>
                                                        <th class="value">12/09/2022</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="100">Montant :</td>
                                                        <th class="value">5000$</th>
                                                    </tr>
                                                    <tr>
                                                        <td width="100">Reference :</td>
                                                        <th class="value">338639</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ng-template>
                                    </ngb-panel>

                                </ngb-accordion>
                            </section>

                            <section class="feedback my-5 d-flex justify-content-center">
                                <div class="like me-5">
                                    <img src="/assets/images/icon-thumbs-up.png" alt="" class="align-text-bottom me-2">
                                    <span>599 Like</span>
                                </div>
                                <div class="dislike">
                                    <img (click)="doUnlike = true" src="/assets/images/icon-thumbs-down.png" alt=""
                                        class="align-middle me-2">
                                    <span>599 Dislike</span>
                                </div>
                                <app-tell-us-why [isOpen]="doUnlike" (close)="doUnlike = false"></app-tell-us-why>
                            </section>

                            <section class="px-3 d-flex justify-content-center mb-4 mt-5">
                                <button auraButton class="w-50" [full]="true" label="Submit" type="primary" size="large">
                                </button>
                            </section>

                            <section class="url pb-3 text-center">
                                <a data-bs-toggle="modal" data-bs-target="#exModal" class="a text-decoration-underline"
                                    (click)="moreDetailsModal = true">
                                    See all informations
                                </a>
                            </section>
                            <app-document-details [isOpen]="moreDetailsModal" (close)="moreDetailsModal = false">
                            </app-document-details>
                        </div>
                        <div *ngIf="currentTab == 1" class="tabs m-3 mt-0 bg-white rounded flex-fill pb-3 pt-4">

                            <section class="px-3" *ngFor="let item of linkedDocs">
                                <h4 class="pt-3 h4">{{item.category}}</h4>
                                <aura-list [data]="item.docs" [hasMenu]="true" verticalAlign="center"></aura-list>
                            </section>

                        </div>
                        <div *ngIf="currentTab == 2" class="tabs m-3 mt-0 bg-white rounded flex-fill">
                            <section class="chat h-100 d-flex flex-column">
                                <aura-comments [comments]="comments"></aura-comments>
                                <div class="mt-3 border-top mb-0 mt-auto">

                                     <!-- <app-comment-input (send)="onSend($event)"></app-comment-input> -->
 
                                 </div>
 
                            </section>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
