
<mat-card class="chip-card" *ngIf="showState" >
    <mat-card-header>
      <mat-card-title>Document metadata</mat-card-title>
    </mat-card-header>
  
  
    <mat-card-content>
      <div class="spinner-div">
        <mat-spinner *ngIf="isLoadingState"></mat-spinner>
      </div>
      <mat-list>
        <div *ngFor="let d of allData">
          <mat-list-item class="listitem" role="listitem">
            <span class="label">{{d.label}}</span>
            <span>{{d.val}}</span>
          </mat-list-item>
        </div>
    
    </mat-list>
      
  
  
    </mat-card-content>
  
</mat-card>