import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { APISce } from "src/modules/app-common/services";
import { DocumentService } from "src/modules/documents/services/document.service";
// import { GedLoginService, GoogleLoginService } from 'src/modules/user/services';
import { GedService } from "src/modules/app-common/services/ged/ged.service";

import { Space } from "../../models/space/space.model";
import { SpaceGroup } from "../../models/space/space-group.model";
import { Spacetype } from "../../models/type-space/spacetype.model";
import { Observable, from, of } from "rxjs";
import {
  VertexDrive,
  VertexReadableSearchResult,
  VertexSearchResult,
} from "../../models/vertex-search-result/vertex-search-result.model";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SpaceService 
{
  static instance: SpaceService;

  // raw data from server
  _rawSpaces: {}[];

  // spaces as
  _spaces: Space[];

  _spacesBySid = {};
  _spacesBySuid = {};
  _spacesByOid = {};
  _spacesByGroups = {};
  _spacesTemplates: SpaceGroup = new SpaceGroup(null, "Templates");

  constructor(
    private router: Router,
    private docService: DocumentService,
    private api: APISce,
    private gedService: GedService
  ) 
  {
    SpaceService.instance = this;
  }

  //Open document in a space
  openDoc(file) 
  {
    return this.openFileOrFolder(file);
  }

  openFileOrFolder(file) 
  {
    // get mime type : doc or folder
    const url = this.docService.getRoutebyMimeType(file.mimeType) + file.id;

    // then navigate to file or folder
    this.router.navigate([url], {
      queryParams: { d: localStorage.host_domain, relativeTo: this.router },
    });
  }

  // open document by id
  openDocTab(document) 
  {
    this.navigateToDocById(document.id);
  }

  // open document by id
  openDocInList(id: string, prefix = "") 
  {
    this.navigateToDocById(id, prefix);
  }

  // navigate relative to current space
  navigateToDocById(uid: string, prefix = "") 
  {
    const url = prefix + "/file/" + uid;

    this.router.navigate([url], {
      queryParams: { d: localStorage.host_domain },
    });
  }

  // add or replace a space
  // add it in various groups and lookups
  listeSpaces: Spacetype[] = [];
  addSpace(space: Space, replace = false) 
  {
    // group by uid and oid
    this._spacesByOid[space.getOid()] = this._spacesBySuid[space.getUid()] =
      space;

    // group by doc type name
    const docType = this.getDocTypeByOid(space.getDocTypeOid());

    space.setDocTypeName(docType && docType["title"]);

    const sid = space.getSid();

    if (!this._spacesBySid[sid])
    {
      this._spacesBySid[sid] = new SpaceGroup(space, "spacesBySid");
    }

    this._spacesBySid[sid].addSpace(space);

    // group by group name
    const groupName = space.getGroupName();

    if (groupName != null) 
    {
      if (groupName.indexOf("Template") == -1) 
      {
        // regular space
        if (!this._spacesByGroups[groupName])
        {
          this._spacesByGroups[groupName] = new SpaceGroup(
            space,
            "spacesByGroup"
          );
        }

        space.setGroupName(groupName);
        this._spacesByGroups[groupName].addSpace(space, replace);
      }
      else 
      {
        // template space
        this._spacesTemplates.addSpace(space, replace);
      }
    }
    else 
    {
      this.listeSpaces.push(new Spacetype(space));
    }
  }

  async clearCache() 
  {
    await this._loadSpaces(false);
  }

  // load full list of spaces
  async _loadSpaces(cache = true, raw = true): Promise<Space[]> 
  {
    if (cache && this._spaces) 
    {
      return this._spaces;
    }
    this.listeSpaces = [];
    this._spaces = [];

    // send request in parallel for doc types
    const promiseLoadTypes = this.loadDocTypes();

    // then request spaces
    const url = `/ged/my-spaces`;
    const options = await this.api.httpConfig();

    let spaces;

    this._spaces = [];
    this._spacesBySid = {};
    this._spacesBySuid = {};
    this._spacesByOid = {};
    this._spacesByGroups = {};

    this._spacesTemplates = new SpaceGroup(null, "Templates");

    try 
    {
      const response = await this.api.get(url, {}, options);

      // wait for doc types request previously sent
      await promiseLoadTypes;

      if (response["content"]?.data) 
      {
        this._rawSpaces = response["content"]?.data;

        spaces = (this._rawSpaces || []).map((spaceData: {}) => 
        {
          const space = new Space(spaceData);

          this.addSpace(space, false);

          return space;
        });
      }

      // add spaces to cache
      this._spaces = spaces;

      return this._spaces;
    }
    catch (error) 
    {
      console.log(error);
      throw error;
    }
  }

  async getSpaceByUid(suid: string): Promise<Space> 
  {
    await this._loadSpaces();

    return this._spacesBySuid[suid] || null;
  }

  getSpaceByOid(oid) 
  {
    return this._spacesByOid[oid] || null;
  }

  getSpacesBySid(oid) 
  {
    return this._spacesByOid[oid] || null;
  }

  getSpacesByGroupName(groupName) 
  {
    return (
      this._spacesByGroups[groupName] &&
      this._spacesByGroups[groupName].getSpaces()
    );
  }

  async getSpaceGroups() 
  {
    await this._loadSpaces();

    return this._spacesByGroups;
  }
  async getSpaces() 
  {
    await this._loadSpaces();

    return this.listeSpaces;
  }

  async getSpaceTemplates() 
  {
    await this._loadSpaces();

    return this._spacesTemplates;
  }

  // OLD code
  docTypes = [];
  docTypesByOid: {};
  spacesTypes: [{ html: string; space: any }?] = [];
  spacesByGroups: {};
  spacesByTypes: {};
  spacesTemplate = [];

  spaceGroupsByTypeOid = {}; // spaces list by type of document
  spacesByUid: {}; // spaces by google uid
  spacesByOid: {}; // space by GED oid

  async getSpace(
    suid: string,
    withMeta = true,
    withContent = true
  ): Promise<Space> 
  {
    let space: Space;

    try 
    {
      if (!withMeta && !withContent) 
      {
        space = await this.getSpaceByUid(suid);
        if (space) return space;
      }

      const url = `/ged/spaces/uid/${suid}?$withMeta=true`;
      const options = await this.api.httpConfig();

      const response = await this.api.get(url, {}, options);

      space = new Space(response);

      // old
      const sid = space.getSid();

      this._spacesBySid[sid] = this._spacesBySuid[suid] = space;

      // new
      this.addSpace(space, true);

      return space;
    }
    catch (error) 
    {
      console.error(error);

      return error;
    }
  }

  // get list of types
  async loadDocTypes() 
  {
    if (this.docTypesByOid) return this.docTypesByOid;

    this.docTypesByOid = {};
    this.docTypes = (await this.gedService.getDocTypes("")) || [];

    this.docTypes.forEach((docType) => 
    {
      this.docTypesByOid[docType["oid"]] = docType;
    });

    return this.docTypesByOid;
  }

  getDocTypeByOid(oid) 
  {
    return this.docTypesByOid[oid] || null;
  }

  async getGroup(space: Space): Promise<SpaceGroup> 
  {
    await this._loadSpaces();

    if (space) 
    {
      const groupName = space.getGroupName();

      if (groupName) return this._spacesByGroups[groupName];
    }

    return null;
  }

  async getSpacebySid(sid, cache = true, raw = true) 
  {
    await this._loadSpaces(cache);

    const space = this._spacesBySid[sid];

    if (space && raw) return space.data();

    return space;
  }
}
