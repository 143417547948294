import { MetatableComponent } from "./metatable.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MetatableRoutingModule } from "./metatable-routing.module";
import { CrudManagerModule } from "../crud/crud-manager.module";
import { AppCommonModule } from "../app-common/app-common.module";

@NgModule({
  declarations: [MetatableComponent],
  imports: [
    CommonModule,
    AppCommonModule,
    MetatableRoutingModule,
    CrudManagerModule,
  ],
  exports: [MetatableComponent],
})
export class MetatableModule 
{}
