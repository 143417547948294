import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { SpaceElementComponent, SpaceItem } from '../space-element/space-element.component';

@Component({
  selector: 'app-spaces-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgIf,
    SpaceElementComponent],
  templateUrl: './spaces-list.component.html',
  styleUrls: ['./spaces-list.component.scss']
})
export class SpacesListComponent implements OnInit 
{

  @Input()
    spaces: SpaceItem[]
  constructor() 
  { }

  ngOnInit(): void 
  {
  }

  public trackByFn(index, space: SpaceItem) 
  {
    if (space?.path)
    {
      return space.path;
    }
  }
}
