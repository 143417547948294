// ========== DISPLAYS A FOLDER ===========

import {
  Component,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
  OnInit,
  Output,
  ViewEncapsulation,
  Inject,
} from "@angular/core";

import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GDocService, GedService } from "src/modules/app-common/services";
import { RouteService } from "src/modules/app-common/services/route/route.service";
import { DocumentService } from "src/modules/documents/services/document.service";
import { SpaceHomeComponent } from "../../containers";
import { Folder } from "../../models/folder/folder.model";
import { SpaceService } from "../../services";

import { AfterViewInit, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { SpaceContent } from "../../models/space/space-content.model";
import { DOCUMENT } from "@angular/common";

// material design
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";

// design system
// import { ListItem } from "@interfaces/list-item";
import { TableColumn, TableRow } from '@interfaces/table';

const themePath = environment.themePath;

@Component({
  selector: "app-table-space",
  templateUrl: `./${themePath}/table-space.component.html`,
  styleUrls: [`./${themePath}/table-space.component.scss`],
  encapsulation: ViewEncapsulation.None,
})

export class TableSpaceComponent implements OnInit 
{
  paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.ref);

  @Output() emitState: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;

  pdoc:boolean =  true; // use pdoc design ?

  spaceContent;
  tableContent: any = [];

  // i8N strings
  NomStr = environment.stringsFile.NomStrg;
  PropriétaireStr = environment.stringsFile.Creator;
  DatemodificatonStr = environment.stringsFile.Datemodificaton;
  TypeStr = environment.stringsFile.Type;
  EtatStr = environment.stringsFile.Etat;
  ActionsStr = environment.stringsFile.Actions;

  //***/folder : "Open folder in Google Drive ", fichier : "Open fichier in Google Drive", open : "Open" */
  DriveContent: Folder[] = [];
  FolderContent: any = [];
  files;
  titles = [];
  fichier;
  docinGed;
  // dataSource = new MatTableDataSource();

  displayedColumns: string[] = [];
  displayHeaderValeur: string[] = [this.NomStr, this.PropriétaireStr,this.DatemodificatonStr, this.TypeStr];

  hasStateColums = false;
  columnsToDisplay: string[];

  // ROW SELECTION METHODS
  selectedRowIds: Set<number> = new Set<number>();
  selectedRowId: number;
  selectedRow = null;

  selected;
  isDocument = false;
  fichiers: any[] = [];
  parents = [];
  currentId = "";
  dataLength;
  numberItems: number = 0;
  nextToken;
  folderContent: SpaceContent;

  dataListe = [];
  folderId: string; // current folder id
  static instance:TableSpaceComponent;

  constructor(
    private gdocService: GDocService,
    private router: Router,
    private route: ActivatedRoute,
    private drawer: SpaceHomeComponent,
    private gedService: GedService,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private docService: DocumentService,
    private routeService: RouteService,
    private spaceHome: SpaceHomeComponent,
    private spaceService: SpaceService,
    private documentService: DocumentService,
    @Inject(DOCUMENT) private document: Document
  )
  {
    TableSpaceComponent.instance = this ;
  }

  path = ''
  ngOnInit()
  {
    // get path
    this.route.url.forEach((urls) =>
    {
      this.path = urls[0]["path"];
    })

    // get folder uid param and load folders
    this.route.params.forEach((params) =>
    {
      this.parents = [];
      this.currentId = params["spaceId"];
      const id = this.currentId;

      localStorage.setItem("spaceId", id);

      // load data
      this.loadItems(id);
    });
  }

  // load more items when we hit the screen bottom
  onWindowScroll()
  {
    const elem = document.getElementById("divScroll");
 
    // bottom marker below scroll window?
    const endScroll = (elem.scrollTop + elem.offsetHeight +1) >= elem.scrollHeight;

    // yes, so request next items
    if (endScroll && this.nextToken)
    {
      this.route.params.forEach((params) => 
      {
        const id = params["spaceId"];

        this.getMoreItems(id, this.nextToken);
      });
    }
  }

  // load next page
  // columnsDesc: { fname : string, label: string }[];
  columnsDesc: any [];
  activeTab: string = "table";

  // get next page from server
  async loadItems(id : string)
  {
    this.dataListe = [];

    return this.getMoreItems(id,null);
  }

  async getMoreItems(id : string, token=null)
  {
    this.folderId = id;

    try
    {
      const response = await this.gdocService.getFolderItems(id, token);



      const res = response["spaceContent"];

      this.nextToken = response["nextPageToken"];

      this.spaceContent = res;
      this.tableContent = res;
    }
    catch (error)
    {
      return;
    }

    // push new items to the full list
    let i = 0;

    for (const item of this.tableContent)
    {
      const item2: SpaceContent = SpaceContent.build(item);

      this.tableContent[i] = item2;

      if(item2.hasState())
      {
        this.hasStateColums = true;
      }

      this.dataListe.push(item2);
      i++;
    }

    this.columnsDesc = [
      { fname:"name", label:this.NomStr },
      //{ fname:"Owner", label:this.PropriétaireStr },
      { fname:"date", label:this.DatemodificatonStr },
      { fname:"type", label:this.TypeStr }
    ];

    if (this.hasStateColums)
    {
      this.columnsDesc.push({ fname:"state" , label:this.EtatStr });

      this.displayedColumns = ["name", "Owner", "date", "type", "state", "Actions"];
      //this.displayHeaderValeur.push(this.EtatStr, this.ActionsStr);
      this.displayHeaderValeur = [this.NomStr, this.PropriétaireStr,this.DatemodificatonStr, this.TypeStr,
        this.EtatStr, this.ActionsStr];
    }
    else
    {
      this.displayedColumns = ["name", "Owner", "date", "type", "Actions"];
      // this.displayHeaderValeur.push(this.ActionsStr);
      this.displayHeaderValeur = [this.NomStr, this.PropriétaireStr,this.DatemodificatonStr, this.TypeStr,
        this.ActionsStr];
    }

    this.columnsToDisplay = this.displayedColumns.slice();
    // create data for tables
    // this.prepareMatTable(this.dataListe);
    this.prepareTable(this.folderId, this.dataListe,this.columnsDesc);
  }

  /*
  prepareMatTable(dataListe) {
    this.dataSource = new MatTableDataSource(dataListe);
    this.dataSource.sort = this.sort;
  }
  */

  // table V2
  columns: TableColumn[];
  data: TableRow[][];

  prepareTable(folderId,dataListe,columnsDesc)
  {
    // init header
    this.columns = columnsDesc.map(col =>
    {

      if(col.fname=='name')
      {
        return  {
          id:col.fname,
          label : col.label,
          type: 'TextWithIcon',
          width: 260
        };
      }
      else
      {
        return  {
          id:col.fname,
          label : col.label,
          type: 'Text',
          canSort: true,
        };
      }
    });

    this.columns.push({
      id: 'menu',
      label: "Actions",
      type: 'Menu',
      width: 55
    });

    // init data
    const rows = dataListe.map(file =>
    {
      let link,iconLink48;

      // let parentId = this.documentService.getParentId(file);

      if(file.isFolder())
      {
        // iconLink48 = this.documentService.getFolderIcon48(file.iconLink);
        iconLink48 = file.iconLink;
        link = "/s/space/" + file.id;
      }
      else
      {
        iconLink48 = this.documentService.getGoogleIconFrom16(file.iconLink,32);
        link = this.fileLink(file);
      }

      const row = columnsDesc.map(col =>
      {
        const fname = col.fname;
        let value = file.getProp(fname) || '';

        if(fname=='name') 
        {
          return  {
            id:fname,
            value,
            iconImg: iconLink48,
            link,

          };
        }
        else
        {
          if(fname == "date" && value=="Invalid Date")
          {
            value = "";
          }

          return  {
            id:fname,
            link,
            value,

          };
        }
      });

      if (file.isFolder())
      {
        row.push({
          id: 'menu',
          value: '',
          menuItems: [
            {
              label: 'Open folder',
              callback: () => 
              {
                this.openFolder(file);
              },
            }
          ],
        });
      }
      else
      {
        row.push({
          id: 'menu',
          value: '',
          menuItems: [
            {
              label: 'Open file',
              callback: () => 
              {
                this.openDoc(file);
              },
            }
          ],
        });
      }

      return row;
    });

    this.data = rows;
  }

  fileLink(file) 
  {
    return "/s/space/" +this.folderId+"/file/" + file.id;
  }

  openDoc(file)
  {
    const url = this.fileLink(file);

    this.router.navigate([url], {
      queryParams: { d: localStorage.host_domain },
    });
  }

  openFolder(item) 
  {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
      this.router.navigate(["/s/space/" + item.id], {
        queryParams: { d: localStorage.host_domain },
      })
    );
  }

  //  ================ ROW click management ============

  // dbl click : open file
  onRowDblClick(row) 
  {
    this.selectedRow = row;
    this.spaceService.openDoc(row);
  }

  // simple click :
  onRowClick(row) 
  {
    this.selectedRow = row;

    if (row.isFolder())
    {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
        this.router.navigate(["/s/space/" + row.id], {
          queryParams: { d: localStorage.host_domain },
        })
      );

      // this.router.navigate([url])
    }
    else
    {
      this.drawer.openDrawer();
      this.isDocument = true;

      this.gdocService
        .getFile(row.id)
        .then()
        .then((data) =>
        {
          const path =
            "/" + data["name"] + "" + this.getDocReader(data.mimeType, true);

          localStorage.setItem("path", path);

          this.gedService.getDoc(this.selectedRow.id, path).then((res) =>
          {
            const temp = res;

            if (temp != null && typeof temp == "object")
            {
              this.docinGed = true;
            }
            else
            {
              this.docinGed = false;
            }

            this.emitState.emit({
              isdoc: this.isDocument,
              document: this.selectedRow,
              docinGed: this.docinGed,
            });
          });
        });
    }
  }

  rowClick(row)
  {
    if (row.isFolder())
    {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
        this.router.navigate(["/s/space/" + row.id], {
          queryParams: { d: localStorage.host_domain },
        })
      );
    }
    else
    {
      this.spaceService.openDocTab(row);
    }
  }


  toExternalLink(f)
  {
 
    if (f.type.includes("Folder")) 
    {
      window.open("https://drive.google.com/drive/folders/" + f.id, "_blank");
    }
    else 
    {
      this.gdocService
        .getFile(f.id)
        .then()
        .then((data) => 
        {
          const doc = data;

          window.open(doc.webViewLink, "_blank");
        });
    }
  }

  rowIsSelected(row) 
  {
    //return this.selectedRowIds.has(id);
    return this.selectedRow == row;
  }

  getDocReader(name: string, gedReader = false)
  {
    if (gedReader) 
    {
      let ext = "";

      name = name.toLowerCase();
      if (name.includes(".png")) ext = ".png";
      if (name.includes(".jpeg") || name.includes(".jpg")) ext = ".jpeg";
      if (name.includes("sheet")) ext = ".gsheet";
      if (name.includes("document")) ext = ".gdoc";
      if (name.includes("presentation")) ext = ".gpres";

      return ext;
    }
    else
    {
      let docType = "";

      if (name.includes("pdf")) docType = "pdf";
      if (name.includes("sheet")) docType = "spreadsheets";
      if (name.includes("doc")) docType = "document";
      if (name.includes("pres")) docType = "presentation";
      if (name.includes("form")) docType = "forms";
      localStorage.setItem("path", docType);

      return docType;
    }
  }

  // ========= UPLOAD FILES =============

  fileBrowseHandler(fichiers) 
  {
    this.prepareFilesList(fichiers);
  }

  prepareFilesList(fichiers: Array<any>) 
  {
    for (const item of fichiers) 
    {
      item.progress = 0;
      this.fichiers.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  uploadFilesSimulator(index: number) 
  {
    setTimeout(() => 
    {
      if (index === this.fichiers.length) 
      {
        return;
      }
      else 
      {
        const progressInterval = setInterval(() => 
        {
          if (this.fichiers[index].progress === 100) 
          {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          }
          else 
          {
            this.fichiers[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
  uploadLoading = false

  async onFileDropped($event: File[])
  {
    this.uploadLoading =  true

    for (const item of $event)
    {
      this.fichiers.push(item);

      if(this.path=="space")
      {
        const parentId = this.currentId
        const data = await this.gedService.uploadFile(item, parentId);

        if (data["content"])
        {
          const uploadedDoc = data["content"];

          this.uploadLoading = false;

          this.snackBar.open("File " + item.name + " is uploaded!", "",
            {
              duration: 5000,
            });

          await this.loadItems(this.currentId);
        }
        else
        {
          this.snackBar.open("L'envoi de fichiers a échoué, vérifiez vos accès sur le dossier!",
            "",{duration: 5000,});
        }
      }
    }
  }

  deleteFile(index: number)
  {
    this.fichiers.splice(index, 1);
  }

  formatBytes(bytes, decimals = 2)
  {
    if (bytes === 0) 
    {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
