import { EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GedService } from 'src/modules/app-common/services';
import { WorkflowService } from 'src/modules/workflow/services';
import { Workflow } from 'src/modules/workflow/models/workflow.model'

const themePath = environment.themePath;

@Component({
  selector: 'app-doc-infos',
  templateUrl: `./${themePath}/doc-infos.component.html`,
  styleUrls: [`./${themePath}/doc-infos.component.scss`]
})
export class DocInfosComponent implements OnInit, OnChanges
{
  @Input('documentCurent') documentCurent;
  @Input('reloadDoc') reloadDoc;
  @Input('path') path;
  @Input('canEdit') canEdit;
  @Input() isOpen: boolean = false;
  @Input("spaceName") spaceName;
  @Input() window: number = 0;
  isRelatedDocs : boolean = false;


  @Output() close = new EventEmitter<any>();

  hasWorkflow: boolean = true;
  workflowTasks: any;
  workflowHistoric: any;
  curentPath
  title: string = environment.stringsFile.docInfos;
  currentTab: number = 0;
  step: number = 0;
  linksDoc = environment.stringsFile.link;
  currentDocId = localStorage.docId;
  docId;
  doc;
  inGed = false;
  typeDoc: any;
  iseditMeta: boolean = false;

  constructor(
    private gedService: GedService,
    private workflowService: WorkflowService,
    private route: ActivatedRoute)
  {
    //this.getWorkflow(this.currentDocId);
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.documentCurent && changes.path)
    {
      this.isDocinGed(this.docId);
      this.curentPath = this.path;
    }
  }

  ngOnInit(): void
  {
    this.typeDoc = this.documentCurent?.content?.data?.type_document.html;

    this.route.params.forEach(params =>
    {
      this.docId = params["docId"]
      //  this.isDocinGed(this.docId);
    })

  }

  isDocinGed(docId)
  {
    const gedDocument = this.documentCurent;

    // check if document is ok
    if (!!gedDocument && gedDocument != null && typeof gedDocument == "object")
    {
      this.doc = gedDocument;
      this.inGed = true;
    }
    else
    {
      this.inGed = false;
    }

  }

  /*   getWorkflow(currentDocId){
      this.workflowService.getWorkflow(currentDocId)
        .then((data)=>{
          this.workflowTasks=data.content.tasks.items;
         });
    }
   */
  updateMetas(event)
  {
    if (event.reloadDoc)
    {
      this.isDocinGed(this.docId);
    }
  }

  closeModal() 
  {
    this.close.emit();
  }

  setWorkflowTasks(workflow : Workflow) 
  {
    // this.hasWorkflow = workflow.hasWorkflow();

    this.workflowTasks = workflow.getTasks();
  }

  setHistory(data) 
  {
    this.workflowHistoric = data
  }
  getDocLinks($event)
  {
    if($event.length != 0)
    {
      this.isRelatedDocs = true
    }
    else
    {
      this.isRelatedDocs = false
    }
  }

}
