import {
  AfterViewInit,
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import {
  MatTabChangeEvent,
  MatTabGroup,
  MatTabLabel,
} from "@angular/material/tabs";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import { DocType } from "src/modules/app-common/models/doc-type/doc-type.model";
import { DoctypeDialogData } from "src/modules/app-common/models/doctype-dialog-data/doctype-dialog-data.model";
import { GedService } from "src/modules/app-common/services";
import { DocumentService } from "src/modules/documents/services/document.service";
import { SearchGenericService } from "src/modules/search-generic/services/search-generic.service";
import { SpaceService } from "src/modules/spaces/services";
import { DocModalComponent } from "../components/doc-modal/doc-modal.component";
import { FileItem } from "src/modules/spaces/models";
import { FormManagerComponent } from "src/modules/form-manager/form-manager.component";
import { PDocEventsService } from "src/modules/p-doc-envents/p-docs-events.service";
import { AuthService } from "src/modules/user/services";
import { filter, tap } from "rxjs/operators";

const themePath = environment.themePath;

@Component({
  selector: "app-space-doc-add",
  templateUrl: "./space-doc-add.component.html",
  styleUrls: ["./space-doc-add.component.scss"],
})
export class SpaceDocAddComponent implements OnInit, AfterContentChecked 
{

  resultGroup: string;
  searchResponseObservable: any;
  searchResponse: [];
  @Inject(MAT_DIALOG_DATA) public data: DoctypeDialogData;

  @Input() isOpen: boolean = false;
  @Input("spaceUid") spaceUid;
  @Input("space") space;

  @Output() close = new EventEmitter<any>();

  @ViewChild(FormManagerComponent) childComponent: FormManagerComponent;


  typeDocStr: string = environment.stringsFile.newDocmodal;

  title: string = "New Document";
  isLoading: boolean = false;

  previewLink: string;
  temp = null;
  docType = {
    oid: "",
    title: "",
  };
  currentId: string;
  fieldAutoList = [];

  inGed = false;
  showForm: boolean = false;
  done: boolean = false;

  currentDoc;

  jsontosend = {};
  templateId: string = "";
  spaceId: string = "";

  autoComplete;
  typeList = [];
  listeSpaces = [];
  listeTemplates = [];

  templateListe;
  defaultTemplate;
  defaultSpace;
  jsonData = {};
  documentDrive; // : FileItem;
  inputData : any;
  formManagerFields: FormGroup;
  goToStep2 : boolean;
  currentName : string;
  formValid : boolean = false;

  @ViewChild("drawer") public drawer: MatDrawer;
  //@ViewChild('f') public docFormulaire:NgForm;
  @ViewChild("f") f: NgForm;

  formFields = [];
  temporaryList = [];
  tags = [];
  step2: boolean = false;
  step3: boolean = false;
  @ViewChild("optionsContainer", { static: false })
    optionsContainer: ElementRef;

  @ViewChild("docModal") docModalComponent: DocModalComponent;
  @ViewChild("f") public docFormulaire: NgForm;

  @ViewChild("container", { static: false }) container: ElementRef;

  searchKey = new FormControl();
  selectedTabIndex = 0; // Initialize the selected tab index to 0

  currentStep: number = 1;

  formFieldValues: any = {};

  scrollPositions: Map<number, number> = new Map<number, number>();

  docModalScrollPosition = 0;

  scrollPositionStep2: number = 0;
  templateSelected = false;
  documentSelected = false;
  selectedTemplate;
  selectFromDrive: boolean = false;

  selectedTabLabel: string;
  checkedTemplateId: string;

  /** is a document uploaded ? */
  isUploaded: boolean = false;

  /** form field inpu for uploaed file from PC */
  docUploaded : File;

  CurrentRoute: string;
  CurrentSearch: string;
  @ViewChild("tabGroup") tabGroup;

  static instance: SpaceDocAddComponent;
  constructor(
    // public dialogRef: MatDialogRef<AddDoctypeComponent>, @Inject(MAT_DIALOG_DATA) public data: DoctypeDialogData,
    private router: Router,
    private route: ActivatedRoute,
    private spaceService: SpaceService,
    private gedService: GedService,
    private documentService: DocumentService,
    private searchGenericService: SearchGenericService,
    private cdr: ChangeDetectorRef,
    private pDocsEventsService: PDocEventsService,
    private auth: AuthService
    //private route: Route
  ) 
  {
    SpaceDocAddComponent.instance = this;
  }

  ngAfterContentChecked() 
  {
    if (this.tabGroup) 
    {
      const activeTabIndex = this.tabGroup.selectedIndex;

      //const tabLabel = this.tabGroup._tabs.toArray()[activeTabIndex];
      //  this.selectedTabLabel = this.getCustomTabLabel(tabLabel);
      // this.selectedTabLabel =   this.getCustomTabLabel(this.selectedTabIndex);
    }
  }

  getCustomTabLabel(tabIndex: number): string 
  {
    let customLabel: string;

    switch (tabIndex) 
    {
    case 0:
      customLabel = environment.stringsFile.addRecentDoc;
      break;
    case 1:
      customLabel = environment.stringsFile.docmodal;
      break;
    case 2:
      customLabel = environment.stringsFile.docDrive;
      break;
    case 3:
      customLabel = environment.stringsFile.importDoc;
      break;
    }

    return customLabel;
  }

  getSelectedTabLabel(tabGroup: MatTabGroup) 
  {

    //const activeTabIndex = tabGroup.selectedIndex;
    // this.selectedTabLabel = this.getCustomTabLabel(activeTabIndex);
  }

  getSelectedTab(tabGroup) 
  {
    this.selectedTabIndex = tabGroup;

    this.selectedTabLabel = this.getCustomTabLabel(tabGroup);

    if (this.selectedTabLabel === environment.stringsFile.docDrive) 
    {
      this.selectFromDrive = true;
    }
    else 
    {
      this.selectFromDrive = false;
    }
  }
  ngAfterViewInit() 
  {
    const storedScrollPosition = localStorage.getItem("scrollPosition");

    this.scrollPosition = storedScrollPosition
      ? parseInt(storedScrollPosition, 10)
      : 0;
  }

  onOptionSelected(optionId: string) 
  {
    this.checkedTemplateId = optionId;
  }


  ngOnInit(): void 
  {
    if (this.currentStep == 1) 
    {
      this.route.params.forEach((params : {}) =>
      {
        this.spaceUid = params['spaceId'];
        this.getDocumentTypes();
      });
     
    }
    //this.selectedTabIndex = this.currentStep - 1;
  }

  scrollPosition: number = 0;

  saveScrollPositionStep2(scrollPosition: number) 
  {
    this.scrollPosition = scrollPosition;
    localStorage.setItem("scrollPosition", scrollPosition.toString());
  }

  nextStep() 
  {
    if (this.currentStep === 2) 
    {
      this.scrollPositionStep2 = this.scrollPosition;
    }
    // this.selectedTabIndex = this.currentStep - 1;

    this.currentStep++;
  }

  prevStep() 
  {
    this.currentStep--;

    if (this.currentStep === 1) 
    {
      this.selectFromDrive = false;
    }
    if (this.currentStep === 3) 
    {
      this.scrollPositionStep2 = 0;
    }

    //this.selectedTabIndex = this.currentStep - 1;
  }

  getSelectedIndex() 
  {
    const selectedIndex = this.tabGroup.selectedIndex;
  }

  ngAfterViewChecked() 
  {
    this.cdr.detectChanges();
  }

  closeModal() 
  {
    if (this.docFormulaire) 
    {
      this.docFormulaire.form.reset();
    }
    this.docType.title = "";
    this.selectedTabIndex = 0;
    this.selectFromDrive = false;
    this.currentStep = 1;
    this.getDocumentTypes();
    this.showForm = false;
    this.goToStep2 = false;
    //this.drawer.close();
    this.close.emit();
  }

  doFilter(value)
  {
    if (value == "")
    {
      this.typeList = [];
      console.log('hy')
    }
    else
    {
      console.log('hello')
      this.gedService.getDocTypes(value)
        .then((res) =>
        {
          this.autoComplete = res;
          this.typeList = [];

          for (const option of Object.values(this.autoComplete))
          {
            this.typeList.push(option["title"]);
          }
          this.filterType(this.typeList, value);
        });
    }
  }

  filterType(options, value) 
  {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.toLowerCase().includes(value)
    );
  }

  doFilterField(field) 
  {
    if (field.value == "") 
    {
      field.fieldAutoList = [];
    }
    else 
    {
      this.gedService
        .getAutocompleteList(field.value, field.auto.url)
        .then((res) => 
        {
          let autoComplete = [];

          autoComplete = res;

          field.fieldAutoList = [];
          for (const option of Object.values(autoComplete)) 
          {
            field.fieldAutoList.push(option);
          }

          this.filter(field.fieldAutoList, field.value);
        });
    }
  }

  filter(options, value) 
  {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.html.toLowerCase().includes(value)
    );
  }

  async getSpacebyType(type) 
  {
    const space = this.spaceService.getSpacebySid(type);

    return space;
  }

  // Document type select
  isType: boolean = false;
  onSelectedType(event) 
  {
    if(!event.value)
    {
      this.goToStep2 = false;
    }
    else
    {
      this.goToStep2 = true;
    }
    this.formFields = [];
    const type = event.title || event.value;

    this.gedService.getDocTypes("", null, this.spaceUid).then((res) => 
    {
      this.isType = true;
      this.autoComplete = res;
      let oid;

      this.step2 = true;
      // this.currentStep = 2
      for (const item of this.autoComplete) 
      {
        if (item.title == type) 
        {
          oid = item.oid;
          this.docType = item;
          this.metadataByType(oid, true);
        }
      }
    });
  }

  metadataByType(oid, addnewDoc: boolean) 
  {
    if (!addnewDoc && this.templateSelected) 
    {
      // If addnewDoc is false and there is an existing selected template,
      // use the existing selected template and skip the template selection part
      this.showForm = true;
      for (const field of this.formFields) 
      {
        this.formFieldValues[field.key] = field.value;
      }

      // Return the existing formFields
      return this.formFields;
    }

    // Fetch metadata and select template
    this.formFields = [];
    this.gedService
      .getMetaByType(oid, true, true, null, addnewDoc)
      .then((res) => 
      {
        this.showForm = true;
        const data = res["data"];

        this.inputData = res;
        this.inputData.metadata.fields.space = {...this.inputData.metadata.fields.space, 'x-show': false};
        this.inputData.metadata.fields.template = {...this.inputData.metadata.fields.template, 'x-show': false};
      
        this.inputData.data= {...this.inputData.data, 'name':this.documentDrive?.name ?? this.docUploaded?.name };

        const metadata = res["metadata"]["fields"];

        for (const key of Object.keys(metadata)) 
        {
          /*if (
            metadata[key]["x-show"] != false &&
            metadata[key]["show"] != false
          ) {*/
          // this.drawer.open();
          let element: DocType = new DocType(
            key,
            data[key],
            metadata[key],
            this.gedService
          );

          //temporary
          //begin
          if (element.getAuto() != null && element.getControl() == "select") 
          {
            this.gedService
              .getAutocompleteListSelect(element.getUrlAuto())
              .then((autolist) => 
              {
                let tempList = [];

                tempList = autolist || [];

                for (const item of tempList) 
                {
                  element.fieldAutoList.push(item);
                }
              });
          }
          else if (element.getControl() == "checkbox") 
          {
            if (element.getUrlAuto()) 
            {
              this.gedService
                .getAutocompleteListSelect(element.getUrlAuto())
                .then((autolist) => 
                {
                  let tempList = [];

                  tempList = autolist || [];

                  for (const item of tempList) 
                  {
                    element.fieldAutoList.push(item);
                  }
                });
            }
            else if (element.getIsEnum() && !element.getIsMultiple()) 
            {
              const value = (element.getValue().value || "").toString();

              element.setValue({
                value,
                html: element.getValue().html,
              });

              this.initCheckBoxes(element, element.getValue());
            }
          }
          else if (element.getIsEnum() && !element.getIsMultiple()) 
          {
            element.setValue({
              value: element.value.value,
              html: element.value.html,
            });
          }

          if (element.getAuto() != null) 
          {
            if (
              element.getKey() == "space" ||
                element.getKey() == "template"
            ) 
            {
              // dynamic enum values can be dependent on another fields values
              // the names of that other fields are provided in the query
              const otherFieldsKey = metadata[key]["x-dynamic-values"]["query"];

              if (otherFieldsKey) 
              {
                this.gedService
                  .getAutocompleteList(
                    element.getValue(),
                    element.getUrlAuto(),
                    oid,
                    otherFieldsKey
                  )
                  .then((autolist) => 
                  {
                    let tempList = [];

                    if (autolist?.length != 0) 
                    {
                      //  element.setValue(autolist[0]);
                      if (autolist) 
                      {
                        element.value = { ...autolist[0] };
                        element.setPreviewLink(autolist[0]);
                      }
                    }
                    tempList = autolist || [];

                    for (const item of tempList) 
                    {
                      if (element.getKey() == "space") 
                      {
                        this.listeSpaces = element.fieldAutoList;
                        //let space = this.getSpacebyType(item.html);
                      }
                      element.fieldAutoList.push(item);
                    }

                    if (element.getKey() == "template") 
                    {
                      this.listeTemplates = element.fieldAutoList;
                      this.templateListe = element;
                      this.previewLink = element.getPreviewLink();
                    }
                  });
              }
            }
          }
          else 
          {
            this.gedService
              .getAutocompleteList(element.getValue(), element.getAuto())
              .then((autolist) => 
              {
                let tempList = [];

                tempList = autolist || [];
                for (const item of tempList) 
                {
                  element.fieldAutoList.push(item);
                }
              });
          }

          //end.

          if (this.data?.eventItem) 
          {
            element = this.fillEventElement(element);
          }

          if (this.isUploaded && element.getKey() == "name") 
          {
            element.value = this.docUploaded?.name;
            this.inputData.data= {...this.inputData.data, 'name':this.docUploaded?.name};

          }
          if (this.documentSelected && element.getKey() == "name") 
          {
            element.value = this.documentDrive?.name;

          }
          if (this.templateSelected && element.getKey() == "name") 
          {
            element.value = "";
          }


          this.formFieldValues[key] = element.value;
          this.formFields.push(element);

          this.temporaryList.push(element);
          //}
        }
      });

    return this.formFields;
  }

  isSelectedValue(option, field) 
  {
    if (option.key == field.value || option.html == field.html) return true;

    return false;
  }

  fillEventElement(element) 
  {
    const eventItem = this.data.eventItem;

    /*  if(element.key=='description') element.value = eventItem?.getDesc()
     if(element.key=='name') element.value = eventItem.getTitle() */
    return element;
  }

  onSelectedField(event: MatAutocompleteSelectedEvent, element) 
  {
    for (const option of element.fieldAutoList) 
    {
      if (option.html == event.option.value) 
      {
        const temp = {
          name: element.key,
          value: option,
        };

        this.jsonData[element.key] = option;
      }
    }
  }

  onSelectedValue(field, value) 
  {
    if (field.key == "template") 
    {
      for (const item of field.fieldAutoList) 
      {
        if (value.html == item.html) 
        {
          //field.value=item;
          // field.value=item;
          const id = item?.infos.id || null;

          this.previewLink = `https://drive.google.com/thumbnail?sz=w300&id=${id}`;

          return this.previewLink;
        }
      }

      return this.previewLink;
    }

    return this.previewLink;
  }

  async getIdSpace(field) 
  {
    const spaces = this.listeSpaces;

    for (let i = 0; i < spaces.length; i++) 
    {
      const space = spaces[i];

      if (space["html"] == field) 
      {
        return space;
      }
    }

    return null;
  }

  async getIdTemplate(field) 
  {
    const themes = this.listeTemplates;

    for (let i = 0; i < themes.length; i++) 
    {
      const t = themes[i];

      if (t["html"] == field) return t;
    }

    return null;
  }

  encodeMulKeys(keys) 
  {
    return (keys.length && "|" + keys.join("|") + "|") || "";
  }

  checkedOptions = {};
  onChangeEnumsChecked(field, name, event) 
  {
    if (!this.checkedOptions[name.key]) this.checkedOptions[name.key] = [];

    const tags = [];

    if (event?.source?.selected) 
    {
      this.checkedOptions[name.key].push(field);
      this.formFieldValues[name.key] = event.source.value;
    }
    else 
    {
      const index = this.checkedOptions[name.key].indexOf(
        (x) => x.value == field
      );

      this.checkedOptions[name.key].splice(index, 1);
      this.formFieldValues[name.key] = event.source.value;
    }
  }

  initCheckBoxes(field, value) 
  {
    const fname = field.key;

    this.checkedOptions[fname] = [];
    this.checkedOptions[fname].push(value);
  }

  isCreated: boolean = false;
  // OBSOLETE ???
  /*
  async onCreateDoc(values) {
    // let spaceId = await this.getIdSpace(values["space"]);
    let templeteId = await this.getIdTemplate(values["template"]);
    this.done = true;
    this.isLoading = true;
    let data = {};

    for (let item of this.formFields) {
      let key = item.key;

      if (item.auto == null && !item.isEnum && item.control != "checkbox") {
        data[key] = values[key];
      } else if (item.isEnum && !item.isMultiple) {
        data[key] = {
          value: item.value.html,
          html: item.enumValues[item.value.html],
        };
      } else if (item.isEnum && item.isMultiple && item.control == "checkbox") {
        let keys = [];
        let datavalues = [];

        if (this.checkedOptions[key]) {
          keys = this.checkedOptions[key].map((obj) => {
            return obj["key"];
          });

          datavalues = this.checkedOptions[key].map((obj) => {
            return obj["value"];
          });
        }

        data[key] = {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(","),
          values: this.checkedOptions[key],
        };
      } else if (
        !item.isEnum &&
        item.isMultiple &&
        item.control == "checkbox"
      ) {
        let keys = [];
        let datavalues = [];

        if (this.checkedOptions[key]) {
          keys = this.checkedOptions[key].map((obj) => {
            return obj["value"];
          });

          datavalues = this.checkedOptions[key].map((obj) => {
            return obj["html"];
          });
        }

        data[key] = {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(","),
          values: this.checkedOptions[key],
        };
      } else if (item.key == "template") {
        data[key] = {
          value: templeteId["value"],
          html: item.value.html,
        };
      } else if (item.key == "space") {
        data[key] = {
          html: this.space._data.name,
          sid: this.space._data.sid,
          value: this.space._data.oid,
          infos: {},
        };
      } else {
        let ResData = await this.gedService.getAutocompleteList(
          values[key],
          item["auto"]["url"]
        );

        data[key] = ResData?.find((item) => item?.html == values[key]);
        data[key] = ResData?.find((item) => item?.html == values[key]);

        if (ResData?.length == 0) {
          this.gedService
            .getAutocompleteList("", item["auto"]["url"])
            .then((response) => {
              data[key] = response.find((item) => item?.html == values[key]);
            });
        }
      }
    }

    for (let item of this.formFields) {
      if (item.key == "template") {
        this.templateId = templeteId["uid"] || null;
      }
    }

    this.jsonData = data;
    if (this.templateId != null) {
      this.gedService
        .createNewDocInGED(
          this.docType.oid,
          this.jsonData,
          this.templateId,
          "root"
        )
        .then((resp) => {
          this.isCreated = true;
          this.isLoading = false;
          if (resp?.id) {
            let url = this.documentService.getRoutebyMimeType(resp?.mimeType);
            url = "/s/space/" + this.spaceUid + "/file/" + resp.id;

            this.router.navigate([url]);
          }
        });
    }
  }
  */

  onTemplateSelected(template) 
  {
    if (template !== null) 
    {
      if (!this.templateSelected) 
      {
        this.templateSelected = true;
        this.selectedTemplate = template;
        this.documentSelected = null;
      }
      else 
      {
      }
    }
    else 
    {
      this.templateSelected = false;
    }
    this.metadataByType(this.docType.oid, !this.templateSelected);
  }

  async getFormData(values : Record<string,any>)
  {
    const data = {};

    for (const item of this.formFields)
    {
      const key = item.key;

      // simple value (text, date)
      if (item.auto == null && !item.isEnum && item.control != "checkbox") 
      {
        data[key] = values[key];
      }

      // checkbox, simple
      else if (
        item.isEnum &&
        !item.isMultiple &&
        item.control != "checkbox"
      ) 
      {
        data[key] = {
          value: item.value.html,
          html: item.enumValues[item.value.html],
        };
      }

      // multiple checkbox
      else if (
        item.isEnum &&
        !item.isMultiple &&
        item.control == "checkbox"
      )
      {
        data[key] = item.value;
      }

      // multiple checkbox : same condition? ==> BUG?
      else if (
        item.isEnum &&
        item.isMultiple &&
        item.control == "checkbox")
      {
        let keys = [];
        let datavalues = [];

        if (this.checkedOptions[key]) 
        {
          keys = this.checkedOptions[key].map((obj) => 
          {
            return obj["key"];
          });

          datavalues = this.checkedOptions[key].map((obj) => 
          {
            return obj["value"];
          });
        }

        data[key] = {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(","),
          values: this.checkedOptions[key],
        };
      }

      // multiple checkbox but not an enum?? ==> DESCRIBE THE CASE
      else if (
        !item.isEnum &&
        item.isMultiple &&
        item.control == "checkbox"
      ) 
      {
        let keys = [];
        let datavalues = [];

        if (this.checkedOptions[key])
        {
          keys = this.checkedOptions[key].map((obj) => 
          {
            return obj["value"];
          });

          datavalues = this.checkedOptions[key].map((obj) => 
          {
            return obj["html"];
          });
        }

        data[key] = {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(","),
          values: this.checkedOptions[key],
        };
      }

      // other check box? ==> is it used?
      else if (item.control == "checkbox") 
      {
      }

      // template
      else if (item.key == "template" && this.selectedTemplate)
      {
        data[key] = {
          value: this.selectedTemplate.value,
          html: this.selectedTemplate.html,
        };
      }

      // SPACE field
      else if (item.key == "space") 
      {
        data[key] = {
          html: this.space._data.name,
          sid: this.space._data.sid,
          value: this.space._data.oid,
          infos: {},
        };
      }

      // other enums
      else 
      {
        const ResData = await this.gedService.getAutocompleteList(
          values[key],
          item["auto"]["url"]
        );

        data[key] = ResData?.find((item) => item?.value == values[key]);

        if (ResData?.length == 0) 
        {
          this.gedService
            .getAutocompleteList("", item["auto"]["url"])
            .then((response) => 
            {
              data[key] = response.find((item) => item?.html == values[key]);
            });
        }
      }
    }

    return data;
  }

  async onCreateDocWizard(values)
  {
    this.formValid = values.valid;
    //let values = this.formFieldValues

    // let spaceId = await this.getIdSpace(values["space"]);
    //let templeteId = await this.getIdTemplate(this.selectedTemplate);
    this.done = true;
    this.isLoading = true;

    // get data from form
    //let data1 = await this.getFormData(values);
    
    values['template'] = {
      value: this.selectedTemplate?.value,
      html: this.selectedTemplate?.html,
    };
      

    // SPACE field
      
    values['space'] = {
      html: this.space._data.name,
      sid: this.space._data.sid,
      value: this.space._data.oid,
      infos: {},
    };

    console.log(values);
     
    const data = values;
    //return


    // add template id if selected
    if (this.selectedTemplate)
    {
      for (const item of this.formFields)
      {
        if (item.key == "template") 
        {
          this.templateId = this.selectedTemplate.uid || null;

        }
      }
    }


    this.jsonData = data;
    const dataWithDocType =
    {
      curTypeDoc: this.docType,
      data: data,
    };

    // ADD document from drive
    if (this.documentDrive != null) 
    {
      this.addDocumentFromDrive(this.documentDrive, dataWithDocType);
    }

    // ADD new document from template
    else if (this.templateId != null) 
    {
      this.addNewDocumentFromTemplate(this.docType.oid, this.jsonData, this.templateId);
    }

    // ADD uploaded document
    else if (this.docUploaded != null) 
    {
      this.addUploadedFile(this.docUploaded, dataWithDocType);
    }
    this.auth.profileSubject
      .pipe(
        filter(value => !!value),
        tap(user => 
        {
          this.currentName = user.name
        }))
      .subscribe()

    this.pDocsEventsService.createDocumentEvent(
      data.name,
      this.currentName,
      this.space._data.name
    );

  }

  // Function to handle the case when documentDrive is not null
  addDocumentFromDrive(documentDrive: any, temp: any) 
  {
    this.gedService.addDocToGed(documentDrive, temp).then((res) => 
    {
      if (res != undefined) 
      {
        if (res.content?.uid) 
        {
          this.handleContentUid(res.content.uid);
        }
        else 
        {
          this.handleNoContentUid();
        }
      }
    });
  }

  // Function to handle the case when content.uid exists
  handleContentUid(uid: string) 
  {
    const url = "/workspace/main/file/" + uid;

    this.router.navigate([url]);
  }

  // Function to handle the case when content.uid does not exist
  handleNoContentUid() 
  {
    const curUrl = this.router.url;

    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => 
    {
      this.router.navigate([curUrl]);
    });
  }

  /** add document from template */
  addNewDocumentFromTemplate(typeDocOid: string, data: any, templateId: any) 
  {
    this.gedService
      .createNewDocInGED(typeDocOid, data, templateId, "root")
      .then((resp) => 
      {
        this.isCreated = true;
        this.isLoading = false;
        if (resp?.id) 
        {
          this.navigateToFileById(resp.mimeType, resp.id);
        }
      });
  }

  /** submit/add uploaded file from PC */
  async addUploadedFile(
    uploadedFile: File,
    values: Record<string, any>) 
  {
    //let data = await this.getFormData(this.formFieldValues);
    const data = values;

    if (data) 
    {
      const dataWithDocType =
      {
        curTypeDoc: this.docType,
        data
      };

      this.gedService
        .uploadFileWithForm(uploadedFile, dataWithDocType)
        .then((resp) => 
        {
          this.isCreated = true;
          this.isLoading = false;

          if (resp?.id) 
          {
            this.navigateToFileById(resp.mimeType, resp.id);
          }

        })
        .catch(error => 
        {
          console.error(error);
        })
        .finally(() => 
        {
          this.isCreated = false;
          this.isLoading = false;
        });
    }
  }

  /** Function to handle the response id when templateId is not null */
  navigateToFileById(mimeType: string, id: string) 
  {
    let url = this.documentService.getRoutebyMimeType(mimeType);

    url = "/s/space/" + this.spaceUid + "/file/" + id;
    this.router.navigate([url]);
  }

  /** reset form fields */
  cancelForm(): void 
  {
    for (const item of this.formFields) 
    {
      item.value = "";
    }
    this.showForm = false;
  }

  /** load document types for use in autocomplete */
  async getDocumentTypes() 
  {
    this.autoComplete  = await this.gedService.getDocTypes("", null, this.spaceUid )


  }

  allEmptyData = false;

  // sets allEmptyData
  checkIfEmptyData(results): void 
  {
    this.allEmptyData =
      results.find((item) => item.results.length != 0) == undefined
        ? true
        : false;
  }

  /** drive document selected : store its value */
  onSelectDocument(gfile: FileItem) 
  {
    if (gfile !== null) 
    {
      this.docUploaded = null;
      this.templateId = null;

      // store selected file
      this.documentDrive = gfile;

      // prepare form
      this.documentSelected = true;

      this.currentStep = 3;
      //this.docType = item;
      this.docFormulaire.resetForm();


      // load metadata form
      this.metadataByType(this.docType.oid, false);
    }
    else 
    {
      this.documentSelected = false;
    }
  }

  /** store uploaded file and prepare data form */
  onUploadDocument(uploadFileField: File) 
  {
    // store uploaded file info
    this.isUploaded = true;
    this.docUploaded = uploadFileField;

    // reset other modes
    this.documentDrive = null;
    this.templateId = null;

    // prepare form fields
    this.docFormulaire.resetForm();
    this.metadataByType(this.docType.oid, true);
  }

  // google drive searches and navigation (updated by <app-doc-drive>)

  lastPath(route) 
  {
    this.CurrentRoute = route;
  }
  lastSearch(query) 
  {
    this.CurrentSearch = query;
  }


  /**
   *
   */
  addDocument()
  {
    if (true) 
    {
      this.childComponent.submit();
    }
  }

  handleFormValidityChanged(valid: boolean) 
  {
    this.formValid = valid;
  }
}
