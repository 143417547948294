<app-sidenav
[breadcrumb]="bc"
[title]="'groupsString' | translate"
(emitdataGroupes)="getData($event)"
>
  <div class="h-100 d-flex flex-column content-space">
    <div class="">
      <form class="row mt-5" *ngIf="displaySpaces.length > 50">
        <div class="col pe-0 me-0">
          <aura-input-text
            id="search"
            className="shadow-sm border-0"
            size="large"
          ></aura-input-text>
        </div>
        <div class="col-4 col-md-2 me-0">
          <button
            auraButton
            size="large"
            type="primary"
            [full]="true"
            label="Search"
          ></button>
        </div>
      </form>

      <div class="row py-3">
        <div class="col icons d-flex justify-content-end gap-2 view-icons">
          <button
            auraButton
            icon="gg-math-plus"
            size="large"
            class="new-btn"
            type="success"
            [label]="'New space' | translate"
            (click)="spaceNewModal = true"
          ></button>

          <span class="d-none d-md-block"> &nbsp;&nbsp;</span>
          <!-- <button auraButton size="large" type="light" icon="gg-user-add" [full]="true" label="{{'share' | translate}}"   ></button> -->
          <span class="d-none d-md-block"> &nbsp;&nbsp;</span>
          <img
            [src]="
              '/assets/images/view-list.svg#' +
              (activeTab == 'table' ? 'active' : '')
            "
            (click)="toggleView('table')"
            class="d-none d-md-block"
          />
          <span class="d-none d-md-block"> &nbsp;&nbsp;</span>
          <img
            [src]="
              '/assets/images/view-cols.svg#' +
              (activeTab == 'grid' ? 'active' : '')
            "
            (click)="toggleView('grid')"
            class="d-none d-md-block"
          />
        </div>
      </div>
      <app-space-new
        [groupeName]="typeSpace"
        [isOpen]="spaceNewModal"
        (close)="spaceNewModal = false"
      ></app-space-new>

      <div class="grid">
        <aura-list
          verticalAlign="center"
          [columns]="activeTab == 'grid' ? 4 : 1"
          [hasMenu]="true"
          [data]="displaySpaces"
          [noShadow]="false"
        ></aura-list>
      </div>
    </div>

    <!-- <div class="row mt-auto mb-0">
            <div class="col text-right ">
                <button (click)="open()" class="btn btn-xl btn-primary mt-4 d-sm-inline-block d-block m-auto">+ Add New
                    Department</button>
                  <app-new-department [isOpen]="isOpen" (close)="close()"></app-new-department>
            </div>
        </div> -->
  </div>
</app-sidenav>
