import { EListAdvanced } from "@pdoc/aura";
import { environment } from "src/environments/environment";
import { ContactService } from "src/modules/contact/services/contact.service";
import { UserService } from "../../services/user/user.service";
import { RecentContact } from "../recent-contact";

const defaultPhotoUrl = "https://lh3.googleusercontent.com/a/default-user=s64";

type isParticipating = 'needsAction' | 'accepted' | 'declined' | 'tentative';

class TUserEventData
{
  email: string;
  name: string;
  id: string;
  status: isParticipating;
}

export class UserEvent
{
  id: string;
  fullname: string;
  image: string;
  isParticipating: EListAdvanced;

  constructor(event)
  {
    if(event)
    {
      this._setId(event);
      this._setFullName(event);

      this._setIsParticipating(event);
      this._setImage(event);
    }
  }

  getIsParticipating() : EListAdvanced
  {
    return this.isParticipating;
  }

  getId() : string 
  {
    return this.id;
  }

  getFullName() : string 
  {
    return this.fullname;
  }

  getImage() : string
  {
    return this.image;
  }

  // init functions

  _setId(event : TUserEventData) 
  {
    this.id = event?.id;
  }

  _setFullName(event : TUserEventData) 
  {
    this.fullname = event.name;
  }

  async _setImage(event : TUserEventData)
  {
    let  url:string;

    this.image = defaultPhotoUrl;

    const users = await  UserService.instance.getCachedUsers()

    if(users.length !=0)
    {
      const profil = (users).find((obj : RecentContact) => obj.getEmail() == event.email)

      if(profil)
      {
        this.image = profil.getPicture();

        return;
      }
    }

    ContactService.instance?.getProfileByMail(event.email)
      .then(photoLink =>
      {
        if(photoLink)
        {
          url =  photoLink?.['photos']?.['0']?.['url'];
          this.image = url ;
        }
      });

  }

  _setIsParticipating(event)
  {
    if (event?.status == 'needsAction') 
    {
      this.isParticipating = undefined
    }
    if (event?.status == 'accepted') 
    {
      this.isParticipating = EListAdvanced.YES
    }
    if (event?.status == 'declined') 
    {
      this.isParticipating= EListAdvanced.NO
    }
    if (event?.status == 'tentative') 
    {
      this.isParticipating =EListAdvanced.MAYBE
    }
  }
}
