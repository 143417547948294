 
   <!--<div *ngIf="showContent">
    <mat-list>
      <div *ngFor="let chipData of chipContent; let i = index">
        <div>
          <mat-divider *ngIf="i!=0"></mat-divider>
          <mat-list-item class="listitem" role="listitem" *ngIf="!!chipData.value && chipData.type!='date' && chipData.type!='integer' && chipData.auto==null" (click)="showContent=!showContent">
               <span class="label">{{chipData.label}}</span>
               <span class="dataValue">{{chipData.value}} </span>
              </mat-list-item>

              <mat-list-item class="listitem" role="listitem" *ngIf="chipData.auto!=null   " (click)="showContent=!showContent">
               <span class="label">{{chipData.label}}</span>
               <span class="dataValue"> {{chipData.value}} </span>
              </mat-list-item>

              <mat-list-item class="listitem" role="listitem" *ngIf="!!chipData.value && chipData.type=='integer' && chipData.auto==null" (click)="showContent=!showContent">
              <span class="label">{{chipData.label}}</span>
              <span class="dataValue">{{chipData.value}} </span>
             </mat-list-item>
              <mat-list-item class="listitem" role="listitem" *ngIf="!!chipData.value && chipData.type=='date'" (click)="showContent=!showContent">
               <span class="label">{{chipData.label}}</span>
               <span class="dataValue">{{chipData.value | date: 'dd/MM/yyyy'}} </span>
              </mat-list-item>
          </div>
      </div>

    </mat-list>
  </div>-->
    <div  class="formDesc">
     <app-form-manager
            [inputData]="currentSpace"
            [displayButtons]="true"
            [type]="'form'"
            (onFormCancelEvent)="annuler()"
            (onFormSubmitEvent)="onUpdatespaceDesc($event)"
            [showRecap]="true"></app-form-manager></div>
    <!--<form #f="ngForm">
      <div  class="formDesc">
        <div *ngFor="let chipData of chipContent; let i=index"  > 

          <mat-form-field  appearance="fill"   style="display: block; text-justify: center;" >
  
            <mat-label>{{chipData.label}}</mat-label>
  
            <div *ngIf="chipData.auto!=null ">
              <input matInput    [(ngModel)]="chipData.value"  name="{{chipData.meta}}"
  
                     [matAutocomplete]="autofield" (ngModelChange)="doFilterField(chipData)" #optionfield="ngModel">
  
              <mat-autocomplete #autofield="matAutocomplete">
                <mat-option *ngFor="let option of fieldAutoList" [value]="option.html">
                  {{option.html}}
                </mat-option>
              </mat-autocomplete>
  
            </div>
  
  
            <div *ngIf="chipData.meta!='icon' && chipData.meta!=='color' && chipData.meta!='driverId' && chipData.auto==null" >
              <input matInput type="{{chipData.type}}"  [(ngModel)]="formValues[i]"
                     name="{{chipData.meta}}">
            </div>
  
            <div  *ngIf="chipData.meta==='color'">
             <div  class="color-select"  [ngStyle]="{ 'background-color': formValues[i] } "> </div>
             <mat-select   [(ngModel)]="formValues[i]" name="{{chipData.meta}}"  class="color-selected"  > 
  
               <mat-option *ngFor="let color of colors_ged" [value]="color"  >
                 
                 <div class="color-div"  [ngStyle]="{'background-color': color}" ></div>
               
               </mat-option>
             </mat-select>
            </div>
            <div  *ngIf="chipData.meta==='icon'" class="iconContent">
                 <mat-icon>{{formValues[i]}}</mat-icon>  
                 <mat-select   [(ngModel)]="formValues[i]" name="{{chipData.meta}}" (click)="isShowIcon($event)"   >
  
                  <mat-option *ngFor="let icon of material_icons" [value]="icon"  >
         
                   <mat-icon aria-hidden="true" aria-label="icon">{{icon}}  </mat-icon>{{icon || icon}}
                   
                   </mat-option>
                </mat-select>
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12 actions">
        <button mat-raised-button class="cancelBtn" (click)="showContent=!showContent">{{'cancelBtn' | translate | uppercase}}</button>
        <button mat-raised-button class="submitBtn" type="submit"
                (click)="onUpdatespaceDesc(f.value)"  [disabled]="!f.dirty">{{'saveBtn' | translate | uppercase}}</button>
      </div>
      
     

    </form>-->

  
 

