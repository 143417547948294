/**
 * LOG Service : to be used for console logs that needs to stay in production.
 * Allow to generate logs that :
 * 1) are centrally managed (and can be redirecgted to cloud Logger)
 * 2) can be activated when needed only, with env LOGS (LOGS=login,doc)
 * 3) replace console by an instance of this class (let trace = new DebugLog("login"))
 *
 * usage :
 * import { DebugLog } from "src/modules/app-common/services/log/log.service"
 * let trace = DebugLog.build("login");
 *
 * trace.log("Hello World","ready to go!");
 *
 * NB. this new version runs faster because when an id is not active, called functions
 * are empty and no testing is necessary when log is called.
 */
import {environment} from 'src/environments/environment';

/** base class used when log id not active => empty functions */
export class DebugLog
{
  id:string;

  constructor(id : string="log")
  {
    this.id = id.toUpperCase()+":";
  }
  log(...args) 
  {}
  warn(...args) 
  {}
  error(...args) 
  {}

  /**
   *
   * @param id log id
   * @param colors colors in the form of "bgcolor,fgcolor" (ex. "green,white")
   * @param force force messages to be displayed in the console, even if the id is not selected in environement
   * @returns debug object to be used for debugging
   */
  static build(id : string="log",colors="#eeeeee,black",force=false) : DebugLog
  {
    if(force ||
        environment["LOGS"] && environment["LOGS"][id] || environment["LOGS"]['*'])
    {
      return new DebugLogTrace(id,colors);
    }
    else
    {
      return new DebugLog(id);
    }
  }
}

/** class used when log id active : outputs to console */
export class DebugLogTrace extends DebugLog
{
  colors : string = "";
  prefix: string;

  constructor(id : string,colors)
  {
    super(id);
    if(colors)
    {
      const aCols = colors.split(",");

      this.colors =
        "background-color: "+aCols[0]+";"+
        "color: "+(aCols[1] || "white")+";";
      this.prefix = "%c"+id;
    }
  }

  log(...args)
  {
    if(this.colors)
    {
      console.log(this.prefix,this.colors,...args);
    }
    else
    {
      console.log(this.id,...args);
    }
  }

  warn(...args)
  {
    console.warn(this.id,...args);
  }
  error(...args)
  {
    console.error(this.id,...args);
  }
}
