import { environment } from "src/environments/environment";
import { TableSpaceComponent } from "../../components";
import { SpaceTypeComponent } from "../../components/space-type/space-type.component";
const open = environment.stringsFile.open;
const openDrive = environment.stringsFile.open_in_drive;


export class Spacetype 
{
  name: string;
  app_url: string;
  color: string;
  description: string;
  driveId: string;
  icon: string;
  owner: string;
  sid: string;
  uid: string;
  initialLetter : string;
  displayProperties;

  constructor(space) 
  {
    this.setName(space);
    this.setUrl(space);
    this.setColor(space);
    this.setDescription(space);
    this.setIcon(space);
    this.setOwner(space);
    this.setSid(space);
    this.setUid(space);
    this.setDrive(space);
    this.getDisplayProperties();
  }

  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties =  
    [
      {
        uid: this.getUid(),
        title: this.getName(),
        // text: this.getDescription() ,
        image: "/assets/images/icon-folder-small.svg",
        redirectTo: '/s/space/'+self.getUid(),

        menuItems : 
        [
          {
            label: open,
            callback: function () 
            {
              SpaceTypeComponent.instance.rowClick(self.getUid())
            }
          }/* ,

          {
            label: 'Ouvrir La destination',
            callback: function () 
            {
             }
          }, */
          ,
          {
            label: openDrive,
            callback: function () 
            {
              SpaceTypeComponent.instance.openInDrive(self.getUid())
            },
          }

        ]
      }
    ];

    return this.displayProperties;
  }


  getName() 
  {
    return this.name;
  }

  getUrl() 
  {
    return "/s/space/"+this.getUid();
  }

  hasAppUrl() 
  {
    return !!this.app_url;
  }

  getColor() 
  {
    return this.color;
  }

  getIcon() 
  {
    return this.icon;
  }

  getDescription() 
  {
    return this.description;
  }

  getOwner() 
  {
    return this.owner;
  }

  getDrive() 
  {
    return this.driveId;
  }

  getSid() 
  {
    return this.sid;
  }

  getUid() 
  {
    return this.uid;
  }

  getInitialLetter()
  {
    return this.initialLetter
  }

  setName(space) 
  {
    this.name = space?.name;
  }

  setUrl(space) 
  {
    this.app_url = space?.app_url;
  }

  setDescription(space) 
  {
    this.description = space?.description;
  }

  setIcon(space) 
  {
    this.icon = space?.iconLink;
  }

  setColor(space) 
  {
    this.color = space?.color;
  }

  setOwner(space) 
  {
    this.owner = space?.owner;
  }

  setDrive(space) 
  {
    this.driveId = space?.driveId;
  }

  setUid(space) 
  {
    this.uid = space?.uid;
  }

  setSid(space) 
  {
    this.sid = space?.sid;
  }

  setInitialLetter(space) 
  {
    this.initialLetter = space?.name.charAt(0);
  }
}
