
<!--  <section class="px-3" *ngFor="let item of linkedDocs2">
  <h4 class="pt-3 h4">{{item.category}}</h4>
  <app-list [data]="item.docs" [hasMenu]="true" verticalAlign="center"></app-list>
 </section>    -->
<ng-container *ngIf="documentLinks.length != 0">
  <section *ngFor="let link of documentLinks; let i = index"
    class="px-3">
    <h4 class="pt-3 h4 title-link">
      {{ link.getTitle() }}

      <span *ngIf="link.getAdd()">
        <a *ngIf="canEdit"
          data-bs-target="#exModal"
          data-bs-toggle="modal"
          class="a text-decoration-underline linkModal"
          (click)="moreDetailsModal = true">
          <mat-icon class="material-icons-round">add_circle</mat-icon>
        </a>

        <app-doc-type-add [dataLink]="link.getAdd()"
          [isOpen]="moreDetailsModal"
          [parentId]="parentUid"
          (close)="moreDetailsModal = false"></app-doc-type-add>
      </span>
    </h4>

    <aura-list verticalAlign="center"
      [data]="link?.listeDocs"
      [hasMenu]="true"
      [noShadow]="false"></aura-list>
  </section>
</ng-container>

<div *ngIf="documentLinks.length == 0"
  class="no_document">
  <p style="font-weight: bold;">{{ 'No_linkedDoc' | translate }}</p>
</div>
