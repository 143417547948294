


  <div *ngIf="displayMeetings.length != 0" >
    <app-event-answer [isOpen]="moreDetailsModal" (close)="moreDetailsModal = false" [eventItem]="eventItem"></app-event-answer>

    <!-- <app-event-answer [isOpen]="moreDetailsModal" (close)="moreDetailsModal = false"></app-event-answer> -->
    
    <aura-list-advanced verticalAlign="top" [hasRightIcons]="true" [data]="displayMeetings"
      answers question></aura-list-advanced>
   </div>

  <div *ngIf="displayMeetings.length == 0" class="no_event">
    <p style="font-weight: bold;">{{'event_planned' | translate }}</p>
   </div>
