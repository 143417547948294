import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Page } from "@interfaces/page";
import { environment } from "src/environments/environment";
import { SpaceService } from "src/modules/spaces/services";

import { GDocService, GedService } from "../../services";
import { AddDocComponent } from "../add-doc/add-doc.component";
import { AddDoctypeComponent } from "../add-doctype/add-doctype.component";
import { AddFolderComponent } from "../add-folder/add-folder.component";
import { AuthService } from 'src/modules/user/services';
import { Subject, forkJoin, interval, of } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TaskService } from 'src/modules/tasks/services/task.service';
import { TranslateService } from '@ngx-translate/core';

const themePath = environment.themePath;


@Component({
  selector: "app-sidenav",
  templateUrl: `./${themePath}/sidenav.component.html`,
  styleUrls: [`./${themePath}/sidenav.component.scss`],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SidenavComponent implements OnInit, OnDestroy 
{

  ESPACE_ACTIVATED: boolean = environment.ESPACE_ACTIVATED;

  @Input("inGed") inGed;
  @Input() title: string;
  @Input() breadcrumb: any[] = null;
  @Output() emitdataGroupes: EventEmitter<any> = new EventEmitter();
  @Output() emitdataTypes: EventEmitter<any> = new EventEmitter();
  @Input() isOn: boolean = false;
  @Output() closeSideMenu = new EventEmitter();
  @Input() inputCleared: boolean = false ;
  
  appTitle: string;
  driveId: string;
  parentId: string;
  spaceId = "";
  spaceList = [];
  spaceItem;
  document;
  folder;
  url;
  showParentLink = false;
  sideNavState: boolean = true;
  linkText: boolean = true;
  contentMargin = 250;
  iconPadding = 15;
  displayed: string = "none";
  isExpanded = true;
  isShowing = false;
  isLoading = true;
  driveHover = false;
  spacesByTypes = {};
  SpacesIndividual = [];
  SpacesTemplate = [];
  TypeFolder = [];
  modeleSpaces = [];
  allSpaces = [];
  Spaces2: any = []
  spacesData;
  taskurl = "/t/all";
  taskurl2 = "/t/all/view/grid";
  ws_url = "/workspace/main";
  drivesUrl = "/s/space/drives";
  driveurl = "/s/space/root";
  groupsUrl = "/s/groups/all";
  driveurl2;
  //search  = "search";
  //driverId : any;
  spaceurl = [];
  href;
  googleDocs = false;
  filterQuery;
  isUrlGroups: boolean = false;
  likeTasks: boolean = false;
  addDocument: boolean = environment.addDocument;
  isUrlSearch: boolean = false;

  spaces = []
  // view='default';
  SpaceTrue = false;


  isDocModalOpen = false;

  moreDetailsModal: boolean = false;

  __spaces = [];
  subGroups = []
  support_button: boolean;

  icons: Icon[] = this.ESPACE_ACTIVATED ? [
    {
      label: 'MENU.HOME', icon: 'home', link: '/#/workspace/main'
    },
    { label: 'Spaces', icon: 'spaces', link: '/#/s/groups/all' },
    { label: 'MENU.DOCS', icon: 'spaces', link: '/#/explorer/drive/shared' },
    { label: 'MENU.TASKS', icon: 'tasks', link: '/#/t/all/view/grid' }
  ] : [
    {
      label: 'MENU.HOME', icon: 'home', link: '/#/workspace/main'
    },
    { label: 'MENU.DOCS', icon: 'spaces', link: '/#/explorer/drive/shared' },
    { label: 'MENU.TASKS', icon: 'tasks', link: '/#/t/all/view/table' }
  ]

  avatarActions = [
    { label: environment.stringsFile.USER.LOGOUT, icon: 'gg-log-off', callback: this.logout.bind(this) }
  ];
  profilePic = '';
  logout() 
  {

    // console.log('logout clicked')
    this.auth.logout()
  }
  private onDestroy$: Subject<void> = new Subject<void>();


  onActionClicked(callback: Function) 
  {
    // Handle the action callback here
    callback();
  }
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private spaceService: SpaceService,
    private route: ActivatedRoute,
    private gdocService: GDocService,
    private gedService: GedService,
    private tasksService: TaskService,
    private translate: TranslateService
  ) 
  {
    this.support_button = environment.SUPPORT_LINK;

  }
  ngOnDestroy(): void 
  {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }




  ngOnInit() 
  {
    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter(event => event instanceof NavigationEnd),
      startWith(0),
      tap(event => 
      {
        this.icons = this.icons.map(icon => 
        {
          icon.active = icon.link.includes(this.router.url)
          if (icon.link.includes(this.router.url)) 
          {
            icon.class = 'active'
          }
          else 
          {
            icon.class = ''
          }
          if (icon.label == 'Spaces')
          {
            if (this.router.url.includes('/s/space/'))
            {
              icon.class = 'active'
            }
          }
          if (icon.label == 'Docs')
          {
            if (this.router.url.includes('explorer'))
            {
              icon.class = 'active'
            }
          }

          return icon
        })
      })
    )
      .subscribe();

    this.auth.profileSubject
      .pipe(takeUntil(this.onDestroy$),
        filter(value => !!value),
        tap(user => 
        {

          this.profilePic = user.picture
        }))
      .subscribe();

    interval(600000)
      .pipe(takeUntil(this.onDestroy$),
        startWith(0),
        switchMap((value) => 
        {
          return forkJoin([this.tasksService.getTasksApprobations(), this.tasksService.getFlaggedTasksObs()])
        }),
        map((response) => 
        {
          console.log(response)
          const [approbations, flaggedTasks] = response

          return (approbations?.groupTasks?.length || 0) + (approbations?.myTasks?.length || 0) + (flaggedTasks?.length || 0)
        }),
        tap((tasksCount) => 
        {
          this.icons = this.icons.map(icon => 
          {
            if (icon.label === 'MENU.TASKS') 
            {
              icon.badge = tasksCount
            }

            return icon
          })
        })
      ).subscribe()

    if (this.router.url.includes('/groups/')) 
    {
      this.isUrlGroups = true
    }
    if (this.router.url.includes("/t/all/view")) 
    {
      this.likeTasks = true
    }

    if (this.router.url.includes('/search')) 
    {
      this.isUrlSearch = true
    }

    this.filterQuery = this.route.snapshot.params["spaceId"];
    if (this.inGed === false) 
    {
      this.googleDocs = true;
    }

    this.route.params.forEach((param) => 
    {
      this.href = param["spaceId"];
      this.parentId = param["spaceId"];
      if (param["spaceId"] == "root") 
      {
        this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe((params) => 
        {
          this.router.navigate([], {
            queryParams: { view: null },
            queryParamsHandling: "merge",
          });
        });
      }
    });

    this.getSpaces();
    this.isDrive();
    this.getSpaceType();
  }

  isDrive(): boolean 
  {
    let drive;

    if (this.inGed === false) 
    {
      drive = true;
    }

    return drive ? true : false;
  }

  getDriveId() 
  {
    this.gdocService.getMyDriveId().then((res) => 
    {
      this.driveurl2 = "/s/space/" + res;
    });
  }

  //if url is my drive: dont show parents, if url is a space : show parents, if url isnt space dont show parents
  isRoot() 
  {
    this.route.params.forEach((params) => 
    {
      const id = params["spaceId"];

      this.parentId = params["spaceId"];

      if (id != this.driveId) 
      {
        this.showParentLink = true;
      }
      else 
      {
        this.showParentLink = false;
      }

    });
  }

  getRoute(uid) 
  {
    const url = "/s/space/" + uid;

    this.router
      .navigateByUrl("/", { skipLocationChange: false })
      .then(() => this.router.navigateByUrl(url));
  }

  getGroupe(url) 
  {
    this.router
      .navigateByUrl("/", { skipLocationChange: false })
      .then(() => this.router.navigateByUrl(url));
  }

  isLinkActive(url): boolean 
  {
    const queryParamsIndex = this.router.url.indexOf("?");
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);

    return baseUrl === url;
  }

  isTabActive(key) 
  {
    const currentSpaceUid = this.route.params["_value"]["spaceId"];

    if (key.indexOf("Template") !== -1) 
    {
      const listeModels = Object.entries(this.modeleSpaces).find((item) => 
      {
        const currentSpaceUid = this.route.params["_value"]["spaceId"];
        const model = Object.values(item[1]).find((val) => val["space"]["uid"] === currentSpaceUid) || null;

        return model ? true : false;
      });

      return listeModels ? true : false;
    }
    else 
    {
      let listSpaces;

      listSpaces = Object.entries(this.spacesByTypes).find((item) => item[0] === key);
      if (listSpaces[1].length > 1) 
      {
        const space = Object.values(listSpaces[1]).find((val) => val["space"]["uid"] === currentSpaceUid) || null;

        return space ? true : false;
      }
    }
  }

  upperize(s: string) 
  {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  docTypes = [];

  getSpaceType() 
  {
    this.gedService.getDocTypes("").then((res) => 
    {
      this.docTypes = res || [];
    });
  }

  transformSpace(space) 
  {



    const result = {
      name: space.pagename,
      path: space.path,
      type: space.type
    }

    return result
  }
  async getSpaces() 
  {

    //await this.spaceService.clearCache();
    // get spaces grouped by main types if any, or space name
    await this.spaceService.clearCache()
    this.spacesByTypes = await this.spaceService.getSpaceGroups();
    this.__spaces = await this.spaceService.getSpaces()
    const modelSpaces = await this.spaceService.getSpaceTemplates();

    // build spaces list for display in component
    for (const typeName in this.spacesByTypes) 
    {

      const spacesByType = this.spacesByTypes[typeName];
      const nbSpaces = spacesByType.length();

      // several spaces for the type
      if (nbSpaces > 1) 
      {
        // several spaces => display type
        this.Spaces2.push(
          {
            isGroupe: true,
            title: spacesByType.getName(),
            spaces: spacesByType.getSpaces()
          });
      }
      else if (nbSpaces == 1) 
      {
        // one spaces => display space name ?
        this.Spaces2.push(
          {
            isGroupe: false,
            // title: spacesByType[0].space['name'],
            title: spacesByType.getName(),
            spaces: spacesByType.getSpaces()
          });
      }
    }


    this.spacesData =
      Object.values(this.Spaces2)
        .sort((a: string, b: string): number => 
        {
          return a['title'].toUpperCase() > b['title'].toUpperCase() ? 1 : -1
        });
    this.__spaces = Object.values(this.__spaces)
      .sort((a, b): number => 
      {
        return a['name'].toUpperCase() > b['name'].toUpperCase() ? 1 : -1
      });


    const modelName = "Templates";
    let models;

    if (modelSpaces.length()) 
    {
      models = [
        {
          title: modelSpaces.getName(),
          spaces: modelSpaces.getSpaces(),
          isGroupe: true
        }
      ];
    }
    else 
    {
      models = [
        {
          title: modelSpaces.getName(),
          spaces: [],
          isGroupe: false
        }
      ];
    }

    this.emitdataGroupes.emit(
      {
        groups: this.spacesData,
        //models,
        spaces: this.__spaces
      });
    this.__spaces.slice(0, 3).forEach(space => 
    {
      this.subGroups.push(
        {
          pagename: space?.['name'],
          path: /*'/s/space/' + */space?.['uid'],
          type: 'space',
          icon: 'gg-folder',
        }
      )
    });
    Object.values(this.spacesData).slice(0, 8).forEach(espace => 
    {
      this.subGroups.push(
        {
          pagename: espace?.['title'],
          path: /*'/s/space/groups/' + */espace?.['title'],
          type: 'group',
          icon: 'gg-organisation',
        }
      )
    });

    /* this.subGroups.push(
      {
        pagename: modelName,
        path: '/s/space/groups/' + modelName,
        icon: 'gg-organisation',
      }
    ); */

    if (this.subGroups.length > 9) 
    {
      this.subGroups.push(
        {
          pagename: "All groups",
          path: /*'/s/groups/all' */ 'all',
          type: 'group',
          icon: 'gg-organisation',
        }
      );
    }

    this.spaces = this.subGroups.map(this.transformSpace)


  }




  getSpace(key) 
  {
    this.spaceItem = key;
  }

  hostdomain = localStorage.host_domain;

  navToSpace(i) 
  {
    this.router.navigate([this.spaceurl[i]], {
      queryParams: { view: "default", d: localStorage.host_domain },
    });
  }

  //Create new Folder event
  openFolderDialog(): void 
  {
    const dialogRef = this.dialog.open(AddFolderComponent,
      {
        width: "650px",
        data:
        {
          title: environment.stringsFile.newFolder,
          folderName: "",
          parentId: this.parentId,
        },
      });

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((result) => 
    {
      this.folder = result;
    });
  }

  //Create new Google file event
  openDocDialog(mimeType, typeName): void 
  {
    const dialogRef = this.dialog.open(AddDocComponent, {
      width: "fit-content",
      minWidth: "650px",
      data:
      {
        title: environment.stringsFile.createA + typeName,
        documentName: "",
        mimeType: mimeType,
        parentId: this.parentId,
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((result) => 
    {
      this.document = result;
    });
  }

  //Create new Document Type event
  openDocumenttypeDialog(): void 
  {
    const dialogRef = this.dialog.open(AddDoctypeComponent,
      {
        width: "500px",
        minWidth: "650px",
        height: "max-content",
        data:
        {
          title: environment.stringsFile.newDoc,
          documentName: "",
          parentId: this.parentId,
        },
      });

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe((result) => 
    {
      this.folder = result;
    });
  }

  //not used anymore
  toggleDisplay() 
  {
    this.sideNavState = !this.sideNavState;
    this.linkText = !this.linkText;

    if (!this.sideNavState) 
    {
      this.contentMargin = 100;
    }
    else 
    {
      this.contentMargin = 250;
      this.iconPadding = 15;
    }
    setTimeout(() => 
    { }, 200);
  }

  showFilrstletter(name) 
  {
    const x = name.charAt(0);

    return x;
  }


  togglesidebar() 
  {
    this.closeSideMenu.emit();
  }
  close() 
  {
    this.isDocModalOpen = false;
  }
  open() 
  {
    this.isDocModalOpen = true;
  }
  onClick(event: any) 
  {
    // patch FY : à vérifier avec Youssef...
    try 
    {
      event.currentTarget.classList.toggle('');
    }
    catch (error) 
    {

    }
  }

  menu1: Page[] =
    [
      {
        pagename: environment.stringsFile.homeSideNav,
        path: this.ws_url,
        icon: 'gg-home-alt',
      },

      {
        pagename: environment.stringsFile.spacesSideNav,
        path: this.groupsUrl,
        image: 'assets/images/spaces-icon.svg',
        subpages: this.subGroups
      },

      {
        pagename: environment.stringsFile.taskesSideNav,
        path: this.taskurl2,
        icon: 'gg-play-list-check',
      },


    ];
  menu2: Page[] = [{
    pagename: environment.stringsFile.drivesSideNav,
    path: this.drivesUrl,
    icon: 'gg-google',
  },
    /* {
      pagename: 'Support',
      icon: 'gg-support',
      path: '/support',
      isLastItem: true,
    }, */
  ]

  items = [
    { label: environment.stringsFile.support, icon: 'gg-support', callback: this.getToSupport.bind(this) },
    { label: environment.stringsFile.userguide, icon: 'gg-ereader', callback: this.getUserGuide.bind(this) },
    { label: "Sleekplan", icon: 'gg-eye', callback: this.showSleekplanWidget.bind(this) },

  ];

  getGroups(name: string) 
  {
    const url = "/s/space/groups/" + name;

    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate([url]));

  }
  goToSupport() 
  {

    const redirectUrl = "https://devoteam.service-now.com/itportal/?id=index";

    window.open(redirectUrl, "_blank");
  }

  getToSupport() 
  {

    const redirectUrl = "https://devoteam.service-now.com/itportal/?id=index";

    window.open(redirectUrl, "_blank");
  }

  getUserGuide() 
  {

    const redirectUrl = "https://help.presencedoc.com/user-guide";

    window.open(redirectUrl, "_blank");
  }

  showSleekplanWidget() 
  {
    window["$sleek"]["open"]();

  }
}


export interface Icon {
  label: string,
  icon: string,
  link: string,
  badge?: number | string,
  active?: boolean,
  class?: string
}