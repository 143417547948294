import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { environment } from 'src/environments/environment';
import { ContactsComponent } from './containers';


const routes: Routes = [
  {
    path:'all', component: ContactsComponent,   data:{
      title:environment.stringsFile.peoplePage
    }
  }
  , {
    path:'all2', component: DashboardComponent,   data:{
      title:environment.stringsFile.peoplePage
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PeopleRoutingModule 
{ }
