<div class="container-fluid">
    <div class="row my-2 my-md-5">
        <div class="col mb-3 mb-md-5">
            <h3 class="text-center">Get help about our products, basic knowledge and more</h3>
        </div>
    </div>
    <form class="row mb-3 mb-md-5">
        <div class="col-7 col-md-10 pe-0 me-0">
            <aura-input-text className="border-0 shadow-sm" size="large"
                placeholder="Search for features, basic knowledge or options…" id="search"></aura-input-text>
        </div>
        <div class="col-5 col-md-2 me-0">
            <button auraButton label="Search" size="large" type="primary" [full]="true"></button>
        </div>
    </form>
    <div class="row">
        <div class="col-lg-4 mt-5" *ngFor="let card of cards">
            <!-- <aura-card [link]="card.link" [src]="card.src" [size]="card.size" [title]="card.title" [text]="card.text">
            </aura-card> -->
        </div>
    </div>

</div>
