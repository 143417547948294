import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'aurax-btn-icon',
  standalone: true,
  imports: [CommonModule,
    RouterModule],
  templateUrl: './btn-icon.component.html',
  styleUrls: ['./btn-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuraxBtnIconComponent implements OnInit 
{
  @Input()
    link = '/'

  @Input()
    icon = ''

  @Input()
    label = ''

  @Input() badge
  constructor() 
  { }

  ngOnInit(): void 
  {
  }

}
