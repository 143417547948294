<aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="title"   width="100%" height="100%" [fullscreen]="true">
     <div class="body">



        <div class="container-fluid mb-1" >
            <div class="row">
              <div class="col-md-12">
              <aura-tabs [activeTabIndex]="window">
                  <aura-tab title="{{'Informations' | translate}}  ">
                    <div class="row">
                        <div class="col-6  rounded p-3">
                            <div class="card border-0 shadow-sm">
                                <div class="card-body text-start">
                                  <div class="header">
                                      <h4 class="h3">{{'DocumentProperties' | translate}}</h4>
                                      <span class="edit-icon" (click)="iseditMeta=!iseditMeta">
                                          <svg xmlns="http://www.w3.org/2000/svg"  class="icon-crayon" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"/> </g> </svg>
                                      </span>
                                    </div>

                                    <app-doc-desc-info  [_gedDoc]="documentCurent"  [canEdit]="canEdit" [iseditMeta]="iseditMeta"></app-doc-desc-info>

                                </div>
                            </div>
                        </div>
                        <div class="col-6  rounded p-3">

                          <div class="card border-0 shadow-sm">
                              <div class="card-body text-start">
                                  <h4 class="h3" >{{'Etat document' | translate}}</h4>
                                  <app-doc-workflow-metadata [_gedDoc]="documentCurent"  ></app-doc-workflow-metadata>

                              </div>
                          </div>
                        </div>
                    </div>
                  </aura-tab>
                  <aura-tab title="{{'Approbations' | translate}}"   >
                    <div class="px-5  processus" > 
                        <app-doc-workflow-processus  (wfState)="setWorkflowTasks($event)"  [_gedDoc]="documentCurent" [isInfo]='true'></app-doc-workflow-processus>
                    </div>

                    <div class="container-fluid mb-1" >
                        <div class="row  gx-2"  *ngIf="workflowHistoric?.length !=0">
                            <div class="col-md-12 mt-2">
                                <div class="card border-0 shadow-sm">
                                    <div class="card-body text-start">
                                        
                                        <app-doc-list-completed-tasks   [_gedDoc]="documentCurent"></app-doc-list-completed-tasks>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aura-tab>

                  <aura-tab title="{{'linkedDoc' | translate}}"  *ngIf="isRelatedDocs">
                    <app-doc-related [_gedDoc]="documentCurent" [canEdit]="canEdit" (documentLinked)="getDocLinks($event)"></app-doc-related>
                </aura-tab>
                </aura-tabs>
              </div>
            </div>


      </div>

       <!--  <div class="controls py-3">
         <button (click)="currentTab = 0" [ngClass]="{'active': currentTab == 0}" class="btn linked ps-0 pe-4">
                {{'linkedDoc' | translate}}
            </button>
       <button (click)="currentTab = 1" [ngClass]="{'active': currentTab == 1}" class="btn comments ps-3 pe-4 ">
            {{'historyDoc' | translate}}
            </button>
        </div>

        <div *ngIf="currentTab == 0" class="row list hide">
            <div class="col">
            <app-doc-related [_gedDoc]="documentCurent" [canEdit]="canEdit" ></app-doc-related>
            </div>
        </div>
          <div *ngIf="currentTab == 1" class="row list hide">
              <div class="col">
                <app-doc-workflow-historic [_gedDoc]="documentCurent"></app-doc-workflow-historic>
               </div>
          </div> -->
    </div>

</aura-modal>
