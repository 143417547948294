<div class="loader" *ngIf="timeoutTruthTable">

    <aura-loader [show]="loading"></aura-loader>
</div>
 
    <div *ngIf="displayWarningMessage && flaggedTasksTableData.length === 0" class="warning-message">
        <h1>{{'verifyAllWarning' | translate}}</h1>
    </div>
     <ng-container  *ngIf=" (!loading && (flaggedTasksTableData?.length || taskTableData?.length )) ; else empty">
        <div class="chips-container">
            <mat-chip-list aria-label="Fish selection">
                <div *ngFor="let chipData of chipsData; let index = index">
                    <app-chip 
                        [label]="chipData.label"
                        [icon]="chipData.icon"
                        [activeClass]="chipData.activeClass" 
                        (click)="updateClickedChipAndFilter(index)"
                    ></app-chip>
                </div>
            </mat-chip-list>
        </div>
        <div class="inline-header-button-container" *ngIf="chipFilter === 'Approbations' || chipFilter === 'All'">
            <h2>{{'Approbations' | translate}}</h2>
            <h5 class="button-header" (click)="getApprobationsForAllDocuments()" *ngIf="taskTableData.length !== 0">
                {{'verifyAll' | translate}} {{ taskTableData.length }} {{'documents' | translate}}
                <mat-icon class="button-header button-header-icon" fontIcon="task_alt"></mat-icon>
            </h5>
        </div>
         
         <aura-table-row *ngIf="(chipFilter === 'Approbations' || chipFilter === 'All') && !displayWarningMessage "
         data-title="{{'My tasks' | translate}}"
         [disableHead]="true"
         [columns]="columns"
         [data]="taskTableData" ></aura-table-row>
         
         <div *ngIf="displayWarningMessage && flaggedTasksTableData.length !== 0" class="warning-message-with-flag">
            <span>{{'verifyAllWarning' | translate}}</span>
        </div>
         
         
         
         
         <h2 *ngIf="chipFilter === 'Flagged' || chipFilter === 'All'">{{'flaggedContent' | translate}}</h2>
         
         <aura-table-row *ngIf="chipFilter === 'Flagged' || chipFilter === 'All'"
         data-title="{{'flaggedContent' | translate}}"
         [disableHead]="true"
         [columns]="columns"
         [data]="flaggedTasksTableData" ></aura-table-row>
         
        </ng-container>
    
    <ng-template #empty>
        <app-tasks-empty *ngIf="!loading && !displayWarningMessage"></app-tasks-empty>
    </ng-template>

 