<app-sidenav [title]="'currentActivityString' | translate" [breadcrumb]="bc">
  <div class="container-fuild">
    <div class="row gx-3">
      <div class="col-12 col-md-6 col-lg-4 mt-4 mt-md-0" *ngIf="withEvents">
        <div class="box">
          <app-recent-docs [title]="'Recent space documents' | translate" [docRecents]="recentDocs"
            *ngIf="recentDocs?.length != 0"></app-recent-docs>
        </div>
        <div class="box">
          <app-recent-docs [title]="'Other recent files' | translate" [docRecents]="recentFiles"
            *ngIf="recentFiles?.length != 0"></app-recent-docs>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-6 mt-6 mt-md-0" *ngIf="!withEvents">
        <div class="box">
          <app-recent-docs [title]="'Recent space documents' | translate" [docRecents]="recentDocs"
            *ngIf="recentDocs?.length != 0"></app-recent-docs>
        </div>
        <div class="box">
          <app-recent-docs [title]="'Other recent files' | translate" [docRecents]="recentFiles"
            *ngIf="recentFiles?.length != 0"></app-recent-docs>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 mt-4 mt-md-0" *ngIf="withEvents">
        <div class="box">
          <app-files-actions [Actions]="filesWithActions" ></app-files-actions>
          <!-- <br> -->
          <app-people [Peoples]="recentContacts" *ngIf="withRecentContacts && recentContacts?.length != 0"></app-people>
        </div>
      </div>

        <div class="col-12 col-md-12 col-lg-6 mt-6 mt-md-0" *ngIf="!withEvents">
        <div class="box">
          <app-files-actions [Actions]="filesWithActions" *ngIf="filesWithActions?.length != 0"></app-files-actions>
          <app-people [Peoples]="recentContacts" *ngIf="withRecentContacts && recentContacts?.length != 0"></app-people>
        </div>
      </div> 

      <div class="col-12 col-md-12 col-lg-4" *ngIf="withEvents">
        <div class="box bg-color pb-2 rounded mt-5">
          <div class="px-3 pt-3 mb-3">
            <div class="
                bg-primary
                color-white
                w-100
                position-relative
                text-left
                ps-3
                h-155
                rounded
              ">
              <span class="h1">{{ "event" | translate }}</span>
              <img src="/assets/images/event.svg" alt="" class="
                  banner-img
                  m-auto
                  d-block
                  pb-2
                  position-absolute
                  end-40
                  top-0
                " width="250" />
            </div>
          </div>

          <div id="color-calendar" class="bg-white d-flex justify-content-center"></div>
          <div class="sub_box px-3" *ngIf="eventRecents?.length != 0">
            <app-events [Events]="eventRecents" (emitState)="getEventsOutput($event)"></app-events>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-sidenav>