<mat-card  class="card-content">
  <mat-card-header>
      <mat-card-title>

      <mat-toolbar class="card-title">
          <span>{{'searchFiltersTxt' | translate}}</span>
          <span class="app-spacer"></span>
          <button mat-icon-button style="vertical-align:middle;" routerLink="{{homePage}}" >
            <mat-icon style="color: white">close</mat-icon>
        </button>
        </mat-toolbar>
      </mat-card-title>
  </mat-card-header>

  <mat-card-content   >
       <div style="padding-top: 15px; padding-left: 10px !important;">
       <h3>{{'DocResults' | translate}} </h3>
       </div>
       <mat-list class="listFilter">
 
        
          <mat-list-item *ngFor="let filt of filters"  class="fullFilter">
              <div class="row" *ngIf="filt?.values.length!=0">
                  <div style="padding-top: 15px; width: 120px; padding-left: 10px !important;">
                      <span class="label" >{{  remove_(filt.label )| titlecase}} </span>
                  </div>
               
                  <div   class="filter-content">
                    <mat-radio-group class="example-radio-group" (change)="searchs(filt.label,$event.value)"
                                      name="{{filt.label}}">
                      <mat-radio-button class="example-radio-button" *ngFor="let value of filt.values"
                                        [value]="value" color="primary" [checked]="filterBy(filt.label,value, filt.values)">
                                         
                       <span class="filterVal" *ngIf="filterBy(filt.label,value , filt.values)">[  {{value  | titlecase}}  ]  </span> 
                       <span  class="filterVal" *ngIf="!filterBy(filt.label,value, filt.values)">{{value  | titlecase}}    </span>
                      </mat-radio-button>
                      
                    </mat-radio-group>
                  </div>

              </div>
          </mat-list-item>
      </mat-list>
  </mat-card-content>
  <mat-card-actions class="col-12 actions">
      <span *ngIf="filters.length==0">{{'noFiltersTxt' | translate}}</span>
 
       <button *ngIf="filters.length !==0" mat-raised-button (click)="resetFilters()" >{{'resetFiltBtn' | translate | uppercase}}</button>
      <button class="btn-close" *ngIf="filters.length===0 && !isType"  mat-raised-button (click)="closeSearch()" >{{'closeFilterBtn' | translate | uppercase}}</button>
  </mat-card-actions>

</mat-card>