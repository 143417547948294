import {
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';

import { TableType } from '../table-type.pipe';
import { TableClass } from '../table-class.pipe';
import { ATableComponent } from '../table.component';
import { ITableRow } from '../table.interface';
import { Dropdown, Loader, TDropdownItem } from '@pdoc/aura';
@Component({
  exportAs: 'AuraTableSolidComponent',
  selector: 'aura-table-solid-extension',
  templateUrl: 'table-solid.component.html',
  styleUrls: ['table-solid.component.scss', '../table.component.scss'],
  imports: [
    DatePipe,
    NgbTooltipModule,
    NgClass,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    RouterLink,
    TableType,
    TableClass,
    Dropdown,
    Loader,
  ],
  standalone: true,
})
export class TableSolidExtension extends ATableComponent 
{
  @Input() loading?: boolean = false;
  rowHoverStates: boolean[] = [];
  
  onMouseOver(rowIndex: number) 
  {
    this.rowHoverStates[rowIndex] = true;
  }

  onMouseOut(rowIndex: number) 
  {
    this.rowHoverStates[rowIndex] = false;
  }

  hasAction(td: ITableRow, row: ITableRow[]): Function | boolean 
  {
    const action: Function | undefined = this.columns.find(
      (x) => x.id === td.id
    )?._action;

    return action?.(row) || false;
  }

  getMenuItems(items: any, row: any) 
  {
    return items.map((el: any): TDropdownItem => 
    {
      return {
        label: el.label,
        callback: () => el.callback(row),
      };
    });
  }
}
