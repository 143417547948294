import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


import { FormManagerSelectFormatData } from "./form-manager-select-format-data.pipe";
import { FormManagerSelectSelectedValue } from "./form-manager-select-selected-value.pipe";
import { AppFormFieldComponent } from './components/app-form-field/app-form-field.component';
import { FormManagerComponent } from "./form-manager.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { AuraModule } from '@pdoc/aura';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";


import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    FormManagerSelectFormatData,
    FormManagerSelectSelectedValue,
    FormManagerComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    AuraModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    TranslateModule,


  ],
  exports: [MatNativeDateModule, FormManagerComponent]
})
export class FormManagerModule 
{}
