import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ListItem } from "src/design-system/core/interfaces/list-item";
import { environment } from "src/environments/environment";
import { GDocService } from "src/modules/app-common/services";
import { SpaceService } from "src/modules/spaces/services";
import { WorkplaceService } from "../../services/workplace.service";


const themePath = environment.themePath;

 @Component({
   selector: "app-files-actions",
   templateUrl: `./${themePath}/files-actions.component.html`,
   styleUrls: [ `./${themePath}/files-actions.component.scss`],
 })
export class FilesActionsComponent implements OnInit , OnChanges  
{
  [x: string]: any;  
  linkdrive = environment.drivelink;
  actionComments = environment.stringsFile.actionComments;


 
  @Input('Actions') listeFiles

  constructor(
    private gdocService: GDocService,
    private router: Router,
    private spaceService: SpaceService,
    private workspaceService: WorkplaceService
  ) 
  {}
  displayProperties:ListItem[] = [];


  ngOnChanges(changes: SimpleChanges): void 
  {

    Object.values(this.listeFiles).forEach(item=> 
    {
      this.setDisplayProperties(item['displayProperties']);

    })
  }


  ngOnInit(): void 
  {
    //this.loadFilesActions();
  }
  
  setDisplayProperties(results)
  {
    this.displayProperties.push(results[0])
  
  }

  /*  async loadFilesActions() {
    this.workspaceService.getFilesActions().then((res) => {
      this.listeFiles = res;
    });
  }  
 */
  openDocum(doc) 
  {
    this.spaceService.openDocTab(doc);
  }

  toExternalLink(f) 
  {
    window.open(f.webViewLink, "_blank");
  }
  toDrive()
  {
    window.open(this.linkdrive, '_blank');
  }
  rowParent(parentId) 
  {
    
    
    this.gdocService.getMyDriveId().then((res) => 
    {
      if (parentId.parents == res) 
      {
        this.router.navigate(["/s/space/root"])
      
      }
      else 
      {
        if (parentId.parents == null) 
        {
          this.router.navigate(["/workspace/main"])
           
        }
        else
        {
          this.router.navigate(['/s/space/'+parentId.parents],{queryParams:{ d: localStorage.host_domain}})
        }
      }
    });
 

  }
}
