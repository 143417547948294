import { stringFile } from "src/assets/strings/strings";

export class GcsItem 
{
  datasource: string;
  objectType: string;
  title: string;
  displayProperties;
  webLink: string;
  snippet: string; 
  iconLink: string;
  constructor(gcsItem)
  {
    this.setDatasource(gcsItem);
    this.setObjectType(gcsItem);
    this.setTitle(gcsItem);
    this.setSnippet(gcsItem);
    this.setWebLink(gcsItem);
    this.setIconLink(gcsItem);
    this.getDisplayProperties();

  }
  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties =  [
      {
        id: 'name',
        value: this.getTitle(),
        iconImg: this.getIconLink()
      },
      {
        id: 'details',
        value: this.getSnippet().replace("&#39;","''")
      },
      {
        id: 'Menu',
        value: '',
        menuItems: [
          {
            label: stringFile.stringsJson['openLink'],
            callback: function () 
            {
              window.open(self.getWebLink(),"_blank")
            }
          },
        ],
      }
            
    ]

    return this.displayProperties;
  }

  setIconLink(gcsItem)
  {
    this.iconLink = '/assets/google_icons/docs_icon.svg';
  }

  getIconLink()
  {
    return this.iconLink;
  }

  setSnippet(gcsItem)
  {
    this.snippet = (gcsItem?.snippet?.snippet || '').replace("&#39;","'");
    this.snippet = this.snippet.replace("&#39;","'");
  }

  getSnippet()
  {
    return this.snippet;
  }
  setDatasource(gcsItem)
  {
    this.datasource = gcsItem?.metadata?.source?.name || gcsItem?.metadata?.source?.predefinedSource || '';

  }

  setObjectType(gcsItem)
  {
    this.objectType = gcsItem?.metadata?.objectType || '';
  }

  setWebLink(gcsItem)
  {
    this.webLink = gcsItem?.url || '';
  }
    
  setTitle(gcsItem)
  {
    this.title = gcsItem?.title || '';
  }

  getDatasource()
  {
    return this.datasource;
  }

  getObjectType()
  {
    return this.objectType;
  }

  getWebLink()
  {
    return this.webLink;
  }

  getTitle()
  {
    return this.title;
  }
}