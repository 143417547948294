import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-doc-upload",
  templateUrl: "./doc-upload.component.html",
  styleUrls: ["./doc-upload.component.scss"],
})
export class DocUploadComponent implements OnInit, OnChanges 
{
  @Output() documentUploaded = new EventEmitter();

  uploadedFileName: string | undefined;

  constructor() 
  {}
  ngOnChanges(changes: SimpleChanges): void 
  {}

  ngOnInit(): void 
  {}

  triggerFileInput(): void 
  {
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;

    if (fileInput) 
    {
      fileInput.click();
    }
  }

  uploadfromPc(event) 
  {
    const file = event.target.files[0];

    if (file) 
    {
      this.uploadedFileName = file.name;
      this.documentUploaded.emit(file);
    }
  }

  async onFileDropped($event: File[]) 
  {
    const file = $event[0];

    if (file) 
    {
      this.uploadedFileName = file.name;
      this.documentUploaded.emit(file);
    }
    //console.log("onFileDropped", $event[0])
  }
}
