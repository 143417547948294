
<aura-modal 
  [isOpen]="isOpen"
  (close)="closeModal()" 
  [title]="typeDocStr" 
  [fullscreen]="false" 
  width="90%">
    <div class="row justify-content-center spinnerLoading" *ngIf="isLoading">
         
      <mat-spinner ></mat-spinner>
      <div class="in-loader">
         <h2 class="aura-h2">{{'attende_doc_insert'|translate}} </h2>
      </div>
    
    </div>
  
      <div *ngIf="!isLoading">
  
        <mat-drawer-container>
          <form #f="ngForm" >
  
              <div>
                  <mat-form-field  class="form-dev"  appearance="fill" style="display: block; text-justify: center;" >
                      <mat-label>Document Type</mat-label>
                        <mat-select [(ngModel)]="docType.title"   [(value)]="docType.title" name="docType"
                        (selectionChange)="onSelectedType($event)"
                          >
                         <mat-option *ngFor="let option of autoComplete" [value]="option.title">
                           {{option.title}}
                         </mat-option>
                       </mat-select>
                    </mat-form-field>
  
              </div>
  
              <div *ngIf="showForm" class="form-content">
                <ng-container  appearance="fill" style="display: block; text-justify: center;" 
                *ngFor="let field of formFields; let j=index">
                
                <mat-form-field class="form-dev" *ngIf=" field.auto==null && field.key!='planned_date_publication'  && field.control !=='checkbox' && !(field.isEnum)   && field.control!=='select'">
                  <mat-label>{{field.label}}</mat-label>
                    <input matInput  [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}" 
                   required="{{field.required}}">  
                </mat-form-field>
                  
                   <mat-form-field class="form-dev" *ngIf="field.key=='planned_date_publication' ">
                    <mat-label>{{field.label}}</mat-label>
                     
                      <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="date" 
                      required="{{field.required}}">  
                     
                   </mat-form-field>
                    <mat-form-field class="form-dev" *ngIf="field.auto!=null && field.key!='space' && field.control!='select' && field.control!='checkbox' && field.key!='template' && field.key!='for_type_document'  && field.control !='select' && field.control !=='checkbox'">
                      <mat-label>{{field.label}}</mat-label>
                
                        <input matInput [(ngModel)]="field.value.html" name="{{field.key}}" type="{{field.type}}" 
                        required="{{field.required}}"
                        [matAutocomplete]="autofield" (ngModelChange)="doFilterField(field)" #optionfield="ngModel">  
                        
                        <mat-autocomplete #autofield="matAutocomplete" (optionSelected)="onSelectedField($event,field)" class="autoOption">
                          <mat-option *ngFor="let option of field.fieldAutoList" [value]="option.html">
                            {{option.html}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="form-dev" *ngIf="field.key=='for_type_document'  && field.control!='select' && field.control !='checkbox' ">
                      <mat-label>{{field.label}}</mat-label>
                        <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}" 
                       required="{{field.required}}"
                       [matAutocomplete]="autofield" (ngModelChange)="doFilterField(field)" #optionfield="ngModel">  
                       
                       <mat-autocomplete #autofield="matAutocomplete" (optionSelected)="onSelectedField($event,field)" class="autoOption">
                         <mat-option *ngFor="let option of field.fieldAutoList" [value]="option.html">
                           {{option.html}}
                         </mat-option>
                       </mat-autocomplete>
                    </mat-form-field>
                  
                    <mat-form-field class="form-dev" *ngIf="field.key!=='space' && field.key=='template'&& field.auto!==null || field.control==='select' ">
                      <mat-label>{{field.label}}</mat-label>
                        <mat-select [(ngModel)]="field.value.html" [(value)]="field.value.html"    required="{{field.required}}"
                       (selectionChange)="onSelectedValue(field, field.value)" name="{{field.key}}">
                         <mat-option *ngFor="let option of field.fieldAutoList" [value]="option.html">
                           {{option.html}}
                         </mat-option>
                       </mat-select>
                    </mat-form-field>
                    
                      <div *ngIf="field.control ==='checkbox' && field.isEnum  "  class="checkbox-dev">
                          <mat-label >{{field.label}}</mat-label>
                          <section  *ngFor="let item of field.enumValues | keyvalue"  class="checkboxSection" >
                            <input type="checkbox"   (change)="onChangeEnumsChecked(item, field,$event.target.checked)"  class="checkboxItem"> {{item.value}}<br>
                        </section> 
         
                      </div >
                      <mat-form-field *ngIf="field.control ==='checkbox'  && field.isMultiple && !(field.isEnum)" class="form-dev  ">
                        <mat-label>{{field.label}}</mat-label>
                        <!-- <section *ngFor="let item of field.fieldAutoList
                        " class="checkboxSection">
                          <input type="checkbox" class="checkboxItem"
                            (change)="onChangeEnumsChecked(item, field,$event.target.checked)"
                            > {{item.html}}<br>
                        </section> -->
                        <mat-select 
                        [(ngModel)]="field.value.html" 
                        [multiple]="true" 
                        [ngModelOptions]="{standalone: true}" >
                         <mat-option *ngFor="let item of field.fieldAutoList" [value]="item" (onSelectionChange)="onChangeEnumsChecked(item,field,$event)"  >
                           {{ item.html }}
                         </mat-option>
                       </mat-select> 
                       
            
                      </mat-form-field>
                    
                 
                  <mat-form-field class="form-dev" *ngIf="(field.isEnum) && !(field.isMultiple) && field.control!=='select' && field.control !=='checkbox'" >
                    <mat-label >{{field.label}}</mat-label>
                      <mat-select [(ngModel)]="field.value.html" [(value)]="field.value.html" name="{{field.key}}">
                        <mat-option  *ngFor="let item of field.enumValues | keyvalue " [value]="item.key">
                          {{item.value}}
                        </mat-option>
                      </mat-select>
                   
                    
                  </mat-form-field>
               
              
      
                </ng-container>
              </div>
          </form>
          <mat-drawer #drawer class="rightdrawer" mode="side" position="end"
          style="width: 50%; height: auto !important;">
            <img *ngIf="previewLink!=null" [src]="previewLink" class="previewImg">
          </mat-drawer>
  
        </mat-drawer-container>
  
  
      <div  class="col-12 actions  mb-2" align="end">
        <div style="padding-right: 31px;">
          <button auraButton   (click)="closeModal()"  label="{{'cancelBtn' | translate}}"  class="cancelBtn"> </button>
          <button auraButton type="light" [hasShadow]="true" label="{{'addBtn' | translate}}" (click)="onCreateDoc(f.value)" [disabled]="!f.valid" ></button>
        <!--   <button mat-button class="cancelBtn" mat-dialog-close>{{'cancelBtn' | translate | uppercase}}</button>
          <button mat-button class="submitBtn" (click)="onCreateDoc(f.value)" >{{'addBtn' | translate | uppercase}}</button> -->
        </div>
       </div>
        </div>
  </aura-modal>
  