<div class="logo-container">
  <a class="logo" [routerLink]="['/']">
    <img src="/assets/logos/pdocs.svg" alt="presence docs logo" height="100%">
  </a>
</div>

<!-- <aura-breadcrumb [steps]="breadcrumb"></aura-breadcrumb> -->
<app-search-bar *ngIf="isUrlSearch"></app-search-bar>
<aura-dropdown position="bottom-right" [items]="avatarActions" size="lg">
  <div class="logo-container">
    <aurax-avatar-rounded [image]="profilePic"></aurax-avatar-rounded>
  </div>
</aura-dropdown>
