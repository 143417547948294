<aura-modal
  [isOpen]="isOpen"
  (close)="closeModal()"
  [title]="'Space members' | translate"
  [fullscreen]="false"
  width="70%"

>
  <ng-container>
    <form #form="ngForm">
      <div class="row from-perm">
        <div class="col-5">
          <mat-form-field
            class="form-dev"
            appearance="fill"
            style="display: block; text-justify: center"
          >
            <mat-label>{{ "Select new member" | translate }}</mat-label>
            <input
              matInput
              [matAutocomplete]="auto"
              [multiple]="true"
              [formControl]="userCtrl"

              cdkFocusInitial
               (ngModelChange)="doFilterField($event)"
            />

            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onSelectUser($event)"
            >
              <mat-option
                *ngFor="let user of usersList "
                [value]="user.email"
              >
                <img
                  style="vertical-align: middle"
                  aria-hidden
                  src="{{ user?.picture }}"
                  height="25"
                />
                <span> {{ user?.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-5">
          <mat-form-field
            class="form-dev"
            appearance="fill"
            style="display: block; text-justify: center"
          >
            <mat-label>{{ "Role" | translate }}</mat-label>

            <mat-select
              [formControl]="permissionCtrl"
              name="permission"
              (selectionChange)="onSelectedPerm($event)"
            >
              <mat-option
                *ngFor="let perm of permissionRoles"
                [value]="perm.value"
              >

                {{ perm.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <!-- <span  >
            <i class="gg-add"></i>
          </span> -->
          <button class="circle-btn" (click)="addPermissiontoUser()"  [disabled]="!form.valid">
            <i class="fas fa-plus"></i>
          </button>


        </div>
      </div>

      <!--     <mat-form-field>
              <mat-label>Select multiple options</mat-label>
              <mat-select [(ngModel)]="selectedOptions" [multiple]="true"  (selectionChange)="onSelectChange($event)">
                <mat-option *ngFor="let option of options" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
      <!-- <div class="col-md-12 actions">
                <button
                  auraButton
                  label="{{'cancelBtn' | translate}}"
                  class="cancelBtn"
                  (close)="closeModal()"> </button>

                <button auraButton
                  type="light"
                  [hasShadow]="true"
                  label="{{ 'Add permission' | translate }}"
                  (click)="addPermissiontoUser(f.value)"
                  [disabled]="!f.valid" ></button>
              </div>  -->
    </form>
  </ng-container>

  <div class="row justify-content-center spinnerLoading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

<div class="list-members">
  <ng-container>
    <h2 aura-h2> {{'List of members' | translate}}</h2>

    <ul class="user-list">
      <ng-container *ngFor="let perm of listePermission">
        <li *ngIf="perm?.who?.type != 'domain'">
          <div>
            <img aria-hidden src="{{ perm?.who?.photoLink }}" />
          </div>
          <div>
            <strong>{{ perm.who.name }} </strong>
            <span>  {{ perm.who.email }} </span>
          </div>

          <span> <strong>{{ perm.role | translate |titlecase }} </strong></span>

          <span (click)="removePermForUser(perm)" *ngIf="perm.canDelete"><i class="gg-close"></i></span>
          <span *ngIf="!perm.canDelete"><i class=""></i></span>

        </li>
      </ng-container>
    </ul>


  </ng-container>

  <ng-container>
    <h3 class="mt-3" *ngIf="isGeneral">General access</h3>

    <ul class="user-list">
      <ng-container *ngFor="let perm of listePermission">
        <li  *ngIf="perm?.who?.type == 'domain'">
          <div class="icon-general">
            <i class="gg-organisation"></i>
          </div>

        <div  class="list-general">
          <span> {{ perm.who.name }} </span>
        </div>
          <span> <strong>{{ perm.role  | translate |titlecase }} </strong></span>

          <span class="sp-general" *ngIf="!perm.canDelete"><i class="gg-key"></i
          ></span>

          <span (click)="removePermForUser(perm)" class="sp-general" *ngIf="perm.canDelete"><i class="gg-close"></i
          ></span>

        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>





  <!--  <aura-select-user


    [usersList]="[{
      firstname: 'Abdel',
      lastname: 'Amerda',
      id: 'id_amerda',
      image: '/assets/images/user-1.png'
  },{
      firstname: 'Talal',
      lastname: 'Cheniwin',
      id: 'id_cheniwi',
      image: '/assets/images/user-3.png'
  },{
      firstname: 'Toufiq',
      lastname: 'Laghzal',
      id: 'id_laghzal',
      image: '/assets/images/user-2.png'
  },{
      firstname: 'Abdel',
      lastname: 'Amerda',
      id: 'id_1',
      image: '/assets/images/user-1.png'
  },{
      firstname: 'Talal',
      lastname: 'Cheniwin',
      id: 'id_2',
      image: '/assets/images/user-3.png'
  },{
      firstname: 'Toufiq',
      lastname: 'Laghzal',
      id: 'id_3',
      image: '/assets/images/user-2.png'
  },{
      firstname: 'Abdel',
      lastname: 'Amerda',
      id: 'id_4',
      image: '/assets/images/user-1.png'
  },{
      firstname: 'Talal',
      lastname: 'Cheniwin',
      id: 'id_5',
      image: '/assets/images/user-3.png'
  },{
      firstname: 'Toufiq',
      lastname: 'Laghzal',
      id: 'id_6',
      image: '/assets/images/user-2.png'
  }]" (onChange)="changeIcon($event)"></aura-select-user> -->
</aura-modal>
