
<mat-card class="example-card" *ngIf="showLinks">
    <mat-card-header>
        <mat-card-title>Related documents</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="spinner-div">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
      <mat-nav-list *ngFor="let link of links">
        <div mat-subheader>{{link.title}}</div>
          <mat-list-item *ngFor="let d of link.data" (click)="toLink(d)">
            <img src="{{d.icon_link}}">
            <div class="linkItem"> {{d.name | uppercase}} </div>
          </mat-list-item>
        </mat-nav-list>
  
      </mat-card-content>
  
      <mat-card-actions>
          
      </mat-card-actions>
  
    </mat-card>
  
  