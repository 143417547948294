
<!-- <aura-title icon="/assets/images/icon-file.svg">{{ 'actionComments' | translate }}</aura-title>

<aura-list verticalAlign="center"
  [data]="displayProperties"
  [hasMenu]="true"></aura-list> -->

<aura-list verticalAlign="center" icon="/assets/images/icon-file.svg" [hasMenu]="true" 
[noShadow]="false"
[data-title]="actionComments" [data]="displayProperties">
 </aura-list>
 <p class="aura-h3 text-center" *ngIf="displayProperties.length ===0">
  No comment task assigned for you
 </p>
