import { environment } from "src/environments/environment";

const defaultPic = environment.defaultPicture;
 
export class Gcontact 
{
  name;
  email;
  picture;
  resourceName;
  constructor(contact) 
  {
    this.setName(contact);
    this.setEmail(contact);
    this.setPicture(contact);
    this.setResourceName(contact);
  }

  getName() 
  {
    return this.name;
  }
  getEmail() 
  {
    return this.email;
  }
  getPicture() 
  {
    return this.picture;
  }
  getResourceName() 
  {
    return this.resourceName;
  }

  setName(contact) 
  {
    if (contact?.names)
    {
      this.name = contact?.names["0"]?.displayName;
    }
    else if (contact.html) 
    {
      this.name = contact.html;
    } 
    else this.name = null;
  }

  setEmail(contact) 
  {
    if (contact?.emailAddresses)
    {
      this.email = contact?.emailAddresses["0"]?.value;
    }
    else if (contact.value) 
    {
      this.email = contact.value;
    } 
     
    else this.email = null;
  }
  setPicture(contact) 
  {
     
    if (contact.picture) 
    {
      this.picture = contact.picture
    } 
    else  
    {
      this.picture = contact?.photos?.["0"]?.url || defaultPic;
    }
  }
  setResourceName(contact) 
  {
    this.resourceName = contact?.resourceName;
  }
}
