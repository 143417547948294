export class Document 
{
  docId?: string;
  ownerToken?: string;
  owner?: string;
  content?: string;
  docName?: string;
  client?: string;
  modifDate?: string;

  fileInfos;

  constructor(fileInfos, doc?)
  {
    const document = doc;

    document.docId = fileInfos.id;
    this.fileInfos = fileInfos;

    return document;
  }

  getName() 
  {
    return this.fileInfos.name;
  }
}
