import { environment } from "src/environments/environment";
import { PersonGcontact } from "src/modules/contact/models/person-gcontact.model";
import { ContactService } from "src/modules/contact/services/contact.service";
import { UserService } from "../../services/user/user.service";

export class EventPerson
{
  photoLink: any;
  name: string;
  initialLetter: string;
  mail: string;
  isSelf: boolean;
  selfStatus: string;
  status: string;
  displayedSelfStatus: string;
  ldapId: string;
  person: any;

  constructor(e_person) 
  {
    this.setInitial(e_person);
    this.setName(e_person);

    this.setisSelf(e_person);
    this.setSelfStatus(e_person);
    this.setStatus(e_person);
    this.setMail(e_person);
    this.setPerson(this.mail);
    this.setDisplayedSelfStatus(this.selfStatus);
  }

  async setPerson(mail)
  {
    this.person = null;
    let profil = await  UserService.getInstance().getUserByEmail(mail);
   
    if(!profil && ContactService.instance)
    {
      profil = await ContactService.instance.getProfileByMail(mail);
      this.setPhoto(profil?.['photos']?.[0]?.url);
    }

    if(profil)
    {
      this.person = new PersonGcontact(profil);
      this.setPhoto(this.person.getPhoto());
    }
  }

  getPerson()
  {
    return this.person;
  }

  getId()
  {
    return this.ldapId;
  }

  setisSelf(e_person)
  {
    this.isSelf = false;

    if (e_person.self)
    {
      this.isSelf = true;
    }
  }

  getIsSelf(): boolean 
  {
    return this.isSelf;

  }

  getDisplayedSelfStatus() 
  {
    return this.displayedSelfStatus;
  }

  setDisplayedSelfStatus(status)
  {
    if (status == 'needsAction') 
    {
      this.displayedSelfStatus = environment.stringsFile.allStatus;
    }
    if (status == 'accepted') 
    {
      this.displayedSelfStatus = environment.stringsFile.acceptStatus;
    }
    if (status == 'declined') 
    {
      this.displayedSelfStatus = environment.stringsFile.declineStatus;
    }
    if (status == 'tentative') 
    {
      this.displayedSelfStatus = environment.stringsFile.maybeStatus;
    }
  }

  setSelfStatus(e_person)
  {
    const status = '';

    if (this.getIsSelf()) 
    {
      this.selfStatus = e_person.responseStatus;
    }
  }

  setStatus(e_person) 
  {
    this.status = e_person.responseStatus;
  }

  getStatus() 
  {
    return this.status;
  }

  setSelfStatusString(status) 
  {
    this.selfStatus = status;
  }

  getSelfStatus() 
  {
    return this?.selfStatus;
  }

  getPhoto() 
  {
    return this.photoLink;
  }

  setPhotoByMail(mail)
  {
    ContactService.instance.getImageByMail(mail).then(photoLink => 
    {
      this.photoLink = photoLink != 'd' ? photoLink : null;
    });
  }

  setPhoto(photoLink) 
  {
    this.photoLink = photoLink;
  }

  setName(e_person) 
  {
    this.name = e_person.displayName ? e_person.displayName : '';
    if (e_person.self == true) 
    {
      this.name = localStorage.name;
    }
  }

  getName() 
  {
    return this.name;
  }

  setInitial(e_person) 
  {
    const temp = e_person.displayName ? e_person.displayName : e_person.email;

    this.initialLetter = temp.slice(0, 1).toUpperCase();
  }

  getInitial() 
  {
    return this.initialLetter;
  }

  setMail(e_person) 
  {
    this.mail = e_person.email ? e_person.email : '';
  }

  getMail() 
  {
    return this.mail;
  }
}
