import { Space } from "../../models/space/space.model";

export interface ISpaceGroup {
  typeOid : any,
  typeName : string ,
  title: string,
  spaces:Space[],
}

export class SpaceGroup implements ISpaceGroup
{
  title: string;
  kind : string;
  typeOid : any;
  typeName : string;

  spaces: Space[] = [];
  sorted = false;

  constructor(space? : Space, kind="Group")
  {
    this.kind = "("+kind+")";

    if(space)
    {
      this.typeOid = space.getDocTypeOid();
      this.typeName = space.getDocTypeName();
      this.title = space.getGroupName();
    }
    else
    {
      this.title = kind;
    }
  }

  getName() 
  {
    return this.title;
  }

  addSpace(space : Space, replace=false) : void
  {
    const spaces : Space[] = this.spaces;

    if(replace)
    {
      this.spaces  = this.spaces.filter((sp : Space) => sp.getOid() !== space.getOid());
    }

    this.spaces.push(space);
  }

  getSpaces()
  {
    if(!this.sorted)
    {
      this.sortSpaces();
    }

    return this.spaces;
  }

  length() 
  {
    return this.spaces.length;
  }

  sortSpaces() 
  {
    this.spaces.sort((a, b) =>
      a.getSortIndex() > b.getSortIndex() ? 1 : -1
    )
    this.sorted = true;
  }
}
