<aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="title">
    <aura-input-textarea name size="large" label="Comment" placeholder="Please right down why you don’t like this document">
    </aura-input-textarea>

    <div class="row justify-content-end mt-5">
        <div class="col-4">
            <button auraButton [full]="true" label="Send" type="primary"></button>
        </div>
    </div>
</aura-modal>
