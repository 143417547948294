import { AddDocComponent } from './add-doc/add-doc.component';
import { AddDoctypeComponent } from './add-doctype/add-doctype.component';
import { AddFolderComponent } from './add-folder/add-folder.component';
import { GoogleAppsComponent } from './google-apps/google-apps.component';
import { NotifBarComponent } from './notif-bar/notif-bar.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SettingsComponent } from './settings/settings.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserBarComponent } from './user-bar/user-bar.component';


export const containers = [
  AddDocComponent,
  AddDoctypeComponent,
  AddFolderComponent,
  GoogleAppsComponent,
  NotifBarComponent,
  SearchBarComponent,
  SettingsComponent,
  SidenavComponent,
  ToolbarComponent,
  UserBarComponent,
];

export const entryComponents = [
  AddDocComponent,
  AddDoctypeComponent,
  AddFolderComponent,
]

export * from './add-doc/add-doc.component';
export * from './add-doctype/add-doctype.component';
export * from './add-folder/add-folder.component';
export * from './google-apps/google-apps.component';
export * from './notif-bar/notif-bar.component';
export * from './search-bar/search-bar.component';
export * from './settings/settings.component';
export * from './sidenav/sidenav.component';
export * from './toolbar/toolbar.component';
export * from './user-bar/user-bar.component';


