import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IEvent } from '../../models';
import { ShortNamePipe } from 'src/modules/explorer/pipes/short-name.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SidepanelService } from '../../containers/document-page/side-panel/sidepanel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-flagging-status',
  standalone: true,
  imports: [CommonModule,
    MatTooltipModule,
    TranslateModule],
  templateUrl: './flagging-status.component.html',
  styleUrls: ['./flagging-status.component.scss']
})
export class FlaggingStatusComponent 
{

  @Output() delete = new EventEmitter<any>();
    
  event: IEvent;

  TEXT = {
    flag: 'flagged',
    verify: 'verified',
    null: ''

  }

  RESPONSE_MAP = {
    //verify
    //1
    'Information is well presented/respect templates': 'Information is well presented/respect templates.',
    'Information is up-to-date': 'Information is up-to-date.',
    'Information is accurate and correct': 'Information is accurate and correct.',
    //2
    "Information is well presented/respect templatesInformation is up-to-date": "info is well presented & up-to-date.",
    "Information is well presented/respect templatesInformation is accurate and correct": "info is well presented & accurate.",
    "Information is up-to-dateInformation is accurate and correct": "info is up-to-date & accurate.",
    //3
    "verify": "info is well presented, up-to-date & accurate.",


    //flag
    'Information is not well presented/do not respect templates': "Information is not well presented/do not respect templates.",
    "Information is outdated/needs to be refreshed": "Information is outdated/needs to be refreshed.",
    'Information is inaccurate or incorrect': 'Information is inaccurate or incorrect.',
    //2
    "Information is not well presented/do not respect templatesInformation is outdated/needs to be refreshed": "info is not well presented & outdated.",
    "Information is not well presented/do not respect templatesInformation is inaccurate or incorrect": "info is not well presented & inaccurate.",
    "Information is outdated/needs to be refreshedInformation is inaccurate or incorrect": "info is outdated & inaccurate.",
    //3
    "flag": "info is not well presented, outdated & inaccurate.",
  }
  constructor(
    private shortNamePipe: ShortNamePipe,
    private datePipe: DatePipe,
    private sidepanelService: SidepanelService,
  ) 
  {
    this.sidepanelService.lastFlaggingEvent$.subscribe((value: IEvent) => 
    {
      this.event = value;
    })
  }


  tooltipText() 
  {
    if (!this.event) 
    {
      return;
    }
    if (!this.event.options.length) 
    {
      return;
    }

    const prefix =  environment.stringsFile[this.TEXT[this.event.type]] + ' ' + environment.stringsFile.fllagedBy + ' ' + this.shortNamePipe.transform(this.event?.user?.firstName || '', this.event?.user?.lastName || '') + '. ' + `(${this.datePipe.transform(this.event.date)}): `;

    const lengthOfOptions = this.event.options.length;
    
    if (lengthOfOptions == 1) 
    {
      return prefix + this.event.options.map(e => e.option).join('');
    }
    else 
    {
      return prefix + this.event.options[lengthOfOptions - 1].option;
    }

  }  
  
  deleteItem()
  {
    this.delete.emit();
  }


}