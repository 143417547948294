<div>
    <div class="dialog-header-div">
        <h1>{{'verifyDocuments' | translate}}</h1>
        <mat-icon class="dialog-close-icon" (click)="closeModal()">close</mat-icon>
    </div>
    <p>{{'verifyAllModalWarningMessage' | translate}}</p>
    <div class="dialog-button-div">
        <button mat-stroked-button (click)="closeModal()">{{'cancelBtn' | translate}}</button>
        <button mat-flat-button class="approval-button" (click)="approveAllDocumentsFunction()">{{'verifyAllModalButton'| translate}}</button>
    </div>
</div>