<div class="container">
    <div>
        <h1>No file selected.</h1>
    </div>
    <div>
        <p>Click on a file to display all its main properties <br /> and collaborative features such as ratings and
            <br /> approbation
            flow.
        </p>
    </div>
</div>