import { environment } from "src/environments/environment";

const gmailSearch = environment.gmailSearchLink
const contactLink = environment.contactLink
const DOTS_ENABLED = environment.DOTS_ENABLED


export class GcontactPerson 
{
  mailAddresses = [];
  primaryMail;
  photo;
  name;
  resourceName;
  contactId;
  constructor(item)
  {
    this.setMailAddresses(item);
    this.setMail(item);
    this.setName(item);
    this.setPhoto(item);
    this.setResourceName(item);
    this.getDisplayProperties();
  }

  displayProperties;
    
  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties =  [
      {
        id: 'name',
        value: this.getName(),
        iconImg: this.getPhoto(),
        onClick: function () 
        {
          window.open(contactLink+'person'+self?.resourceName.substring(self?.resourceName.indexOf('/')), '_blank');

        }
      },
      {
        id: 'mail',
        value: this.getMail(),
      }

    ]


    if (DOTS_ENABLED) {

      this.displayProperties.push({
        id: 'Menu',
        value: '',
        menuItems: [
          {
            label: 'Exchanged emails',
            callback: function () 
            {
              window.open(gmailSearch+self.primaryMail, '_blank');

            },
          }
          ,
          {
            label: 'Contact',
            callback: function () 
            {
              window.open(contactLink+'person'+self?.resourceName.substring(self?.resourceName.indexOf('/')), '_blank');

            },
                    
          },
                   
                   
        ],
      })
    }



    return this.displayProperties;
  }
  setMailAddresses(item)
  {
    this.mailAddresses = (item?.emailAddresses || []).map(element=>element.value)
  }
    
  setMail(item)
  {
    this.primaryMail = (item?.emailAddresses || []).find(element=>element.metadata?.primary==true || element.metadata?.sourcePrimary==true)?.value
  }

  setName(item)
  {
    this.name = (item?.names.length!=0)? item?.names[0]?.displayName : null;
  }

  setPhoto(item)
  {
    this.photo = (item?.photos?.length!=0)? item?.photos[0]?.url : null;
  }

  setResourceName(item)
  {
    this.resourceName = item?.resourceName;
  }

  setContactId(item)
  {
  }

  getMailAddresses()
  {
    return this.mailAddresses;
  }

  getMail()
  {
    return this.primaryMail; 
  }

  getName()
  {
    return this.name;
  }

  getPhoto()
  {
    return this.photo;
  }

  getResourcename()
  {
    return this.resourceName;
  }

  getContactId()
  {
    return this.contactId;
  }
}