import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OauthService, User } from '../../../auth/services/oauth.service';
import { AuthState } from '../../../auth/services/oauth.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { DebugLog } from 'src/modules/app-common/services/log/log.service';
const debug = DebugLog.build("Auth","yellow,black")

const BACKEND_URL = environment.BACKEND_URL;

// import window document, so that we can push events (pub/sub)
// import { DOCUMENT } from '@angular/common';
declare const document: any;

@Injectable({
  providedIn: 'root'
})
/** Manages the authentication :
 * - main application session based on a JWT,
 * - google oAuth token (injected).
 * - login/logout
 * - update JWT and Profile observables used by API services and user photo.
 */
export class AuthService
{
  /** JWT subject */
  jwtStateSubject: BehaviorSubject<AuthState | null>;

  /** google profile subject */
  profileSubject: BehaviorSubject<Profile | null>;

  /** localstorage jwt key */
  jwtkey = 'jwt'

  /** localstorage jwt issue time key */
  jwtIssKey = 'jwt-iss'


  constructor(private oauthService: OauthService,
    private httpClient: HttpClient,
    private router: Router
  )
  {
    /** init jwt/profil subjects */
    this.jwtStateSubject = new BehaviorSubject<AuthState | null>(null);
    this.profileSubject = new BehaviorSubject<Profile | null>(null);

    // load JWT from localstore and triggers event on JWT
    this.loadJWTFromLocalStorage();

    // update JWT when google token is refreshed
    this.updateAuthInfosOnTokenRefresh();
  }

  /**
   * Update JWT + profile when oAuth token is refreshed:
   * - only if access token is provided,
   * - request new JWT from backend
   * -
   */
  updateAuthInfosOnTokenRefresh()
  {
   // Subscribe to changes in OauthService's stateSubject
   this.oauthService.tokenSubject.pipe(

    tap(accessToken =>
    {
      debug.log('Gtoken : ', accessToken)
    }),

    // check token ok
    filter(accessToken => !!accessToken),

    // get new JWT
    switchMap(() =>
    {
      // update JWT in observable
      return this.updateJwt()
    }),

    // store jwt in localstorage
    tap(() =>
    {
      this.setJWTToLocalStorage();

      // publish login event with token and profile
      this.sendEvent("onLogin",{token:this.token, jwt:this.jwt});

      debug.log('JWT : ', this.jwt);
    }),

    // store user profile
    switchMap((response) =>
    {
      // update profile
      if (response && response.content && response.content.profile)
      {
        // from login request if provided
        this.updateProfileSubjectFromObj(response.content.profile)

        return of(null)
      }
      else
      {
        // or from google
        return this.updateProfileFromGoogleAPI()
      }
    }),

  ).subscribe();
  }

  refreshCurrentPage()
  {
    if (this.router.url.includes('/in/login'))
    {
      debug.log('refresh page called')
      window.location.reload();
    }
  }

  // publish event to listeners (builtin event pub/sub)
  sendEvent(eventName="onLogin",data={})
  {
    // Create a new custom event
    const customEvent = new CustomEvent(eventName,
      { detail: data }
    );

    // Dispatch the event on an element or the document
    document.dispatchEvent(customEvent);
  }

  /**
   * requests a new jwt :
   * -  use the user google token to authenticate (Authorization bearer) to the backend,
   * - stores the jwt in observable "authStateSubject"
   * - refresh the page
   *
   * returns an observable with the JWT string + user profile
   */
  updateJwt() : Observable<{content:{userJWT:string, profile:Profile}}>
  {
    const accessToken = this.token
    const userInfo = {}

    return this.httpClient.post<any>(BACKEND_URL + '/ged/login/google', userInfo,
    {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
    })
    .pipe(
      // get data from API
      map(res =>
        res && res.content &&
        res.content.userJWT
      ),

      // ignore if no jwt
      filter(jwt => !!jwt),

      // use jwt
      tap(
        jwt =>
        {
          // update the subject and notify observers of the JWT change
          this.jwtStateSubject.next(jwt);

          // refresh the page
          this.refreshCurrentPage();
        }
      ),
      catchError(err => {
        if(err.status == 401)
        {
          this.clearJWTLocalStorage();
          this.oauthService.refresh(true);
          this.router.navigate(['/in', 'login']);
        }
        return throwError(err);
      })
    )
  }

  /**
   * store user profile in observable subject (and triggers event)
   */
  updateProfileSubjectFromObj(profile)
  {
    this.profileSubject.next(profile);

    // publish login event with token and profile
    this.sendEvent("onProfile",{profile});
  }

  /** request user profile information:
   * - call google profile API with user token
   * - update profileSubject (=> update Observers)
   * - dispatch a DOM event "onProfile" with profile (used ?)
   */
  updateProfileFromGoogleAPI() : Observable<Profile>
  {
    const accessToken = this.token
    const userinfoUrl = 'https://openidconnect.googleapis.com/v1/userinfo';

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });

    return this.httpClient.get<Profile>(userinfoUrl, { headers })
      .pipe(
        // use profile
        tap((profile) =>
        {
          this.updateProfileSubjectFromObj(profile)
        })
      );
  }

  /** request a logout of oAuth google service,
   * cleanup current auth data (profile + JWT subject) and localstore.
   */
  logout()
  {
    this.oauthService.logout()
      .pipe(
        tap(() =>
        {
          this.resetState();
          this.clearJWTLocalStorage()
        }),
        take(1),
        tap(() =>
        {
          this.router.navigate(['/in', 'login'])
        })
      )
      .subscribe()
  }

  /**
   * clean jwt and time issue from localstorage
   */
  clearJWTLocalStorage()
  {
    localStorage.removeItem(this.jwtIssKey)
    localStorage.removeItem(this.jwtkey)
  }

  /** cleanup Observable subjects (set to null) + event */
  resetState()
  {
    this.profileSubject.next(null)
    this.jwtStateSubject.next(null)
  }

  /** get current JWT string */
  get jwt(): AuthState
  {
    return this.jwtStateSubject.value
  }

  /** get current google token */
  get token(): AuthState
  {
    return this.oauthService.token
  }

  /**
   * return google token as a Promise.
   * if token expired, renew it before.
   */
  getTokenAsync(): Promise<AuthState>
  {
    if (this.isExpired())
    {
      return this.oauthService.refresh()
        .pipe(map(refresh => refresh['access_token'])).toPromise()
    }

    return new Promise((resolve, reject) =>
    {
      resolve(this.oauthService.token);
    });
  }

  /** get google token as an observable.
   * refresh the token before if expired.
   */
  getTokenWithObservable():Observable<AuthState>
  {
    if (this.isExpired())
    {
      return this.oauthService.refresh()
        .pipe(map(refresh => refresh['access_token']));
    }

    return of(this.oauthService.token);
  }

  /** checks if google token is expired */
  isExpired()
  {
    return this.oauthService.isTokenExpired()
  }

  /** checks if the user has a JWT + google token in localstorage.
   *
   * NB. do not check the expiration of token here.
   */
  isConnected(): boolean
  {
    return !!this.isJWTInLocalstorage() && this.oauthService.isTokenInLocalstorage()
  }

  /** checks if jwt + time of issue are in local storage. */
  isJWTInLocalstorage()
  {
    const jwt = localStorage.getItem(this.jwtkey)
    const iss = localStorage.getItem(this.jwtIssKey)

    return !!jwt && !!iss
  }

  /** add jwt + now time in local storage */
  setJWTToLocalStorage()
  {
    localStorage.setItem(this.jwtkey, this.jwt)
    localStorage.setItem(this.jwtIssKey, new Date().getTime().toString())
  }

  /** load JWT from localstorage and trigger an event on jwt observable ("authStateSubject") */
  loadJWTFromLocalStorage()
  {
    const jwt = localStorage.getItem(this.jwtkey)

    if (jwt)
    {
      this.jwtStateSubject.next(jwt)
    }
  }

}

/** Google profile */
export class Profile
{
  email?: string
  email_verified?: boolean
  family_name?: string
  given_name?: string
  hd?: string
  locale?: string
  name?: string
  picture?: string
  sub?: string
}
