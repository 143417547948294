import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginComponent, ProfileComponent } from './containers';
import { OnlyGuestGuard, OnlyLoggedGuard } from './guards';

const routes: Routes = [

  {
    path: 'login', component: LoginComponent,
    data: {
      title: environment.stringsFile.loginPage
    },
    canActivate: [OnlyGuestGuard],
  },
  {
    path: 'profile', component: ProfileComponent, canActivate: [OnlyLoggedGuard],
    data: {
      title: environment.stringsFile.profilePage
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule 
{ }
