<div class="step" *ngIf="true || !isLoading">
    <div *ngFor="let task of tasks ;let i=index">
        <div class="doc-state layout-row" layout="row"
      style="border-radius: 9px;background-color: #fafafa;border-color: #f0f0f0;">
      <span>{{i + 1}}</span>
            <div class="state ng-binding flex" style="display: inline">
                <span class="" style="margin-right:5px">{{'Action' | translate}} : <strong>{{ task.data.fromStateLabel}} -> <span style="text-transform: uppercase;">{{task.data.actionLabel}}</span></strong> -> <strong>{{task.data.state}}</strong></span>
                <span class="" style="margin-right:5px">{{'By' | translate}} : <strong>{{ task.data.from_user?.html}}</strong></span>
                <span class="" style="margin-right:5px">{{'On' | translate}} : <strong>{{ getTime(task.revisionDesc)}}</strong></span>
            </div>

             <div style="display: inline; float: right"        
              (click)="revisionDrive(task.revisionDesc?.webViewLink)"><img src="{{task.revisionDesc?.iconLink}}"
              class="revision-img"> {{'Revision' | translate}} v{{task.revId}}
                <span></span>
             </div>
          </div>
    </div>
  </div>

