<mat-card class="meta-card" *ngIf="showMeta">
  <mat-card-header>
    <mat-card-title>Informations {{typeDoc}}</mat-card-title>
  </mat-card-header>
  
  <mat-card-content  *ngIf="showContent">
    <h3>{{doc?.description}}</h3>
    <div class="spinner-div">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
    
    <mat-list>
      <div *ngFor="let chipData of chipContent; let i = index">
        
        <mat-divider *ngIf="i!=0"></mat-divider>

        <mat-list-item class="listitem" role="listitem" (click)="showContent=!showContent"
        *ngIf="!!chipData.value && chipData.type!='date'">
          <span class="label">{{chipData.label}}</span>
          <span>{{chipData.value.html || chipData.value }}</span>
        </mat-list-item>

        <mat-list-item class="listitem" role="listitem" (click)="showContent=!showContent"
          *ngIf="!!chipData.value && chipData.type=='date'" >
          
            <span class="label">{{chipData.label}}</span>
            <span>{{chipData.value.html || chipData.value | date: 'dd/MM/yyyy'}}</span>
          
        </mat-list-item>
      </div>
    </mat-list>
  </mat-card-content>


  
  
  <mat-card-content *ngIf="!showContent">
    <form #f="ngForm">
      <div *ngFor="let chipData of chipContent; let i=index">
        <mat-form-field  appearance="fill"   style="display: block; text-justify: center;" >
          <mat-label>{{chipData.label}}</mat-label>

          <div *ngIf="!chipData.showAuto && chipData.type!=='date'">
            <input matInput type="{{chipData.type}}"  [(ngModel)]="formValues[i]" 
            name="{{chipData.meta}}" 
            >
          </div>
          
          <div *ngIf="chipData.showAuto">
            <input matInput type="{{chipData.type}}" [(ngModel)]="formValues[i].html" name="{{chipData.meta}}"
            [matAutocomplete]="auto" (ngModelChange)="doFilter(i)"
            #optionfield="ngModel">  
          
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of chipData.autoComplete" [value]="option.html">
                {{option.html}}
              </mat-option>
            </mat-autocomplete>
          </div>

          <div *ngIf="!chipData.showAuto && chipData.type==='date'" class="box">
            <input matInput  id="dateInput" [(ngModel)]="formValues[i]" name="{{chipData.meta}}" 
             (dateChange)="OnDateChange($event.value,i)"    
            [matDatepicker]="picker">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker  #picker></mat-datepicker>

          </div>
        </mat-form-field>
      </div>
      <div class="col-md-12 actions">
        <button mat-raised-button class="cancelBtn" (click)="showContent=!showContent">{{'cancelBtn' | translate | uppercase}}</button>
        <button mat-raised-button class="submitBtn" type="submit"
        (click)="onUpdateMeta(f.value)" [disabled]="!f.dirty">{{'saveBtn' | translate | uppercase}}</button>
      </div>

    </form>

  </mat-card-content> 
</mat-card>
