<!-- <div id="login" ng-controller="LoginCtrl" class="container">
  <div class="row justify-content-center">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>
          <img src="/assets/images/logo-wt.svg#light" class="logo">
        </mat-card-title>
      </mat-card-header>


      <mat-card-content>
        
        <br>
        <br>
        <br>
        <a [href]="loginURL">
        
          Login multi-client server
        </a>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->

<div class="login-container">

  <div class="login-card">
    <h2>
      Welcome back to PresenceDoc.
    </h2>
    <div>

      <p>
        Sign in to fully leverage your company's internal knowledge.
      </p>
      <a [href]="loginURL" id="login-btn">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.1 10.2271C20.1 9.518 20.0364 8.83619 19.9182 8.18164H10.5V12.0498H15.8818C15.65 13.2998 14.9455 14.3589 13.8864 15.068V17.5771H17.1182C19.0091 15.8362 20.1 13.2725 20.1 10.2271Z"
            fill="#4285F4" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.5 20.0004C13.2 20.0004 15.4637 19.1049 17.1182 17.5777L13.8864 15.0686C12.9909 15.6686 11.8455 16.0231 10.5 16.0231C7.89548 16.0231 5.69093 14.264 4.90457 11.9004H1.56366V14.4913C3.20911 17.7595 6.59093 20.0004 10.5 20.0004Z"
            fill="#34A853" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.90455 11.9007C4.70455 11.3007 4.59091 10.6598 4.59091 10.0007C4.59091 9.34158 4.70455 8.70067 4.90455 8.10067V5.50977H1.56364C0.886364 6.85977 0.5 8.38704 0.5 10.0007C0.5 11.6143 0.886364 13.1416 1.56364 14.4916L4.90455 11.9007Z"
            fill="#FBBC05" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.5 3.97727C11.9682 3.97727 13.2864 4.48182 14.3228 5.47273L17.1909 2.60455C15.4591 0.990909 13.1955 0 10.5 0C6.59093 0 3.20911 2.24091 1.56366 5.50909L4.90457 8.1C5.69093 5.73636 7.89548 3.97727 10.5 3.97727Z"
            fill="#EA4335" />
        </svg>
        <span id="login-text">

          Login with Google
        </span>
      </a>
    </div>
  </div>
  <div>
    New to PresenceDoc? <a href="">Join now</a>
  </div>

</div>
