import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APISce } from 'src/modules/app-common/services';


const BACKEND_URL = environment.BACKEND_URL;


@Injectable({
  providedIn: 'root'
})
export class SearchService 
{
  constructor(
    private api: APISce,
  ) 
  {
  }



  engineOptions: any = [
    { engine: 'gcontact2' },
    { engine: 'gcontact' },
    { engine: 'gpeople' },
    { engine: 'gcal' },
    { engine: 'gdrive' },
    { engine: 'ged' }
  ];

  async callSearch(url: string, data: {}) 
  {
    const options = this.api.httpConfig();

    return this.api.post(url, data, options)
  }

  async searchRes(keywords: string, fromFolderId: string = null) 
  {
    let url = `/search/query`;

    if (fromFolderId) 
    {
      url += `&folderId=${fromFolderId}`;
    }

    const data: any =
    {
      engineOptions: [],
      pageSize: 25,
      query: ''
    };

    data.engineOptions.push(...this.engineOptions);
    data.query = keywords;

    const response = await this.callSearch(url, data);

    const searchResults: any =
    {
      ged: [],
      gdrive: [],
      gcontact: [],
      gcontact2: [],
      gpeople: [],
      gcal: [],
      facets: [],
    };

    let facets;
    const ged = [];
    const gdrive = [];
    const gcontact = [];
    const gcontact2 = [];
    const gpeople = [];
    const gcal = [];

    if (response?.['content']?.['groups'].length > 0 && response?.['errors'] == undefined) 
    {
      const enginesRes = response?.['content']?.['groups'] || [];

      enginesRes.forEach((engineData) => 
      {
        const engineId = engineData?.['engine'];

        if (engineId == 'gdrive') 
        {
          //console.log('filterListes',item)
          const folders = engineData['items']?.['folders'] || [];
          const files = engineData['items']?.['files'] || [];
          const infos = engineData['infos'] || [];

          gdrive.push(...folders);
          gdrive.push(...files);
          facets = infos;

          searchResults.gdrive.push(...gdrive);
          searchResults.facets.push(facets);
        }
        else if (engineId == 'ged') 
        {
          //console.log('filterListes',item)
          const folders = engineData['items']?.['folders'] || [];
          const files = engineData['items']?.['files'] || [];
          const infos = engineData['infos'] || [];

          gdrive.push(...folders);
          gdrive.push(...files);
          facets = infos;

          searchResults.gdrive.push(...gdrive);
          searchResults.facets.push(facets);
        }
        else if (engineId == 'gcal') 
        {
          const events = engineData['items'] || [];

          gcal.push(...events)
          searchResults.gcal.push(...gcal);
        }
        else if (engineId == 'gcontact') 
        {
          const contacts = engineData['results'] || [];

          gcontact.push(...contacts)
          searchResults.gcontact.push(...gcontact);
        }
        else if (engineId == 'gcontact2') 
        {
          const contacts2 = engineData['results'] || [];

          gcontact2.push(...contacts2)
          searchResults.gcontact2.push(...gcontact2);
        }
        else if (engineId == 'gpeople') 
        {
          const peoples = engineData['people'] || [];

          gpeople.push(...peoples)
          searchResults.gpeople.push(...gpeople);
        }
      });
    }
    else if (response?.['errors']) 
    {
      response?.['errors'].forEach((engineError) => 
      {
        const engineId = engineError?.['engine'];

        if (engineId == 'gdrive' || engineId == 'gcal') 
        {
          console.warn("Search engine error => signout", engineId, engineError);
          // this.googleLoginService.signOut(); // to be seen after // ag
        }
      });
    }

    return searchResults;
  }
}
