import { DocumentService } from './document.service';
import { GedDocService } from './ged-doc.service';

export const services = [
  DocumentService,
  GedDocService
];

export * from './document.service';
export * from './ged-doc.service';

