import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedListItem } from 'src/design-system/core/interfaces/advanced-list-item';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { PeopleDialogComponent } from '../../components';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit 
{

  constructor(private dialog : MatDialog) 
  { }

  ngOnInit(): void 
  {
  }

  addNewContact(): void 
  {
    const dialogRef = this.dialog.open(PeopleDialogComponent, {
      width: '650px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => 
    {
    });
  }



  /*  meetings: AdvancedListItem[] = [
    {
       id: 1,
       title: 'Meeting With Client',
       text: '10:00 - 12:00',
       image: '/assets/images/icon-google-calendar.png',
       rightIcons: [
          {
             icon: '/assets/images/icon-external.svg',
             callback: function (meet: AdvancedListItem) {
                console.log(meet);
             },
             width: 24,
          },
          {
             icon: '/assets/images/icon-google-meet.png',
             callback: function (meet: AdvancedListItem) {
                console.log(meet);
             },
             width: 28,
          },
       ],
       users: [
          {
             id: 1,
             fname: 'johne',
             lname: 'snowden',
             image: '/assets/images/user-3.png',
             isParticipating: true,
          },
          {
             id: 2,
             fname: 'sarah',
             lname: 'morgan',
             image: '/assets/images/user-2.png',
             isParticipating: true,
          },
          {
             id: 3,
             fname: 'Hajar',
             lname: 'snowden',
             image: '/assets/images/user-1.png',
             isParticipating: false,
          },
          {
             id: 4,
             fname: 'Abdel',
             lname: 'Amerda',
             image: '/assets/images/user-2.png',
             isParticipating: false,
          },
       ],
    },
    {
       id: 2,
       title: 'Meeting With Client',
       text: '10:00 - 12:00',
       image: '/assets/images/icon-google-calendar.png',
       rightIcons: [
          {
             icon: '/assets/images/icon-external.svg',
             callback: function (meet: AdvancedListItem) {
                console.log(meet);
             },
             width: 24,
          },
          {
             icon: '/assets/images/icon-google-meet.png',
             callback: function (meet: AdvancedListItem) {
                console.log(meet);
             },
             width: 28,
          },
       ],
       users: [
          {
             id: 1,
             fname: 'johne',
             lname: 'snowden',
             image: '/assets/images/user-1.png',
             isParticipating: true,
          },
          {
             id: 2,
             fname: 'sarah',
             lname: 'morgan',
             image: '/assets/images/user-2.png',
          },
          {
             id: 3,
             fname: 'Hajar',
             lname: 'snowden',
             image: '/assets/images/user-3.png',
             isParticipating: false,
          },
       ],
    },
    {
       id: 3,
       title: 'Meeting With Client',
       text: '10:00 - 12:00',
       image: '/assets/images/icon-google-calendar.png',
       rightIcons: [
          {
             icon: '/assets/images/icon-external.svg',
             callback: function (meet: AdvancedListItem) {
                console.log(meet);
             },
             width: 24,
          },
          {
             icon: '/assets/images/icon-google-meet.png',
             callback: function (meet: AdvancedListItem) {
                console.log(meet);
             },
             width: 28,
          },
       ],

       users: [
          {
             id: 1,
             fname: 'johne',
             lname: 'snowden',
             image: '/assets/images/user-1.png',
             isParticipating: true,
          },
          {
             id: 2,
             fname: 'Sabir',
             lname: 'morgan',
             image: '/assets/images/user-2.png',
             isParticipating: false,
          },
          {
             id: 3,
             fname: 'Ghazdali',
             lname: 'morgan',
             image: '/assets/images/user-3.png',
             isParticipating: false,
          },
          {
             id: 4,
             fname: 'Basiri',
             lname: 'morgan',
             image: '/assets/images/user-1.png',
             isParticipating: false,
          },
          {
             id: 5,
             fname: 'Bentaoui',
             lname: 'morgan',
             image: '/assets/images/user-3.png',
             isParticipating: false,
          },
          {
             id: 6,
             fname: 'Simo',
             lname: 'morgan',
             image: '/assets/images/user-2.png',
             isParticipating: false,
          },
       ],
    },
 ];
 */

  recentDocs: ListItem[] = [
    {
      id: 1,
      title: 'Document Name',
      text: 'Lorem ipsum',
      icon: 'bi bi-file-earmark-word',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Document Name pdf',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-pdf.svg',
      icon: 'bi bi-file-earmark-pdf',
      iconColor: '#e94748',
      menuItems: [
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Document Name pdf',
      text: 'Lorem ipsum',
      icon: 'bi bi-file-earmark-pdf',
      iconColor: '#e94748',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Excel Doc',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Excel Doc',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 6,
      title: 'Excel Doc',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 7,
      title: 'Document Name',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
  ];

}
