import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/design-system/core/interfaces/user';

@Component({
  selector: 'app-new-department',
  templateUrl: 'bootstrap/new-department.component.html',
  styleUrls: ['bootstrap/new-department.component.scss'],
})
export class NewDepartmentComponent implements OnInit 
{
   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();

   steps: {
      stepOrder: number;
      title: string;
   }[] = [
       {
         stepOrder: 1,
         title: 'Create New Department',
       },
       {
         stepOrder: 2,
         title: 'Choose Departments Icon and Color',
       },
       {
         stepOrder: 3,
         title: 'Set an admin For Marketing Department',
       },
       {
         stepOrder: 4,
         title: 'Share Department',
       },
     ];
   currentStep: number = 1;
   title: string = this.steps[this.currentStep - 1].title;

   // User lists

   selectedUsers: User[] = [];
   selectedUsers2: User[] = [];
   usersList: User[] = [
     {
       id: 1,
       fname: 'Garland',
       lname: 'Borde',
       image: '/assets/images/user-1.png',
     },
     {
       id: 2,
       fname: 'Dione ',
       lname: 'Samuel',
       image: '/assets/images/user-2.png',
     },
     {
       id: 3,
       fname: 'Jourdain ',
       lname: 'Dubois',
       image: '/assets/images/user-3.png',
     },
     {
       id: 4,
       fname: 'Bertrand',
       lname: 'Bret',
       image: '/assets/images/user-2.png',
     },
     {
       id: 5,
       fname: 'Amaline ',
       lname: 'Patenaude',
       image: '/assets/images/user-1.png',
     },
     {
       id: 6,
       fname: 'Patenaude',
       lname: 'Borde',
       image: '/assets/images/user-3.png',
     },
   ];

   constructor() 
   { }

   closeModal() 
   {
     this.close.emit();
     this.currentStep = 1;
   }
   nextStep() 
   {
     this.currentStep++;
     this.title = this.steps[this.currentStep - 1].title;
   }
   prevStep() 
   {
     this.currentStep--;
     this.title = this.steps[this.currentStep - 1].title;
   }
   onChange() 
   {
   }
   ngOnInit(): void 
   { }
}
