
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';

export const containers = [
  LoginComponent,
  ProfileComponent,
];
export const entryComponents = [
  ProfileComponent,

]
export * from './login/login.component';
export * from './profile/profile.component';
