export class Meta 
{
  meta;
  value;
  label;
  background;
  color;
  type;
  auto;
  autoValue;

  constructor(xtag,folder,meta) 
  {
    this.setMeta(xtag);
    this.setValue(folder);
    this.setLabel(meta);
    this.setBackGround();
    this.setColor();
    this.setType(meta);
    this.setAuto(meta);
    this.setAutoValue();

  }

  getMeta() 
  {
    return this.meta;
  }
  getValue() 
  {
    return this.value;
  }
  getLabel() 
  {
    return this.label;
  }  
  getBackGround() 
  {
    return this.background;
  }
  getColor()
  {
    return this.color;
  }
  getType()
  {
    return this.type;
  }
  getAuto()
  {
    return this.auto;

  }
  getAutoValue()
  {
    return this.autoValue;
  }




  ////setter


  setMeta(data) 
  {
    this.meta = data;
  }
  setValue(data) 
  {
    this.value= data;
  }
  setLabel(data) 
  {
    this.label= data['label']
  } 
  setBackGround() 
  {
    this.background ="white";
  }
  setColor()
  {
    this.color= "black";
  }
  setType(data)
  {
    this.type= data["type"];
  }
  setAuto(data)
  {
    this.auto= data['x-dynamic-values'] || null;

  }
  setAutoValue()
  {
    this.autoValue= {};
  }

}
