<div class="circle" [ngStyle]="{'background-color':  circleColor }">
    <img *ngIf="!showInitials" src="{{photoUrl}}">
    <div *ngIf="showInitials" class="initials">
        {{ initials }}
    </div>
    <span *ngIf="icone" class="verification-icon" [ngStyle]="{
        
        'color': classIcone.textColor
      }">
        <!-- Ajoutez ici l'icône de vérification, par exemple : -->
        <i class="{{icone}}"></i>
    </span>
</div>