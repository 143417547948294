import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AdvancedListItem } from 'src/design-system/core/interfaces/advanced-list-item';
import { initListItem, ListItem } from 'src/design-system/core/interfaces/list-item';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
  selector: 'app-dashboard',
  templateUrl: $theme + '/dashboard.component.html',
  styleUrls: [$theme + '/dashboard.component.scss'],
})
export class DashboardComponent implements OnInit 
{
  recentDocs: ListItem[] = [
    {
      id: 1,
      title: 'Document Name',
      text: 'Lorem ipsum',
      icon: 'bi bi-file-earmark-word',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Document Name pdf',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-pdf.svg',
      icon: 'bi bi-file-earmark-pdf',
      iconColor: '#e94748',
      menuItems: [
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Document Name pdf',
      text: 'Lorem ipsum',
      icon: 'bi bi-file-earmark-pdf',
      iconColor: '#e94748',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Excel Doc',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Excel Doc',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 6,
      title: 'Excel Doc',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 7,
      title: 'Document Name',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-excel.svg',
      icon: 'bi-file-earmark-spreadsheet-fill',
      iconColor: '#11b56a',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
  ];
  departments: ListItem[] = [
    {
      id: 1,
      title: 'Departments',
      text: 'Lorem department text',
      image: '/assets/images/icon-building.svg',
      imageHeight: 28,
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Departments',
      text: 'Lorem department text',
      image: '/assets/images/icon-building.svg',
      imageHeight: 28,
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Departments',
      text: 'Lorem department text',
      image: '/assets/images/icon-building.svg',
      imageHeight: 28,
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
  ];
  meetings: AdvancedListItem[] = [
    {
      id: 1,
      title: 'Meeting With Client',
      text: '10:00 - 12:00',
      image: '/assets/images/icon-google-calendar.png',
      rightIcons: [
        {
          icon: '/assets/images/icon-external.svg',
          callback: function (meet: AdvancedListItem) 
          {
            console.log(meet);
          },
          width: 24,
        },
        {
          icon: '/assets/images/icon-google-meet.png',
          callback: function (meet: AdvancedListItem) 
          {
            console.log(meet);
          },
          width: 28,
        },
      ],
      users: [
        {
          id: 1,
          fname: 'johne',
          lname: 'snowden',
          image: '/assets/images/user-3.png',
          isParticipating: true,
        },
        {
          id: 2,
          fname: 'sarah',
          lname: 'morgan',
          image: '/assets/images/user-2.png',
          isParticipating: true,
        },
        {
          id: 3,
          fname: 'Hajar',
          lname: 'snowden',
          image: '/assets/images/user-1.png',
          isParticipating: false,
        },
        {
          id: 4,
          fname: 'Abdel',
          lname: 'Amerda',
          image: '/assets/images/user-2.png',
          isParticipating: false,
        },
      ],
    },
    {
      id: 2,
      title: 'Meeting With Client',
      text: '10:00 - 12:00',
      image: '/assets/images/icon-google-calendar.png',
      rightIcons: [
        {
          icon: '/assets/images/icon-external.svg',
          callback: function (meet: AdvancedListItem) 
          {
            console.log(meet);
          },
          width: 24,
        },
        {
          icon: '/assets/images/icon-google-meet.png',
          callback: function (meet: AdvancedListItem) 
          {
            console.log(meet);
          },
          width: 28,
        },
      ],
      users: [
        {
          id: 1,
          fname: 'johne',
          lname: 'snowden',
          image: '/assets/images/user-1.png',
          isParticipating: true,
        },
        {
          id: 2,
          fname: 'sarah',
          lname: 'morgan',
          image: '/assets/images/user-2.png',
        },
        {
          id: 3,
          fname: 'Hajar',
          lname: 'snowden',
          image: '/assets/images/user-3.png',
          isParticipating: false,
        },
      ],
    },
    {
      id: 3,
      title: 'Meeting With Client',
      text: '10:00 - 12:00',
      image: '/assets/images/icon-google-calendar.png',
      rightIcons: [
        {
          icon: '/assets/images/icon-external.svg',
          callback: function (meet: AdvancedListItem) 
          {
            console.log(meet);
          },
          width: 24,
        },
        {
          icon: '/assets/images/icon-google-meet.png',
          callback: function (meet: AdvancedListItem) 
          {
            console.log(meet);
          },
          width: 28,
        },
      ],

      users: [
        {
          id: 1,
          fname: 'johne',
          lname: 'snowden',
          image: '/assets/images/user-1.png',
          isParticipating: true,
        },
        {
          id: 2,
          fname: 'Sabir',
          lname: 'morgan',
          image: '/assets/images/user-2.png',
          isParticipating: false,
        },
        {
          id: 3,
          fname: 'Ghazdali',
          lname: 'morgan',
          image: '/assets/images/user-3.png',
          isParticipating: false,
        },
        {
          id: 4,
          fname: 'Basiri',
          lname: 'morgan',
          image: '/assets/images/user-1.png',
          isParticipating: false,
        },
        {
          id: 5,
          fname: 'Bentaoui',
          lname: 'morgan',
          image: '/assets/images/user-3.png',
          isParticipating: false,
        },
        {
          id: 6,
          fname: 'Simo',
          lname: 'morgan',
          image: '/assets/images/user-2.png',
          isParticipating: false,
        },
      ],
    },
  ];
  emails: ListItem[] = [
    initListItem({
      id: 1,
      title: 'Security Alert',
      subtitle: 'Abdel ame',
      text: 'lorem ipsum dollar text ipsum',
      rightText: '04:30PM',
      image: '/assets/images/user-1.png',
    }),
    initListItem({
      id: 2,
      title: 'Security Alert',
      subtitle: 'Abdel ame',
      text: 'lorem ipsum dollar text ipsum',
      rightText: '04:30PM',
      image: '/assets/images/user-1.png',
    }),
    initListItem({
      id: 3,
      title: 'Security Alert',
      subtitle: 'Abdel ame',
      text: 'lorem ipsum dollar text ipsum',
      rightText: '04:30PM',
      image: '/assets/images/user-1.png',
    }),
    initListItem({
      id: 4,
      title: 'Security Alert',
      subtitle: 'Abdel ame',
      text: 'lorem ipsum dollar text ipsum',
      rightText: '04:30PM',
      image: '/assets/images/user-1.png',
    }),
    initListItem({
      id: 5,
      title: 'Security Alert',
      subtitle: 'Abdel ame',
      text: 'lorem ipsum dollar text ipsum',
      rightText: '04:30PM',
      image: '/assets/images/user-1.png',
    }),
  ];

  constructor(private titleService: Title) 
  {
    this.titleService.setTitle('Dashboard');
  }

  ngOnInit(): void 
  {}
}
