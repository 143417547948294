import { Pipe, PipeTransform } from '@angular/core';

import { ITableColumn } from './table.interface';
import { TTableColumn } from './table.type';

@Pipe({
  name: '_tableClass',
  standalone: true,
})
export class TableClass implements PipeTransform 
{
  transform(value: ITableColumn[], id: string): string 
  {
    return value.find((column: ITableColumn) => column.id === id)?.class ?? '';
  }
}
