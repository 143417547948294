import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
const databaseOffset = -2

@Injectable({
  providedIn: 'root'
})
export class DateService
{

  constructor(private datepipe: DatePipe) 
  {
    DateService.instance = this;
  }

  public static instance: DateService

  public transformTime(d)
  {
    const date = d;
    const hours = parseInt(this.datepipe.transform(date,'Z'))/100

    date.setHours(date.getHours()-hours)
    date.setHours(date.getHours()-databaseOffset)

    return date;
    
  }
}
