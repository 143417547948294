<mat-sidenav-container class="app-container">
    <mat-sidenav class="app-sidenav sideDoc" id="sidenav" #sidenav mode="side" opened
     [class.menu-open]="sideNavState" [class.menu-close]="!sideNavState">
            <mat-nav-list class="sideItems">

                
                <mat-list-item (click)="PrevPage()" >
                    <button mat-icon-button class="menu-button" matTooltip="{{'previousPage' | translate}}" matTooltipPosition="below">
                    <i class="material-icons-round menu-icon">arrow_back</i>
                    </button>
                    <span *ngIf="linkText">{{'previousPage' | translate}}</span>

                </mat-list-item>  
                <mat-list-item (click)="backToSpace()" *ngIf="docSpace">
                    <button mat-icon-button class="menu-button" matTooltip="{{'returSpace' | translate}}" matTooltipPosition="below">
                        <mat-icon style="color: white">  drive_file_move_rtl</mat-icon> 
                    </button>
                    <span *ngIf="linkText">{{'returSpace' | translate}}</span>

                </mat-list-item> 
                <mat-list-item  >
                    <button mat-icon-button class="menu-button" matTooltip="{{'homepageTT' | translate}}" matTooltipPosition="below"  (click)="toHomepage()">
                    <i class="material-icons-round menu-icon">home</i>
                    </button>
                    <span *ngIf="linkText">{{'homepageTT' | translate}}</span>

                </mat-list-item>


                <mat-list-item routerLink='/t/all'>
                    <button mat-icon-button class="menu-button" matTooltip="{{'taskPage' | translate}}" matTooltipPosition="below">
                    <i class="material-icons-round menu-icon">task</i>
                    </button>
                    <span *ngIf="linkText">{{'taskPage' | translate}}</span>

                </mat-list-item>

                <mat-list-item  (click)="backClicked()">
                    <button mat-icon-button class="menu-button" matTooltip="{{'parentFolderTT' | translate}}" matTooltipPosition="below">
                    <i class="material-icons-round menu-icon">folder</i>
                    </button>
                    <span *ngIf="linkText">{{'returnBtn' | translate}}</span>

                </mat-list-item>



                <mat-list-item>

                    <button mat-icon-button class="menu-button" routerLink="{{driveurl}}"
                            matTooltip="{{'drivePage' | translate}}" matTooltipPosition="below">
                      <mat-icon class="driveIcon" svgIcon="mydrive-white" aria-hidden="false"></mat-icon>

                    </button>
                  <span *ngIf="linkText">{{'drivePage' | translate}}</span>

                </mat-list-item>

                <mat-list-item>
                    <button mat-icon-button class="menu-button" (click)="openInDrive()"
                    matTooltip="{{'openDocDriveTT' | translate}}" matTooltipPosition="below" >
                    <i class="material-icons-round menu-icon">open_in_new</i>
                    </button>
                    <span *ngIf="linkText">{{'openDocDriveTT' | translate}}</span>

                </mat-list-item>

             
                <mat-list-item  *ngIf="!docInfo && inGed">
                    <button mat-icon-button class="menu-button" (click)="openDocInfo()"
                    matTooltip="{{'openMainInfo' | translate}}" matTooltipPosition="below">
                    <i class="material-icons-round menu-icon">info</i>
                    </button>
                    <span *ngIf="linkText && docInfo">{{'openMainInfo' | translate}}</span>
                </mat-list-item>
                <mat-list-item *ngIf="docInfo && inGed">
               

                <button mat-icon-button class="menu-button" (click)="closeDocInfo()" 
                        matTooltip="{{'openMainInfo' | translate}}" matTooltipPosition="below">
                  <i class="material-icons-round menu-icon"> keyboard_double_arrow_right </i>
                </button>
                <span *ngIf="linkText && !docInfo" >close</span>

              </mat-list-item>


                <mat-list-item *ngIf="!isToggled && !docInfo">
                    <button mat-icon-button class="menu-button" (click)="toggleGedBar()"
                    matTooltip="{{'fullscreenTT' | translate}}" matTooltipPosition="below">
                    <i class="material-icons-round menu-icon">fullscreen</i>
                    </button>
                    <span *ngIf="linkText">{{'fullscreenTT' | translate}}</span>

                </mat-list-item>
                <mat-list-item *ngIf="isToggled && !docInfo" >
                    <button mat-icon-button class="menu-button" (click)="openGedBar()"
                    matTooltip="{{'docInfoTT' | translate}}" matTooltipPosition="below">
                    <i class="material-icons-round menu-icon">fullscreen_exit</i>
                    </button>
                    <span *ngIf="linkText">{{'docInfoTT' | translate}}</span>

                </mat-list-item>

                <mat-list-item *ngIf="!docInfo">

                    <button mat-icon-button class="menu-button"
                    [matMenuTriggerFor]="doc_options" matTooltip="{{'deleteDocTT' | translate}}" matTooltipPosition="after">
                    <i class="material-icons-outlined menu-icon">delete</i>
                    </button>

                    <mat-menu #doc_options="matMenu" xPosition="after">

                        <button mat-menu-item class="newElementBtn" *ngIf="inGed" (click)="detachFromGed()">
                            <i class="material-icons-round optionIcon">clear</i>
                            {{'detachDoc' | translate}}
                        </button>
                        <button mat-menu-item class="newElementBtn" (click)="deleteDoc()">
                          <i class="material-icons-round optionIcon">delete</i>
                          {{'deleteDocTT' | translate}}
                      </button>


                    </mat-menu>
                </mat-list-item>

            </mat-nav-list>


    </mat-sidenav>

    <!--END OF SIDENAV MENU-->
    <mat-sidenav-content [ngStyle]="{'margin-left.px' : contentMargin}">
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
