<div class="h-100 d-flex flex-column">
    <div class="">
        <div class="row mb-3">
            <div class="col">
                <img src="/assets/images/icon-left-arrow-circle.svg" width="33" (click)="goback()" />
                <span class="align-middle d-inline-block ms-2 primary-color fw-bold">Retour</span>
            </div>
        </div>
        <form class="row">
            <div class="col pe-0 me-0">
                <aura-input-text id="search" className="shadow-sm border-0" size="large"></aura-input-text>
            </div>
            <div class="col-4 col-md-2 me-0">
                <button auraButton size="large" type="primary" [full]="true" label="Search"></button>
            </div>
        </form>
        <div class="row py-3">
            <div class="col icons d-flex justify-content-end gap-2 view-icons">
                <button auraButton size="large" type="light" icon="gg-user-add" [full]="true" label="Share"></button>
                &nbsp;&nbsp;
                <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                    (click)="toggleView('table')">
                &nbsp;&nbsp;
                <img [src]="'/assets/images/view-cols.svg#' + (activeTab == 'grid' ? 'active' : '')"
                    (click)="toggleView('grid')">
            </div>
        </div>
        <div class="grid">
            <aura-list [hasMenu]="true" [columns]="activeTab == 'grid' ? 4 : 1" verticalAlign="center"
                [data]="departments"></aura-list>
        </div>
    </div>
    <div class="row mt-auto mb-0">
        <div class="col text-right ">
            <button (click)="open()" class="btn btn-xl btn-primary mt-4 d-sm-inline-block d-block m-auto">+ Add New
                Space</button>
            <app-new-space [isOpen]="isOpen" (close)="close()"></app-new-space>
        </div>
    </div>
</div>
