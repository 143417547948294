import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OnlyLoggedGuard } from "../modules/user/guards";
import { defer } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { SearchExtensionComponent } from "src/modules/app-common/components/search-extension/search-extension.component";

const routes: Routes = [
  {
    path: "in",
    loadChildren: () =>
      import("../modules/user/user-routing.module").then(
        (m) => m.UserRoutingModule
      ),
  },
  {
    path: "s",
    loadChildren: () =>
      import("../modules/spaces/spaces-routing.module").then(
        (m) => m.SpacesRoutingModule
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "file",
    loadChildren: () =>
      import("../modules/documents/documents-routing.module").then(
        (m) => m.DocumentsRoutingModule
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "people",
    loadChildren: () =>
      import("../modules/people/people-routing.module").then(
        (m) => m.PeopleRoutingModule
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "generic",
    loadChildren: () =>
      import("../modules/search/search-routing.module").then(
        (m) => m.SearchRoutingModule
      ),
    canActivate: [OnlyLoggedGuard],
  },

  {
    path: "workspace/main",
    redirectTo: "explorer/drive/foundational",
    pathMatch: "full",
  },
  {
    path: "crud-component",
    loadChildren: () =>
      import(
        "../modules/crud/crud-component/crud-component-routing.module"
      ).then((m) => m.GenericPageRoutingModule),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "metatable/:sid/:tid/:url",
    loadChildren: () =>
      import("../modules/metatable/metatable-routing.module").then(
        (m) => m.MetatableRoutingModule
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "err",
    loadChildren: () =>
      import("../modules/errors/errors-routing.module").then(
        (m) => m.ErrorsRoutingModule
      ),
  },
  {
    path: "sidepanel",
    loadChildren: () =>
      defer(() => import('../modules/workspace/workspace-routing.module')).pipe(
        map((m) => 
        {
          return m.WorkspaceRoutingModule
        }),
        retry(2)
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "search/extension/contribs-drive",
    component: SearchExtensionComponent
  },
  {
    path: "",
    // redirectTo: "in/login",
    redirectTo: "explorer/drive/foundational",
    pathMatch: "full",
  },
  {
    path: "",
    loadChildren: () =>
      defer(() => import('../modules/explorer/explorer.module')).pipe(
        map((m) => m.ExplorerModule),
        retry(2)
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "explorer",
    loadChildren: () =>
      defer(() => import('../modules/explorer/explorer.module')).pipe(
        map((m) => m.ExplorerModule),
        retry(2)
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "t",
    loadChildren: () =>
      defer(() => import('../modules/explorer/explorer.module')).pipe(
        map((m) => m.ExplorerModule),
        retry(2)
      ),
    canActivate: [OnlyLoggedGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      defer(() => import('../modules/auth/auth.module')).pipe(
        map((m) => m.AuthModule),
        retry(2)
      ),
  },
  {
    path: "**",
    redirectTo: "err/notfound",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule 
{ }
