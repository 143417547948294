// import { TranslateService } from "@ngx-translate/core"
import {SearchResultItem} from "./search-engine-result"

export class SearchEngine
{
  engine;
  params;
  groups;
  results : SearchResultItem[] = [];
  engineOptions;

  constructor()
  {
    this.setEngine();
    this.setEngineOptions();
  }

  setEngine()
  {
  }

  setResults(engineResponse,prefixUrl='', keyWord?, isExtension?)
  {
    this.getResults(engineResponse)
  }

  setFacets(engineResponse)
  {
  }

  getResults(globalResults)
  {
    const rawEngineResults = globalResults.find(resultsGroup=>resultsGroup.engine == this.engine) || null;
    const formattedResults = this.formatResults(rawEngineResults);

    return formattedResults;
  }

  formatResults(rawEngineResults)
  {
    // let resultsByType = rawEngineResults.map(r=>new )
  }

  getEngine()
  {
    return this.engine;
  }

  getEngineOptions() 
  {
    return this.engineOptions;
  }

  setEngineOptions()
  {
    this.engineOptions = {engine: this.getEngine()}
  }
}
