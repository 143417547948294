
  <aura-modal  [isOpen]="isOpen" (close)="closeModal()"     title="{{eventItem?.getTitle()}}">
     <div   style=" width: 100%; display: inline-block;padding-bottom: 8px; align-items: center;">
      <h1 class="title-div">
          <!-- <span class="secondaryButton" mat-flat-button>
          <mat-icon  class="detail-icon">event</mat-icon>
        </span>
     <span class="event-title">{{eventItem?.getTitle()}}</span> -->
      </h1>
      <mat-drawer-container class="example-container" style="height: fit-content; min-height: 225px; background-color: white!important;;">
        <ng-container class="left-side">

          <div class="call-div">
            <span class="secondaryButton" mat-flat-button>
              <mat-icon class="detail-icon">schedule</mat-icon>
            </span>
            <span matLine class="detail-txt">{{eventItem?.getDate()}}</span>


          </div>

          <div class="call-div">
            <span class="secondaryButton" mat-flat-button>
              <mat-icon class="detail-icon">notes</mat-icon>
            </span>
            <span matLine class="detail-txt" *ngIf="eventItem?.getDesc()!=null">{{eventItem?.getDesc()}}</span>
            <span matLine class="detail-txt desc" *ngIf="eventItem?.getDesc()==null">no description</span>


          </div>

          <div class="event-links-div">
            <div *ngIf="eventItem?.getHangoutLink()!=undefined && eventItem?.getHangoutLink()!=null" class="call-div"
            (click)="toLink(eventItem?.getHangoutLink())">
              <span class="secondaryButton" mat-flat-button>
                <mat-icon class="detail-icon" (click)="toLink(eventItem?.getHangoutLink())" matTooltip="{{'rejoindre' | translate}}" >videocam</mat-icon>
              </span>
              <span class="detail-txt">{{'gMeetLinkTxt' | translate}}</span>

            </div>
            <div *ngIf="eventItem?.getHtmlLink()!=undefined && eventItem?.getHtmlLink()!=null" class="call-div" (click)="toLink(eventItem?.getHtmlLink())">
              <span class="secondaryButton"  mat-flat-button>
                <mat-icon class="detail-icon" aria-hidden="false" matTooltip="{{'openEvent' | translate}}" >open_in_new</mat-icon>
              </span>
              <span  class="detail-txt">{{'gCalendarLinkTxt' | translate}}</span>

            </div>

           <!--  <div class="call-div" (click)="addMeetingNote()">
              <span class="secondaryButton" mat-flat-button>
                <mat-icon class="detail-icon">event_note</mat-icon>
              </span>
              <span>{{'add_meeting_note' | translate}}</span>
            </div> -->


          </div>
        </ng-container>



        <mat-drawer class="rightdrawer" mode="side" position="end" opened
        style="width: 40%;">
          <ng-container class="event-details">


            <div class="call-div">
              <span class="secondaryButton" mat-flat-button>
                <mat-icon class="detail-icon">group</mat-icon>
              </span>

              <span matLine  [ngStyle]="{'font-size':'16px' ,'line-height':'1.5'}">
                {{'event_guests_number' | pluralTranslate: eventItem?.getAttendees().length | translate: {'number': eventItem?.getAttendees().length} }}</span>
              <span></span>

            </div>

            <div style=" width: 100%; display: inline-block;padding-bottom: 8px; padding-left: 25px;" class="attendees-div">

              <div class="attendee-div" *ngFor="let person of eventItem?.getAttendees(); let i=index">

                <div  style="display: inline; margin-right: 10px; position: relative;width: 23px;">
                  <img src="{{person?.getPhoto()}}" *ngIf="!!person?.getPhoto()" class="personPhoto" matTooltip="{{person.getName()}}"
                  [ngClass]="{'cursorPointer': person?.getId()!=null || person?.getMail()!=null}">

                  <svg *ngIf="person.getStatus()=='accepted'" class="status-svg" xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#CEEAD6" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#137333" stroke-width="1.5" d="M3.5 6.5L6 9l5-5"></path></svg>
                  <svg *ngIf="person.getStatus()=='declined'" class="status-svg" xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#FAD2CF" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#B31412" stroke-width="1.5" d="M4 10l6-6M10 10L4 4"></path></svg>
                  <svg *ngIf="person.getStatus()=='tentative'" class="status-svg" xmlns="https://www.w3.org/2000/svg" width="14" height="14"><path fill="#E8EAED" d="M7 0c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"></path><path fill="none" stroke="#3C4043" stroke-width="1.5" d="M5 5c0-1.1.9-2 2-2s2 .9 2 2-1 1.5-1.5 2S7 8 7 9M7 10v1.5"></path></svg>

                  <div class="spareImg center" *ngIf="!person.getPhoto()" style="display: inline-grid;"
                  [ngClass]="{'cursorPointer': person?.getId()!=null || person?.getMail()!=null}">
                    <span class="imgInitial imgInitial_web" matTooltip="{{person.getName()}}">{{person.getInitial() | uppercase}}</span>
                  </div>
                </div>
                <span>{{person.getName()}}</span>

              </div>

              <p class="masked-attendees-p" *ngIf="!eventItem?.getCanSeeGuests()" >maskedlist
              </p>


            </div>

          </ng-container>
        </mat-drawer>
      </mat-drawer-container>
    </div>


    <div  >
      <div style="padding-right: 31px; display: contents; align-items: center;">
        <div style="align-items: baseline; display: flex;  margin-left: 60%;">
          <p style="margin-bottom:15px!important;">{{'event_going_question' | translate}}</p>
        </div>
        <span class="app-spacer"></span>
        <div style="align-items: baseline; display: flex; margin-left: 70%;">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="tempAnswer">
            <mat-button-toggle *ngFor="let o of meetingOptions" value="{{o}}"  (click)="prepareAnswer(o) " >{{o}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
        <button auraButton type="light" [hasShadow]="true" label="{{'goBack' | translate}}" (click)="closeModal()"  ></button>
          <button auraButton type="{{typeBtn}}" [hasShadow]="true" label="{{'updateParticipation' | translate}}"  (click)="meetOptionClick(eventItem)"   [disabled]="!activeSubmitButton" class="btnUpdate"></button>
    <!--      <button mat-raised-button class="submitBtn" (click)="meetOptionClick(eventItem)" [disabled]="!activeSubmitButton">{{'updateParticipation' | translate}}</button>
     -->  </div>
    <!-- </div> -->
  </aura-modal>
