import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { DocumentPageComponent } from './document-page/document-page.component';

export const containers = [
  DocumentPageComponent,
  ActionDialogComponent
];

export const entryComponents = [
  ActionDialogComponent
]

export * from './document-page/document-page.component';
export * from './action-dialog/action-dialog.component';