import { Component, OnInit } from '@angular/core';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { ChartDataset } from 'chart.js';

@Component({
  selector: 'app-analytics',
  templateUrl: 'bootstrap/analytics.component.html',
  styleUrls: ['bootstrap/analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit 
{
  departments: ListItem[] = [
    {
      id: 1,
      title: 'Department',
      redirectTo: '/departments/1/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Department',
      redirectTo: '/departments/2/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Department',
      redirectTo: '/departments/3/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Department',
      redirectTo: '/departments/4/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Department',
      redirectTo: '/departments/5/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 6,
      title: 'Department',
      redirectTo: '/departments/6/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 7,
      title: 'Department',
      redirectTo: '/departments/7/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 8,
      title: 'Department',
      redirectTo: '/departments/8/spaces',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
  ];
  infos: ListItem[] = [
    {
      id: 0,
      title: '60',
      text: 'Document Per day',
      image: '/assets/images/icon-trending-up.svg',
    },
    {
      id: 1,
      title: '0',
      text: 'Document delay',
      image: '/assets/images/icon-trending-down.svg',
    },
    {
      id: 2,
      title: '10',
      text: 'Document to edit',
      image: '/assets/images/icon-trending-up.svg',
    },
    {
      id: 3,
      title: '100',
      text: 'Urgent Document',
      image: '/assets/images/icon-trending-up.svg',
    },
  ];
  // Chat data
  labels: string[] = ['', '', '', '', ''];
  datasets: ChartDataset[] = [
    {
      label: 'Documents',
      backgroundColor: '#FFFFFF',
      borderWidth: 4,
      borderColor: '#009DFF',
      tension: 0.4,
      data: [40, 115, 60, 160, 60, 75],
      pointStyle: 'circle',
      pointRadius: 6,
    },
    {
      label: 'files',
      backgroundColor: '#FFFFFF',
      borderWidth: 4,
      borderColor: '#000',
      tension: 0.4,
      data: [40, 23, 60, 10, 20, 75],
      pointStyle: 'circle',
      pointRadius: 6,
    },
  ];
  constructor() 
  {}

  ngOnInit(): void 
  {}
}
