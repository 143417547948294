
  <!-- steps (roles) line -->
  <ng-container>
    <section id="containerP" class="steps-line  justify-content-between gx-0 mb-4"
    [ngClass]="{'containerP': workflowRoles?.length >3 && !isInfo}"
    >
      <ng-container  *ngFor="let role of workflowRoles; let i=index; let j=last; let isFirst=first" id="content"
        [ngClass]="{'content':  workflowRoles?.length >3 && !isInfo}">

        <ng-container   class="contentT">
            <div class="col position-relative number-step item-content 1"   [ngClass]="{'isFirst':isFirst , 'lastElem' :j , 'two-step':  workflowRoles?.length ==2 && j , 'active':role.isCurrent ||role.isDon}" 
              *ngIf="role.isPending && !role.isCurrent"
              [title]="role.data.description"
              [attr.data-label]="role.name">
              <span class="d-flex justify-content-center align-items-center shadow-sm">{{i+1}}</span>
            </div>

            <div class="col position-relative number-step item-content 2"
              *ngIf="role.isDone" [ngClass]="{'isFirst':isFirst , 'lastElem' :j , 'two-step': workflowRoles?.length ==2 && j , 'active':role.isCurrent ||role.isDon}"
                [title]="role.data.description"
                [attr.data-label]="role.name">
              <span class="d-flex justify-content-center align-items-center shadow-sm"
                [ngClass]="{'active': true , 'isActive':role.isDonet}">{{i+1}}</span>
            </div>

            <div class="col position-relative number-step item-content 3"
              *ngIf="role.isCurrent" [ngClass]="{'isFirst':isFirst , 'lastElem' :j ,'two-step': workflowRoles?.length ==2 && j , 'active':role.isCurrent ||role.isDone }"
                [title]="role.data.description"
                [attr.data-label]="role.name">
              <span class="d-flex justify-content-center align-items-center shadow-sm"
                [ngClass]="{'active': true}">{{i+1}}</span>
            </div>

          </ng-container>

        <ng-container   *ngIf="!j" class="lineProgress">
            <span class="lined"
            [ngClass]="{
              'active':role.isDone,
              'lined-two-step': workflowRoles?.length ==2 && i ===0  ,
              'linedplus': workflowRoles?.length >3 ,
              'linednormal': workflowRoles?.length < 4}"></span>
        </ng-container >
        

      </ng-container>
  </section>

  </ng-container>
<ng-container>
    
  <button id="slideRight" *ngIf="workflowRoles?.length >3 && !isInfo" class="mt-3"
    type="button" (click)="rightButton()"
    ><i class="gg-chevron-right"></i></button>

<button id="slideLeft" *ngIf="workflowRoles?.length >3 && !isInfo"
    type="button" (click)="LeftButton()"><i class="gg-chevron-left"></i></button>


</ng-container>