<div class="filter-content">
  <ng-container *ngTemplateOutlet="isFacetsFormEmpty ? FilterEmpty : FilterContent"></ng-container>

  <ng-template #FilterContent>
    <div *ngFor="let f of facetsForm"
      class="row">
      <aura-select *ngIf="f.control"
        inline
        size="large"
        wrapClass="mb-3"
        [formControl]="f.control"
        [id]="f.id"
        [items]="f.options"
        [placeholder]="f.placeholder"
        (onChange)="onChangeFacetValue($event, f)"></aura-select>
    </div>
  </ng-template>

  <ng-template #FilterEmpty >
    <p>{{'No_results' | translate }}. . .</p>
  </ng-template>
</div>

<section *ngIf="!isFacetsFormEmpty"
  class="url pb-3 text-center">
  <a class="a text-decoration-underline btn-reset"
    (click)="resetFilters()">{{'Reset filters' | translate }}</a>
</section>
