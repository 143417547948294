<div [class.scrollable-container]="scrollable">
    <form [formGroup]="formGroup">
        <div class="container" [class.d-flex]="displayHorizontal">
            <div *ngFor="let field of fieldsArray">
                <ng-container *ngIf="field.desc.isShown()">
                    <div [class.test]="displayHorizontal" (click)="displayEdit()">

                        <ng-container [ngSwitch]="field.desc.typeControl">

                            <ng-template *ngSwitchCase="'checkbox'" [ngTemplateOutlet]="InputCheckbox"></ng-template>

                            <!-- * Template : AUTOCOMPLETE -->
                            <ng-template *ngSwitchCase="'autocomplete'"
                                [ngTemplateOutlet]="InputAutoComplete"></ng-template>

                            <!-- * Template : Input Text -->
                            <ng-template *ngSwitchCase="'text'" [ngTemplateOutlet]="InputText"></ng-template>

                            <!-- * Template : Input Date -->
                            <ng-template *ngSwitchCase="'date'" [ngTemplateOutlet]="InputDate"></ng-template>

                            <!-- * Template : STATIC ENUM: Select -->
                            <ng-template *ngSwitchCase="'select'" [ngTemplateOutlet]="InputEnum"></ng-template>

                            <!-- * Template : checkboxMultiple -->
                            <ng-template *ngSwitchCase="'checkboxMultiple'"
                                [ngTemplateOutlet]="InputCheckboxMultiple"></ng-template>

                            <!-- * Template : checkboxMultiple -->
                            <ng-template *ngSwitchCase="'autocompleteMutiple'"
                                [ngTemplateOutlet]="autocompleteMutiple"></ng-template>


                            <!-- ? START - Template - Input Text -->
                            <ng-template #InputText>
                                <aura-input-text label="{{ field.desc.label || field.desc.fname  | translate}}"
                                    size="large" wrapClass="mb-3" (change)="onChangeField(field.desc.fname)"
                                    [required]="field.desc.required"
                                    [readonly]="field.desc.editable === false || canEdit === false ? true : false"
                                    [formControl]="formGroup.get(field.desc.fname)" [mode]="'outlined'"
                                    (onChange)="selectField()" [icon]="field.desc.icon ?? '' " [isTextArea]="true">
                                </aura-input-text>
                            </ng-template>
                            <!-- ? END - Template  - Input Text-->

                            <!-- ? START - Template  - Input Date -->

                            <ng-template #InputDate>
                                <label class="col-form-label-hipe">{{ field.desc.label }}</label>
                                <mat-form-field class="col-form-field-hipe">
                                    <input matInput [matDatepicker]="picker"
                                        [placeholder]="'FORM.DATE_PLACEHOLDER' | translate"
                                        [formControl]="formGroup.get(field.desc.fname)">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <!--<aura-input-datepicker [formControl]="formGroup.get(field.desc.fname)"
                                    [required]="field.desc.required" [label]="field.desc.label "
                                    (onChange)="call($event)">
                                </aura-input-datepicker> -->
                            </ng-template>
                            <!-- ? END - Template  - Input Date -->

                            <!-- ? START - Template - (STATIC ENUM)/Select -->
                            <ng-template #InputEnum>
                                <aura-select inline wrapClass="mb-3" [formControlName]="field.desc.fname"
                                    [items]="field.desc.enums(true, true)" [required]="field.desc.required"
                                    [label]="field.desc.label | translate" [mode]="'outlined'"
                                    (onChange)="selectField()"
                                    [readonly]="field.desc.editable === false ||  canEdit === false ? true : false">
                                </aura-select>
                            </ng-template>
                            <!-- ? END - Template  - (STATIC ENUM)/Select -->

                            <!-- ? START - Template  - STATIC ENUM: CHECK BOX -->
                            <ng-template #InputCheckbox>
                                <div class="checkbox-dev">
                                    <mat-label *ngIf="field.desc.enums().length > 1">{{field.desc.label |
                                        translate}}</mat-label>
                                    <section *ngFor="let item of field.desc.enums() " class="checkboxSection">
                                        <input type="checkbox"
                                            [checked]="item.value == field.value(true) || item.html == field.html"
                                            (change)="onChangeEnumsChecked(item, field, $event.target.checked);"
                                            class="checkboxItem"> {{item.html}}<br>
                                    </section>
                                </div>
                            </ng-template>

                            <!-- ? END - Template  - (STATIC ENUM)/CHECK BOX -->

                            <!-- ? START - Template  - STATIC ENUM: CHECK BOX MULTIPLE-->
                            <ng-template #InputCheckboxMultiple>
                                <aura-input-auto-complete-multiple *ngIf="field.desc.enums(true).length>0"
                                    [items]="field.desc.enums(true) " (onChange)="onChangeEnumsChecked2(field, $event)"
                                    [defaultValue]="getCheckedElement(field.data(), field.desc.enums(true), field.desc.label)"
                                    [label]="field.desc.label | translate"
                                    (onCreateClick)="onCreateClick($event, field.desc)"
                                    [createNewItemLabel]="'addItem' | translate" [activateClearAll]="false"
                                    [canCreate]="field.desc.urlAdd ? true : false" [mode]="'outlined'"
                                    [readonly]="field.desc.editable === false || canEdit === false ? true : false">
                                </aura-input-auto-complete-multiple>
                            </ng-template>
                            <!-- ? END - Template  - (STATIC ENUM)/CHECK BOX -->

                            <!-- ? START - Template : AUTO COMPLETE MULTIPLE-->
                            <ng-template #autocompleteMutiple>
                                <aura-input-auto-complete-multiple
                                    [items]="field.desc.enums(true)?.lenght ? field.desc.enums(true) : []"
                                    (onChange)="onChangeEnumsChecked2(field, $event)"
                                    [defaultValue]="getCheckedElement(field.data(), field.desc.enums(true), field.desc.label)"
                                    [label]="field.desc.label | translate"
                                    (onCreateClick)="onCreateClick($event, field.desc)"
                                    [createNewItemLabel]="'addItem' | translate" [activateClearAll]="false"
                                    [canCreate]="field.desc.urlAdd ? true : false" [mode]="'outlined'"
                                    [readonly]="field.desc.editable === false  || canEdit === false ? true : false"
                                    [diplayMaxElements]="3">

                                </aura-input-auto-complete-multiple>
                                <!-- To use formControl within formGroup, provide [formControl] = formGroupName.controls[‘formControlName’] otherwise just [formControl] = ‘formControlName’ -->
                            </ng-template>
                            <!-- ? END - Template  - AUTO COMPLETE MULTIPLE -->


                            <!-- ? START - Template  - AutoComplete -->
                            <ng-template #InputAutoComplete>
                                <aura-input-auto-complete *ngIf="isVisible" class="mb-3"
                                    [fetchDataCallback]="filterFieldAutoComplete.bind(this, field, inputValue)"
                                    [useCallback]="true" (onChange)="onSelectedField($event, field)"
                                    [defaultValue]="transformToAura2(field.data())"
                                    [label]="field.desc.label | translate"
                                    (input)="inputValue[field.desc.label] = $event" [mode]="'outlined'"
                                    (onChange)="selectField()"
                                    [readonly]="field.desc.editable === false  || canEdit === false ? true : false"
                                    #autoCompleteComponent>
                                </aura-input-auto-complete>
                            </ng-template>
                            <!-- ? END - Template  - AUTO COMPLETE -->

                        </ng-container>
                    </div>

                </ng-container>
            </div>
        </div>
    </form>
    <!--</mat-card-content>-->
    <div *ngIf="!isExtension">
        <div *ngIf="!showContent || !showRecap   ">
            <div *ngIf="displayButtons">
                <hr style="border:1px solid grey">
                <div class="submit-buttons">
                    <div>

                        <button auraButton label="{{'ACTIONS.CANCEL' | translate}}" (click)="onGoBack()"
                            [hasShadow]="true" [full]="false" class="cancelBtn" [style.padding-inline]="'14px'"
                            type="light">
                        </button>
                    </div>
                    <div>
                        <button auraButton size="small" label="{{'ACTIONS.SAVE' | translate}}" [full]="false"
                            imageIcon="/assets/images/icon-plus.svg" (click)="submit()" class="submit"
                            [disabled]="formGroup.invalid">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  </div> </mat-card>-->
</div>