import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WorkspaceMainComponent } from './containers';
import { DocumentPageComponent } from '../documents/containers/document-page/document-page.component';
import { SidePanelComponent } from '../documents/containers/document-page/side-panel/side-panel.component'
import { NofileComponent } from '../documents/containers/document-page/nofile/nofile.component';

const routes: Routes =
  [
    // {
    //   path: 'main',
    //   component: WorkspaceMainComponent,
    //   data: { title: environment.stringsFile.workspacePage }
    // },

    {
      path: "file/:docId",
      component: SidePanelComponent
    },
    {
      path: "default",
      component: NofileComponent
    }

  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkspaceRoutingModule 
{ }
