import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Location } from "@angular/common";
import { GDocService, GedService } from "src/modules/app-common/services";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DocumentPageComponent } from "../../containers";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { DocumentService } from "../../services/document.service";
import { SpaceService } from "src/modules/spaces/services";

@Component({
  selector: "app-doc-nav",
  templateUrl: "./doc-nav.component.html",
  styleUrls: ["./doc-nav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DocNavComponent implements OnInit, OnChanges 
{
  //isToggled = false;

  ngOnChanges(changes: SimpleChanges) 
  {

    if (changes.documentCurent) 
    {

      this.isDocinGed(this.docId);

      // this.docParent(this.docId);
    }
  }
  @Input('reloadDoc') reloadDoc;
  @Input('documentCurent') documentCurent;

  history: string[] = [];

  driveurl = "/s/space/root";
  parentId;
  doc;
  inGed = false;
  events: string[] = [];
  opened1: boolean = true;
  opened2: boolean = false;
  docInfo = false;

  docSpace = false;

  sideNavState: boolean = false;
  linkText: boolean = false;
  contentMargin = 0;
  iconPadding = 15;
  arrow = "keyboard_arrow_right";
  libelle = environment.appName;
  libelleTooltip = environment.appName;
  parentSpace;
  spacpeParent;
  driveId = localStorage.driveId;
  docId: string = "";
  previousUrl: string = null;
  currentUrl: string = null;

  @Input("isToggled") isToggled;

  constructor(
    private _location: Location,
    private gedService: GedService,
    private router: Router,
    private route: ActivatedRoute,
    private gdocService: GDocService,
    private documentPage: DocumentPageComponent,
    public dialog: MatDialog,
    private docService: DocumentService
  ) 
  {
    this.router.events.subscribe((event) => 
    {
      if (event instanceof NavigationEnd) 
      {

        this.history.push(event.urlAfterRedirects);

      }
    });
  }

  documentId
  ngOnInit(): void 
  {
    this.route.params.forEach((params) => 
    {
      this.docId = params["docId"];
      //  this.isDocinGed(this.docId);
      //this.docParent(this.docId);
      this.SpaceDos(this.docId);
    });


  }

  toggleGedBar() 
  {
    this.documentPage.toggleGedBar();
    this.isToggled = true;
  }

  openGedBar() 
  {
    this.documentPage.openGedBar();
    this.isToggled = false;
  }
  closeDocInfo() 
  {
    this.docInfo = false;
    this.documentPage.closeDocInfo();
  }

  openDocInfo() 
  {
    this.docInfo = true;
    this.documentPage.openDocInfo();
  }

  openInDrive() 
  {
    this.route.params.forEach((params) => 
    {
      const id = params["docId"];

      this.gdocService
        .getFile(id)
        .then()
        .then((data) => 
        {
          const doc = data;

          window.open(doc.webViewLink, "_blank");
        });
    });
  }

  toDrive() 
  {
    this.gdocService.getMyDriveId().then((id) => 
    {
      const driveId = id;

      this.router.navigate(["/s/space/root"], {
        queryParams: { d: localStorage.host_domain },
      });
    });
  }

  toHomepage() 
  {
    this.router.navigateByUrl("/workspace/main");
  }

  async backClicked() 
  {
    this.parentId = await this.gdocService.getDocumentParent(this.docId);

    if (this.parentId)
    {
      this.router.navigate(["/s/space/" + this.parentId], {
        queryParams: { d: localStorage.host_domain },
      });
    }
  }

  async isDocinGed(docId) 
  {
    /* let gedDocument = await this.gedService.getDoc(docId,null);
    console.log("stategedDocument",gedDocument);
   */
    const gedDocument = this.documentCurent

    if (
      !!gedDocument &&
      gedDocument != null &&
      typeof gedDocument == "object"
    ) 
    {
      this.doc = gedDocument;
      this.inGed = true;
    }
    else 
    {
      this.inGed = false;
    }
  }

  spaceParent;
  /*  async docParent(docId) {
     this.parentSpace = await this.gedService.getDocParent(docId,null);
 
     if (this.parentSpace) {
       let spaceSid = this.parentSpace.split(".")[1];
       let space = await this.spaceService.getSpacebySid(spaceSid);
  
       if (space) {
         this.spaceParent = space;
         this.docSpace = true;
       }
     }
   }
  */


  async SpaceDos(docId) 
  {
    const gedDocument = await this.gedService.getSpaceDoc(docId);

    if (gedDocument) 
    {
      this.docSpace = true;
      this.spaceParent = gedDocument

    }

  }
  async backToSpace() 
  {
    if (this.spaceParent) 
    {
      const url = "s/space/" + this.spaceParent["uid"];

      this.router.navigate([url]);
    }
  }

  toggleDisplay() 
  {
    this.sideNavState = !this.sideNavState;

    if (!this.sideNavState) 
    {
      this.linkText = !this.linkText;
      this.contentMargin = 40;
      this.arrow = "keyboard_arrow_right";
    }
    else 
    {
      this.linkText = !this.linkText;
      this.contentMargin = 100;
      this.arrow = "keyboard_arrow_left";
    }
    setTimeout(() => 
    { }, 200);
  }

  detachFromGed() 
  {
    this.docService.detachFromGed();
  }

  deleteDoc() 
  {
    this.docService.deleteDoc(this.inGed);
  }

  PrevPage() 
  {
    this._location.back();

    const url = this.router.url;

    this.history.pop()
    if (this.history.length > 0) 
    {
      this._location.back()
    }
    else 
    {
      this.router.navigateByUrl(url)
    }
  }
}
