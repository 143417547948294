<div class="card-header" *ngIf="!dontShowDocType">
  <div class="py-3"> {{'newDoc' | translate}}</div>
</div>

<div>
  <div class="spinner-div">
    <mat-spinner *ngIf="showSpinner"></mat-spinner>
  </div>

  <form #f="ngForm" *ngIf="!showSpinner">
    <!-- DOCUMENT TYPE -->
    <div *ngIf="!dontShowDocType">
      <mat-form-field class="form-dev" appearance="fill" style="display: block; text-justify: center;">
        <mat-label>Document Type</mat-label>

        <mat-select [(ngModel)]="docType.title" [(value)]="docType.title" name="docType"
          (selectionChange)="onSelectedType($event)">
          <mat-option *ngFor="let option of docTypes" [value]="option.title">
            {{option.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- FORM FIELDS  -->
    <div *ngIf="showForm">
      <!-- <ng-container *ngFor="let field of formFields; let j=index"
            appearance="fill" style="display: block; text-justify: center;">

          
          <mat-form-field class="form-dev"
            *ngIf="field.auto==null && field.control !=='checkbox' && !(field.isEnum)">
            <mat-label>{{field.label}}</mat-label>
              <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"
                required="{{field.required}}">
          </mat-form-field>

          <mat-form-field class="form-dev"
            *ngIf="field.auto!=null  && field.key!='space' &&  field.key!='for_type_document' && field.control !='select' && field.control !=='checkbox' ">

            <mat-label>{{field.label}}</mat-label>
            <input
                  matInput [(ngModel)]="field.value.html"
                  name="{{field.key}}"
                  type="{{field.type}}"
                  required="{{field.required}}"
                  [matAutocomplete]="autofield"
                  (ngModelChange)="doFilterField(field)"
                  #optionfield="ngModel">
              <mat-autocomplete
                #autofield="matAutocomplete"
                (optionSelected)="onSelectedField($event,j)">
                <mat-option *ngFor="let option of fieldAutoList" [value]="option.html">
                  {{option.html}}
                </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="form-dev"
            *ngIf="field.key==='for_type_document' && field.control !='select'">
            <mat-label>{{field.label}}</mat-label>

            <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"
              required="{{field.required}}"

            [matAutocomplete]="autofield" (ngModelChange)="doFilterField(field)" #optionfield="ngModel">
            <mat-autocomplete #autofield="matAutocomplete" (optionSelected)="onSelectedField($event,j)">
              <mat-option *ngFor="let option of fieldAutoList" [value]="option.html">
                {{option.html}}
              </mat-option>
            </mat-autocomplete>

          </mat-form-field>

          
          <mat-form-field class="form-dev"
            *ngIf="field.key=='space' || field.key=='template' || (field.control=='select'  && field.isAuto())">
            <mat-label>{{field.label}}</mat-label>

            <mat-select [(ngModel)]="field.value.html" [(value)]="field.value.html"
              (selectionChange)="onSelectedValue(field, field.value)" name="{{field.key}}">
                <mat-option *ngFor="let option of field.fieldAutoList" [value]="option.html">
                  {{option.html}}
                </mat-option>
              </mat-select>
          </mat-form-field>

          
          <mat-form-field class="form-dev" class="form-dev"
              *ngIf="(field.isEnum) && !(field.isMultiple) && field.control != 'checkbox'">

            <mat-label >{{field.label}}</mat-label>

            <mat-select
              [(ngModel)]="field.value.html"
              [(value)]="field.value.html"
              name="{{field.key}}">
              <mat-option  *ngFor="let option of field.enumValues | keyvalue " [value]="option.key">
                {{option.value}}
              </mat-option>
            </mat-select>

          </mat-form-field>

          
          <div *ngIf="field.control == 'checkbox' && !field.isMultiple"  class="checkbox-dev">
            <mat-label class="checkboxEnums">{{field.label}}</mat-label>
            <section  *ngFor="let option of field.enumValues | keyvalue"   class="checkboxSection">
              <input type="checkbox"
                [checked]="isSelectedValue(option,field)"
                (change)="onChangeEnumsChecked(option, field,$event.target.checked)"
                class="checkboxItem" > {{option.value}}<br>
            </section>
          </div>

          
          <mat-form-field *ngIf="field.control ==='checkbox'  && field.isMultiple && !(field.isEnum)" class="form-dev">
            <mat-label class="checkboxEnums">{{field.label}}</mat-label>
             <mat-select 
             [(ngModel)]="field.value.html" 
             [multiple]="true" 
             [ngModelOptions]="{standalone: true}" >
              <mat-option *ngFor="let item of field.fieldAutoList" [value]="item" (onSelectionChange)="onSelectChange(item,field,$event)"  >
                {{ item.html }}
              </mat-option>
            </mat-select> 
              <section *ngFor="let item of field.fieldAutoList
            " class="checkboxSection">
              <input type="checkbox" class="checkboxItem"
                (change)="onChangeEnumsChecked(item, field,$event.target.checked)"
                > {{item.html}}<br>
            </section>  
          </mat-form-field>onAddtoGed
           

        </ng-container> -->

      <app-form-manager [inputData]="inputData" [displayGroupe]="'meta'" [type]="'form'"
        (onFormCancelEvent)="cancelForm()" (onFormSubmitEvent)="onAddtoGed($event)" [showRecap]="true"
        [showEditFirst]="false" [canEdit]="canEdit" (callBack)="detectFormEdit($event)"
        [isExtension]="true"></app-form-manager>
    </div>

    <div class="col-md-12 actions">

      <!--<button auraButton   type="light"    label="{{'cancelBtn' | translate}}"  *ngIf="showForm && !dontShowDocType" &&  (click)="cancelForm()" class="cancel-btn"> </button>
        <button auraButton type="primary" [hasShadow]="true" label="{{'addBtn' | translate}}" (click)="onAddtoGed(f.value)" [disabled]="!f.valid  || !docTypeSelected" ></button>-->
    </div>

  </form>
</div>