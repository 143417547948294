import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  DateService,
  GDocService,
  GedService,
} from "src/modules/app-common/services";
import { Moment } from "moment";
import { MetaDocument } from "src/modules/spaces/models/meta/meta-document.model";
import { DocumentService, GedDocService } from "../../services";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { PDocEventsService } from "src/modules/p-doc-envents/p-docs-events.service";
import { AuthService } from "src/modules/user/services";
import { filter, tap } from "rxjs/operators";
import { SidepanelService } from "../../containers/document-page/side-panel/sidepanel.service";

const accent = "#ff4081";
const primary = "#3f51b5";
const white = "#FFFFFF";
const themePath = environment.themePath;

@Component({
  selector: "app-doc-desc",
  templateUrl: `./${themePath}/doc-desc.component.html`,
  styleUrls: [`./${themePath}/doc-desc.component.scss`],
})
export class DocDescComponent implements OnInit, OnChanges 
{
  @Input("path") path;
  @Input("gedDoc") gedDoc;
  @Input("canEdit") canEdit;
  @Input("iseditMeta") iseditMeta;
  @Input("spaceName") spaceName;
  ngOnChanges(changes: SimpleChanges) 
  {
    if (this.iseditMeta === true) 
    {
      this.showContent = false;
    }
    else 
    {
      this.showContent = true;
    }
    if (changes.gedDoc) 
    {
      this.gedDoc = changes.gedDoc.currentValue;
      this.getDoc();
    }
  }

  formValues = [];
  currentDocId = localStorage.docId;
  showContent = true;
  showMeta = true;
  showAuto = false;
  isLoading = true;

  doc;
  responseDoc;
  docTemp;
  meta: any;
  inputData: any;
  currentName: string;


  //chipContent = [];
  contentMeta: MetaDocument[] = [];
  chipContent: MetaDocument[] = [];

  constructor(
    private gedService: GedService,
    public datepipe: DatePipe,
    private router: Router,
    private gedDocService: GedDocService,
    private pDocsEventsService: PDocEventsService,
    private auth: AuthService,
    private sidepanelService: SidepanelService
  ) 
  { }

  ngOnInit(): void 
  {
    this.currentDocId = localStorage.docId;
    const path = localStorage.path;

    this.sidepanelService.setTypeOfForm('desc');
  }

  isEnumObjects = [];
  checkedObjs;
  ischeckEdit = false;
  fieldAutoList = [];
  typeDoc;

  isChecked(item, label) 
  {
    const obj = this.objectEnums.find((obj) => obj.label === label);

    if (obj) 
    {
      return obj.enumValues.includes(item);
    }

    return false;
  }

  /*------------------------------------------------- THE GETS ------------------------------------------------------------*/

  getDoc() 
  {
    // to update
    if (this.gedDoc) 
    {
      this.responseDoc = this.gedDoc;
      this.doc = this.gedDoc.content.data;
      this.typeDoc = this.doc.type_document.html;

      const indexx = this.typeDoc.length - 1;

      if (this.typeDoc[indexx] == "s")
      {
        this.typeDoc = this.typeDoc.slice(0, indexx);
      }

      this.getMetas(this.path, this.currentDocId);
    }
  }

  objectEnums = [];

  othserPartners = [];
  objectEnumss = [];

  async getMetas(path, currentDocId) 
  {
    const document = this.gedDoc;
    const meta = document?.["content"].metadata.fields;

    this.isLoading = false;
    this.meta = meta;

    let i = 0;

    for (const [key, value] of Object.entries(this.meta)) 
    {
      const xtag = value["x-tags"] || [];

      if (xtag.includes("meta")) 
      {
        const doc = this.doc[key] || "-";

        this.chipContent.push(new MetaDocument(key, doc, value));

        //datatype est un objet contenant html et value
        if (this.chipContent[i].dataType == "object") 
        {
          if (
            this.doc[key]?.html == "-" ||
            this.doc[key]?.html == null ||
            this.doc[key]?.value == "-" ||
            this.doc[key]?.value == null
          ) 
          {
            this.doc[key] = null;
          }

          this.formValues[i] = { ...this.chipContent[i].value };
        }
        else if (this.chipContent[i].control == "checkbox") 
        {
          const enumValues = this.chipContent[i].value?.split(",") || [];

          this.objectEnums.push({
            label: this.chipContent[i].label,
            enumValues,
          });
        }

        if (
          this.chipContent[i].isAuto &&
          this.chipContent[i].control == "select"
        ) 
        {
          this.formValues[i] = this.chipContent[i].value;
        }

        if (
          this.chipContent[i].control == "checkbox" &&
          this.chipContent[i].isAuto
        ) 
        {
          const valChecked = [];
          const url = this.chipContent[i].autoUrl;
          const enumsOthers = await this.gedService.getEnumsValues(url);

          this.othserPartners = enumsOthers;
          this.chipContent[i].enumValues = enumsOthers;
          const valueChip = this.chipContent[i].value;
          //this.chipContent[i].selectedValues=this.formValues[i].split(",");

          // this.chipContent[i].value =  array.split('|').join(' ').substr(1).slice(0, -1)

          // this.chipContent[i].value = "";
          if (valueChip != null && valueChip != "") 
          {
            const t = valueChip.split("|").join(" ").substr(1).slice(0, -1);
            const split_string = t.split(" ");

            split_string.forEach((item) => 
            {
              valChecked.push(this.filterCheckbox(item));
            });

            valChecked.sort((a, b) => (a > b ? 1 : -1));

            this.objectEnums.push({
              label: this.chipContent[i].label,
              enumValues: valChecked,
            });

            if (valChecked[0] != undefined) 
            {
              this.chipContent[i].value = valChecked.toString();
              this.formValues[i] = this.chipContent[i].value;
            }
            this.chipContent[i].selectedValues = this.formValues[i].split(",");
          }
          else 
          {

            /* if( this.chipContent[i].value = '||'){
            this.chipContent[i].value = "  "
            }
            */
            this.chipContent[i].value = "";
          }
        }
        else if (
          this.chipContent[i].dataType == "enum" && // enum and array of values
          this.chipContent[i].enumValues &&
          this.chipContent[i].enumValues.filter
        ) 
        {
          const value = this.chipContent[i].value;

          if (value && value.html) this.formValues[i] = value;
          else
          {
            this.formValues[i] = this.getEnumValue(
              value,
              this.chipContent[i].enumValues
            );
          }

          this.chipContent[i].value = this.formValues[i];
        }
        else this.formValues[i] = this.chipContent[i].value;

        i++;
      }
    }
    if (this.chipContent.length == 0) this.showMeta = false;
    else this.showMeta = true;
  }

  onChange(key: string, event, label: string, i) 
  {
    this.ischeckEdit = true;
    const isChecked = event.source.selected;

    if (isChecked) 
    {
      if (key["key"]) 
      {
        if (this.chipContent[i].value.indexOf(key["value"]) === -1) 
        {
          const valuesArray: any[] = (
            this.chipContent[i].value.split(",") || []
          ).filter((item) => item !== "");

          valuesArray.push(key["value"]);
          this.chipContent[i].value = valuesArray.join(",");
        }
      }

      if (key["html"]) 
      {
        if (this.chipContent[i].value.indexOf(key["html"]) === -1) 
        {
          const valuesArray: any[] = (
            this.chipContent[i].value.split(",") || []
          ).filter((item) => item !== "");

          valuesArray.push(key["html"]);
          this.chipContent[i].value = valuesArray.join(",");
        }
      }
    }
    else 
    {
      if (key["html"]) 
      {
        const valuesArray = this.chipContent[i].value
          .split(",")
          .filter((item) => item != key["html"])
          .filter((item) => item !== "");

        this.chipContent[i].value = valuesArray.join(",");
      }

      if (key["key"]) 
      {
        const valuesArray = this.chipContent[i].value
          .split(",")
          .filter((item) => item != key["value"])
          .filter((item) => item !== "");

        this.chipContent[i].value = valuesArray.join(",");
      }
    }

    this.objectEnumss = [
      {
        label: this.chipContent[i].label,
        value: this.chipContent[i].value,
      },
    ];
  }

  filterCheckbox(value) 
  {
    // const labels;
    // console.log("split_string",obj.html)
    const label = this.othserPartners.filter((obj) => 
    {
      // console.log("split_string",obj['value'] , value)

      if (obj.value == value) return obj.html;

      //obj.value = value
    });

    if (label[0])
    // const labels =     this.othserPartners[value] ;
    {
      return label[0]["html"];
    }
  }

  /*------------------------------------------------- USER INPUT EVENTS ------------------------------------------------------------*/

  doFilter(i: number) 
  {
    if (this.formValues[i] == undefined) 
    {
      this.formValues[i] = { html: "", value: "" };
    }
    if (this.formValues[i].html == "-") 
    {
      this.formValues[i] = { html: "", value: "" };
    }
    else if (
      this.formValues[i].html == "" ||
      this.formValues[i].html == " "
    ) 
    {
      this.chipContent[i].autoComplete = [];
      this.formValues[i] = { html: "", value: "" };
    }
    else 
    {
      this.gedService
        .getAutocompleteList(
          this.formValues[i].html,
          this.chipContent[i].autoUrl
        )
        .then((res) => 
        {
          if (res?.length == 0) 
          {
            this.gedService
              .getAutocompleteList("", this.chipContent[i].autoUrl)
              .then((response) => 
              {
                const item = response.find(
                  (element) => element?.html == this.formValues[i]?.html
                );

                this.formValues[i] = item;
              });
          }
          else 
          {
            this.chipContent[i].autoComplete = res;
            for (const item of this.chipContent[i].autoComplete) 
            {
              if (this.formValues[i].html == item.html)
              {
                this.formValues[i] = item;
              }
              else this.filter(item, i);
            }
          }
        });
    }
  }

  filter(values, i: number) 
  {
    let filterValues = values;

    if (!Array.isArray(values)) 
    {
      filterValues = [values];
    }

    return filterValues.filter((option) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      (option?.html?.toLowerCase() || option?.toLowerCase() || "")?.includes(
        this.formValues[i].html
      )
    );
  }

  onInputChange(i) 
  { }

  OnDateChange(eventvalue: Moment, i: number): void 
  {
    const dt = eventvalue.toDate();

    this.formValues[i] = dt;
    this.ischeckEdit = true;
  }

  onSelectChange($event, i, enumValues) 
  {
    const value = $event.value;

    this.formValues[i] = this.getEnumValue(value, enumValues);
    this.ischeckEdit = true;
  }

  getEnumValue(value, enumValues) 
  {
    let option;

    if (enumValues.filter) 
    {
      // array
      option = enumValues.filter((opt) => opt.value == value);

      if (option.length) 
      {
        return { value, html: option[0].html };
      }
    }

    return { value: null, html: "" };
  }

  /*------------------------------------------------- UPDATE BUTTON EVENTS ------------------------------------------------------------*/

  @Output() emitState: EventEmitter<any> = new EventEmitter();

  detectFormEdit(event) 
  {
    this.emitState.emit(event)
  }

  async onUpdateMeta(form) 
  {
    const resp = await this.gedDocService.onUpdateGedDocMeta(
      form,
      this.chipContent,
      this.doc,
      this.responseDoc
    );

    this.auth.profileSubject
      .pipe(
        filter(value => !!value),
        tap(user => 
        {
          this.currentName = user.name
        }))
      .subscribe();
    this.pDocsEventsService.updateDocumentEvent(
      this.gedDoc.content?.data?.name,
      this.currentName,
      this.spaceName
    );


    // test rating
    // this.onRate(4,'test fy');

    // this.emitState.emit({reloadDoc:true})
    if (resp != undefined) 
    {
      this.showContent = !this.showContent;
      const curUrl = this.router.url;

      this.router.navigateByUrl("/", { skipLocationChange: false }).then(() => 
      {
        this.router.navigateByUrl(curUrl);
      });
    }
  }

  // onchange event for stars control...
  // to be connected to aura stars
  async onClickStars(rating) 
  {
    this.onRate(rating, "");
  }

  async onRate(rating, comment = "") 
  {
    try 
    {
      const uid = this.doc.uid;
      const resp = await this.gedService.rateDocument(uid, rating, comment);
    }
    catch (error) 
    {
      console.error("Rating failure");
    }
  }

  onCancel() 
  {
    this.showContent = !this.showContent;
    this.emitState.emit(false)
    for (const i in this.formValues) 
    {
      if (this.chipContent[i].dataType == "object")
      {
        this.formValues[i] = { ...this.chipContent[i].value };
      }
      else this.formValues[i] = this.chipContent[i].value;
    }
    //this.formValues[i]=this.chipContent[i].value;
  }
}