import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ListItem } from 'src/design-system/core/interfaces/list-item';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
const $theme = environment.themeUI;

@Component({
  selector: 'app-departments',
  templateUrl: $theme + '/departments.component.html',
  styleUrls: [$theme + '/departments.component.scss'],
})
export class DepartmentsComponent implements OnInit 
{
  isOpen: boolean = false;
  activeTab: string = 'grid';
  departments: ListItem[] = [
    {
      id: 1,
      title: 'Marketing',
      redirectTo: '/groups/marketing',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 2,
      title: 'Project',
      redirectTo: '/groups/project',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 3,
      title: 'Clients',
      redirectTo: '/groups/clients',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 4,
      title: 'Sales',
      redirectTo: '/groups/sales',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
    {
      id: 5,
      title: 'Proposal',
      redirectTo: '/groups/proposal',
      text: 'Lorem ipsum',
      image: '/assets/images/icon-building.svg',
      menuItems: [
        {
          label: 'Edit',
          callback: function (id: number) 
          {
            console.log('Edited Item' + id);
          },
        },
        {
          label: 'Delete',
          callback: function (id: number) 
          {
            console.log('Deleted Item' + id);
          },
        },
        {
          label: 'View',
          callback: function (id: number) 
          {
            console.log('View Item' + id);
          },
        },
      ],
    },
  ];
  constructor(
      private titleService: Title,
      private breadcrumbService: BreadcrumbService
  ) 
  {
    this.titleService.setTitle('Groups');
  }

  ngOnInit(): void 
  {
    this.breadcrumbService.set('@Groups', ' ');
  }
  toggleView(view: string) 
  {
    this.activeTab = view;
  }
  open() 
  {
    this.isOpen = true;
  }
  close() 
  {
    this.isOpen = false;
  }
}
