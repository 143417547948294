<div class="col-12 filesdata mt-2">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Name</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of filesdata">
        <tr>
          <td>
            <div class="itemInfo">
              <img [src]="item.iconLink" class="itemIcon" />
              <span>{{ item.name }}</span>
            </div>
          </td>
          <td class="actionbtn">
            <button
              auraButton
              size="small"
              type="primary"
              [hasShadow]="true"
              label="{{ 'Add this document' | translate }}"
              *ngIf="!item.isFolder && !item.isDrive"
              (click)="selectDocument(item)"
            ></button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
