import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { DocumentPageComponent } from '../../containers';
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'app-doc-infos-sidebar',
  templateUrl: './doc-infos-sidebar.component.html',
  styleUrls: ['./doc-infos-sidebar.component.scss']
})
export class DocInfosSidebarComponent implements OnInit 
{

  @Input('path') path;
  white = "#FFF";

  constructor(private route: ActivatedRoute, private gdocService: GDocService,
    private docPage: DocumentPageComponent, private router: Router, private docService: DocumentService) 
  { }

  ngOnInit(): void 
  {
    this.route.params.forEach(params => 
    {
      this.docId = params["docId"]
      this.isDocinGed(this.docId)

    })

  }


  gedDoc
  @Input() set _gedDoc(value) 
  {
    this.gedDoc = value;
    this.isDocinGed(this.docId);
  }

  docId;
  DocInfos: any = [];
  icon;

  async isDocinGed(docId) 
  {
    // let response = await this.gedService.getDoc(docId);
    const response = this.gedDoc;

    if (response) 
    {
      this.doc = response;

      this.DocInfos = response?.content?.data;
      // this.icon =  res?.content?.data?.icon_link;
      if (this.doc != null && typeof this.doc == "object") 
      {
        this.inGed = true;
      }
      else 
      {
        this.inGed = false;
      }
    }


  }

  toggleBar() 
  {
    this.docPage.toggleGedBar();

  }
  parentId;
  async backClicked() 
  {

    this.parentId = await this.gdocService.getDocumentParent(this.docId);
    if (this.parentId) this.router.navigate(['/s/space/' + this.parentId], { queryParams: { d: localStorage.host_domain } })

  }

  openInDrive() 
  {
    //replace drive url with the real folder url

    this.route.params.forEach(params => 
    {
      const id = params["docId"]

      this.gdocService.getFile(id)
        .then()
        .then(data => 
        {
          this.doc = data;
          window.open(this.doc.webViewLink, "_blank");

        })
    })
  }

  detachFromGed() 
  {
    this.docService.detachFromGed()
  }

  deleteDoc() 
  {
    this.docService.deleteDoc(this.inGed)
  }

  doc = null;
  inGed = false;
  showLinks = true;
  doSomething(showLinks) 
  {
    this.showLinks = showLinks;
  }
}
