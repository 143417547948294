<button mat-icon-button class="example-icon"
matTooltip="{{'waffleBtn' | translate}}"
[matMenuTriggerFor]="gapps">
<mat-icon svgIcon="gapps" class="gapps" aria-hidden="false"></mat-icon>
</button>

<mat-menu #gapps="matMenu">

  <iframe frameborder="0" [src]="urlSafe" width="100%" height="100%">


  </iframe>
</mat-menu>
