import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidepanelService 
{
  private form = new BehaviorSubject({});
  private typeOfForm$$ = new BehaviorSubject('');
  private lastFlaggingEvent$$ = new ReplaySubject(null);
  public lastFlaggingEvent$ = this.lastFlaggingEvent$$.asObservable();

  constructor() 
  { }

  public updateForm(form) 
  {
    this.form.next(form);
  }

  public getForm() 
  {
    return this.form.getValue();
  }

  public getTypeOfForm() 
  {
    return this.typeOfForm$$.getValue()
  }

  public setTypeOfForm(value) 
  {
    this.typeOfForm$$.next(value)
  }

  public addForm() 
  {

  }

  public setLastFlaggingEvent(value) 
  {
    this.lastFlaggingEvent$$.next(value)
  }

}