
import * as englishJson from 'src/assets/i18n/en.json';
import * as frenchJson from 'src/assets/i18n/fr.json';
import * as danishJson from 'src/assets/i18n/da.json';
import * as deutschJson from 'src/assets/i18n/de.json';


import frenchJsonFile from 'src/assets/i18n/fr.json';
import englishJsonFile from 'src/assets/i18n/en.json';
import deutschJsonFile from 'src/assets/i18n/de.json';
import danishJsonFile from 'src/assets/i18n/da.json';

export const stringJsons = [
  frenchJsonFile,
  englishJsonFile,
  deutschJsonFile,
  danishJsonFile
];

export enum EAvailableLocaleName {
  EN = "en",
  FR = "fr",
  DE = "de",
  DA = "da"
}

export type TAvailableLocale = {
  localeName: EAvailableLocaleName,
  languageName: EAvailableLocaleName,
  languageJson: any,
  // languageTs: english,
  multilingueOrder: number;
};

export type TAvailableLocales = TAvailableLocale[];

export const availableLocales: TAvailableLocales = [
  //   {
  //       localeName: "da",
  //       languageName: "da",
  //       languageJson: danishJson,
  //       // languageTs: danish,
  //       multilingueOrder: 2
  //   },
  //   {
  //     localeName: "de",
  //     languageName: "de",
  //     languageJson: deutschJson,
  //     // languageTs: deutsch,
  //     multilingueOrder: 3
  // },
  {
    localeName: EAvailableLocaleName.EN,
    languageName: EAvailableLocaleName.EN,
    languageJson: englishJson,
    // languageTs: english,
    multilingueOrder: 0
  }, {
    localeName: EAvailableLocaleName.FR,
    languageName: EAvailableLocaleName.FR,
    languageJson: frenchJson,
    // languageTs: french,
    multilingueOrder: 1
  }
];
