import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateService, GedService } from 'src/modules/app-common/services';
import { DateAdapter } from '@angular/material/core';
import { Moment } from 'moment';
import { MetaDocument } from 'src/modules/spaces/models/meta/meta-document.model';
import { DocumentService, GedDocService } from '../../services';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const themePath = environment.themePath;

@Component({
  selector: 'app-doc-desc-info',
  templateUrl: `./${themePath}/doc-desc-info.component.html`,
  styleUrls: [`./${themePath}/doc-desc-info.component.scss`]
})
export class DocDescInfoComponent implements OnInit  ,OnChanges
{

  @Input('path') path;
  @Input('canEdit') canEdit;
  @Input("iseditMeta") iseditMeta;

  gedDoc
  @Input() set _gedDoc(value) 
  {
    this.gedDoc= value;
    //this.getDoc()
  }



  formValues=[];
  currentDocId = localStorage.docId;
  showContent = true;
  showMeta=true;
  showAuto=false;
  isLoading=true;
  contentMeta :MetaDocument[]=[];

  doc;
  responseDoc;
  docTemp;

  meta: any;

  chipContent :MetaDocument[]=[];
  ischeckEdit = false

  constructor(private dialog: MatDialog, private gedService: GedService, private _snackBar: MatSnackBar,  private documentService: DocumentService,
    public datepipe: DatePipe, private dateAdapter: DateAdapter<any>,
    private router: Router,
    private dateService: DateService, private gedDocService: GedDocService)
  {

  }
  ngOnChanges(changes: SimpleChanges): void 
  {
    if(changes.iseditMeta)
    {
      if(this.iseditMeta === true)
      {
        this.showContent = false;
      }
    }
   

    
  }
  

    
    
  public ngOnInit(): void 
  {
    this.currentDocId = localStorage.docId;
    const path = localStorage.path
   
    this.getDoc();
  }

  typeDoc;
  getDoc() 
  {
    // to update
    this.responseDoc = this.gedDoc;
    if(this.gedDoc!=undefined)
    {
      this.doc = this.gedDoc.content.data;
      
      this.typeDoc = this.doc.type_document.html;
      const indexx = this.typeDoc.length - 1;

      if (this.typeDoc[indexx] == "s")
      {
        this.typeDoc = this.typeDoc.slice(0, indexx);
      }

      this.getMetas(this.path, this.currentDocId);
    }
      
    
  }
  async  getMetas(path, currentDocId)
  {
    const document = this.gedDoc;
    const meta = document?.['content'].metadata.fields;

    this.isLoading = false;
    this.meta = meta;

    let i = 0;

    for (const [key, value] of Object.entries(this.meta)) 
    {
      const xtag = value["x-tags"] || [];

      if (xtag.includes("meta"))
      {
        const doc =  this.doc[key] || "-";

        this.chipContent.push(new MetaDocument(key , doc ,value));

        //datatype est un objet contenant html et value
        if (this.chipContent[i].dataType == "object")
        {
          if (this.doc[key]?.html == "-" ||
              this.doc[key]?.html == null ||
              this.doc[key]?.value == "-" ||
              this.doc[key]?.value == null)
          {
            this.doc[key] = null;
          }

          this.formValues[i] = { ...this.chipContent[i].value };
        }
        else if (this.chipContent[i].control == "checkbox")
        {
          const enumValues = this.chipContent[i].value?.split(',') || [];

          this.objectEnums.push({
            label : this.chipContent[i].label,
            enumValues
          });
        }

        if (this.chipContent[i].isAuto  && this.chipContent[i].control =="select" ) 
        {
          this.formValues[i] = this.chipContent[i].value;
           
                
        }  

        if (this.chipContent[i].control == "checkbox" && this.chipContent[i].isAuto )
        {
          const valChecked = []
          const url = this.chipContent[i].autoUrl
          const enumsOthers =  await this.gedService.getEnumsValues(url)

          this.othserPartners = enumsOthers
          this.chipContent[i].enumValues = enumsOthers
          const valueChip =this.chipContent[i].value ;

          // this.chipContent[i].value =  array.split('|').join(' ').substr(1).slice(0, -1)

          // this.chipContent[i].value = "";
          if(valueChip != null && valueChip != "")
          {
            const t = valueChip.split('|').join(' ').substr(1).slice(0, -1)
            const split_string = t.split(" ");

            split_string.forEach((item)=>
            {
              valChecked.push(this.filterCheckbox(item))
            })

            valChecked.sort((a, b) => (a > b ? 1 : -1));

            this.objectEnums.push({
              label : this.chipContent[i].label,
              enumValues : valChecked
            });

            if(valChecked[0]!=undefined)
            {
              this.chipContent[i].value  =valChecked.toString()
              this.formValues[i] = this.chipContent[i].value;
            }
          }

          /* if( this.chipContent[i].value = '||'){
          this.chipContent[i].value = "  "
          }
          */
          else
          {
            this.chipContent[i].value ="";
          }
        }
        else
        {
          this.formValues[i] = this.chipContent[i].value;
        }

        i++;
      }
    }
    if (this.chipContent.length == 0)
    {
      this.showMeta = false;
    }
    else
    {
      this.showMeta = true;
    }
  }
  
  /*------------------------------------------------- Checked value multiple------------------------------------------------------------*/
  objectEnums = []

  othserPartners= []
  objectEnumss = []

  isEnumObjects = []
  checkedObjs

  isChecked(item , label)
  {
    let  ischekd
 

    this.objectEnums?.forEach((obj)=>
    {
      if(obj.label = label)
      {
        const k =  obj?.enumValues.forEach((tc)=>
        {
          if(tc)
          {
            if(tc?.indexOf(item) !== -1)
            {
              ischekd = true
 
              return ischekd 
            }
          }
        })   
      }

      return ischekd ? true : false;

    }
    )
    // console.log("formvaluesiiiobj",ischekd)

    return ischekd ? true : false;


  }

  onChange(key: string, isChecked: boolean,label: string , i) 
  {
    this.ischeckEdit = true
   
    if (isChecked) 
    {

      if (key['key'])
      {
        if (this.chipContent[i].value.indexOf(key['value']) === -1 ) 
        {
              
          const valuesArray:any[] = (this.chipContent[i].value.split(',')||[]).filter(item=>item!=="")
      
          valuesArray.push(key['value'])
          this.chipContent[i].value = valuesArray.join(',')
        }
            
    
      }    
      if(key['html'])
      {
        if (   this.chipContent[i].value.indexOf(key['html']) === -1 ) 
        {
            
          const valuesArray:any[] = (this.chipContent[i].value.split(',')||[]).filter(item=>item!=="")
     
          valuesArray.push(key['html'])
          this.chipContent[i].value = valuesArray.join(',')
     
        }
      }
     
    }
    else 
    {
       
     
      if(key['html'])
      {
        const valuesArray = this.chipContent[i].value.split(',').filter(item=>item!=key['html']).filter(item=>item!=="")

        this.chipContent[i].value = valuesArray.join(',')
      } if (key['key'])
      {
        const valuesArray = this.chipContent[i].value.split(',').filter(item=>item!=key['value']).filter(item=>item!=="")

        this.chipContent[i].value = valuesArray.join(',')
      }
    }



      
    
    this.objectEnumss=[{
      label :  this.chipContent[i].label,
      value : this.chipContent[i].value
    }]


 

 
  }
  filterCheckbox(value)
  {
    // const labels;
  // console.log("split_string",obj.html)
    const label =  this.othserPartners.filter((obj)=>
    {

      if(obj.value == value)
      {
        return obj.html
      }

    //obj.value = value
    })

    if( label[0])
    // const labels =     this.othserPartners[value] ;
    {
      return label[0]['html']
    } 
  }



  /*------------------------------------------------- THE GETS ------------------------------------------------------------*/


  

  getAutocompleteMeta(key,i)
  {
    this.chipContent[i].showAuto=true;
    this.chipContent[i].autoUrl=this.meta[key]["x-dynamic-values"]["url"];
    this.chipContent[i]["field-title"]=this.meta[key]["x-dynamic-values"]["field-title"];
    this.chipContent[i]["field-value"]=this.meta[key]["x-dynamic-values"]["field-value"];
  }

  /*------------------------------------------------- USER INPUT EVENTS ------------------------------------------------------------*/

  doFilter(i:number) 
  {
    if(this.formValues[i].html=='-')
    {}
    else if(this.formValues[i].html=='' || this.formValues[i].html==' ')
    {
      this.chipContent[i].autoComplete=[]
      this.formValues[i]={html: "-",value: "-"};
 
    }
    else
    {
      this.gedService.getAutocompleteList(this.formValues[i].html,this.chipContent[i].autoUrl)
        .then(res=>
        {
          this.chipContent[i].autoComplete=res;
          for(const item of this.chipContent[i].autoComplete)
          {
            if(this.formValues[i].html == item.html) this.formValues[i]=item;
            else this.filter(item,i)
          }
        })
    }

  }

  filter(values,i: number) 
  {
    return values.filter(option =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      option.toLowerCase().includes(this.formValues[i].html)
    )
  }
  onInputChange(i)
  {
  }


  OnDateChange(eventvalue:Moment,i:number): void 
  {
    const dt = eventvalue.toDate();

    this.formValues[i]=dt;
 
    //fullTime
    //'2021-04-23T20:37:34.000Z'
    //'yyyy-MM-ddThh:mm:ss'

  }


  /*------------------------------------------------- UPDATE BUTTON EVENTS ------------------------------------------------------------*/

    @Output() emitState: EventEmitter<any> = new EventEmitter();
    async onUpdateMeta(form) 
    {
 
 
      const resp = await this.gedDocService.onUpdateGedDocMeta(form,this.chipContent,this.doc,this.responseDoc)
      
    
      this.emitState.emit({reloadDoc:true})
      if (resp != undefined) 
      {
        this.showContent = !this.showContent;
   
        let url = this.documentService.getRoutebyMimeType(resp?.mimeType);
   
        if (resp?.uid) 
        {
   
          url += resp?.uid;
          this.router
            .navigateByUrl("/", { skipLocationChange: false })
            .then(() => 
            {
              this.router.navigateByUrl(url);
            });
        }  
      
      }
   
    }
  
    onCancel()
    {
      this.showContent=!this.showContent;
      for(const i in this.formValues)
      {
        if(this.chipContent[i].dataType=='object') this.formValues[i]={...this.chipContent[i].value}
        else this.formValues[i]=this.chipContent[i].value
      }
      //this.formValues[i]=this.chipContent[i].value;

    }
}
