import { Component } from '@angular/core';

@Component({
  selector: 'app-handle-failure',
  templateUrl: './handle-failure.component.html'
})
export class HandleFailureComponent 
{
  constructor() 
  {

  }



}
