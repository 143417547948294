import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeopleRoutingModule } from './people-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppCommonModule } from '../app-common/app-common.module';

import * as peopleContainers from './containers';
import * as peopleComponents from './components';

@NgModule({
  declarations: [...peopleContainers.containers, ...peopleComponents.components,],
  imports: [
    CommonModule,
    PeopleRoutingModule,
    AppCommonModule,
    PeopleRoutingModule,
    // BrowserModule,
    MaterialModule,
    // BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
  ],
  exports: [PeopleRoutingModule, ...peopleContainers.containers]
})
export class PeopleModule 
{ }
