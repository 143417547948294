<div class="row" style="justify-content: center;">
  <h1 mat-dialog-title>{{dialogData.action.dialog.title}}</h1>
</div>
<div mat-dialog-content>
  <form #f="ngForm">
    <div *ngFor="let d of dialog">
      <div class="row" style="align-content:center; justify-content: center; margin: 0px!important">
        <mat-form-field *ngIf="d.type!='file'">
          <mat-label>{{d.label}}</mat-label>
          <!--<input [(ngModel)]="d.value" type="{{d.type}}">-->
          <input matInput [(ngModel)]="d.value" type="{{d.type}}" name="d.value" cdkFocusInitial> 
        </mat-form-field>

        <mat-form-field *ngIf="d.type=='file'">
          <mat-label>{{d.label}}</mat-label>
          <!-- <input type="file">  -->
          <ngx-mat-file-input  [(ngModel)]="d.value" name="value">

          </ngx-mat-file-input>
        
            <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
  </form>
  <div mat-dialog-actions class="col-12 actions"  align="end">
    <div style="padding-right: 31px;">

      <button mat-button mat-dialog-close class="cancelBtn">{{'cancelBtn' | translate | uppercase}}</button>
      <button mat-button class="submitBtn" type="submit" (click)="onActionButton(f.value)"
      [mat-dialog-close]="dialogData.action">{{'sendBtn' | translate | uppercase}}</button>
    </div>
  </div>
</div>
