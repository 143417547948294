import { Role } from "../role/role.model";

export class Action 
{
  name: string
  id: any;
  role: Role;
  dialog: any;
  button_class: any;
}
