import { TemplateRef } from '@angular/core';
import { TTableColumn } from './table.type';

export interface ITableColumn {
  id: string;
  type: TTableColumn;
  _action?: Function;
  canSort?: boolean;
  label?: string;
  order?: 'DESC' | 'ASC';
  width?: number;
  class?: string;
}

export interface ITableRow {
  id: string;
  value: string;
  html?: TemplateRef<any>;
  context?: { [name: string]: string };
  sortValue?: string;
  bold?: boolean;
  iconClass?: string;
  iconImg?: string;
  isCircle?: boolean;
  link?: string;
  menuItems?: ITableRowMenuItem[];
  onClick?: (row: ITableRow[]) => void;
  subtitle?: string;
}

export interface ITableRowMenuItem {
  callback: Function;
  label: string;
  icon?: string;
}
