<app-sidenav [inGed]="inGed" [title]="state?.title" [breadcrumb]="bc">
  <mat-drawer-container class="example-container crud-container">

    <div class="px-4 mt-5">
      <app-form-manager *ngIf="!loading"
        [inputData]="inputData"
        [type]="'form'"
        (onFormCancelEvent)="onCancel()"
        (onFormSubmitEvent)="onSubmit($event)"></app-form-manager>
    </div>

    <div class="spinner-container">
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  </mat-drawer-container>
</app-sidenav>
