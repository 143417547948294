import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DocType } from "src/modules/app-common/models/doc-type/doc-type.model";
import { DoctypeDialogData } from "src/modules/app-common/models/doctype-dialog-data/doctype-dialog-data.model";
import { GedService } from "src/modules/app-common/services";
import { SpaceService } from "src/modules/spaces/services";
import { DocumentService } from "../../services/document.service";

const themePath = environment.themePath;


@Component({
  selector: "app-doc-type-add",
  templateUrl: `./${themePath}/doc-type-add.component.html`,
  styleUrls: [`./${themePath}/doc-type-add.component.scss`],
})
export class DocTypeAddComponent implements OnInit ,OnChanges
{

  formFields = [];
  temporaryList = [];
  isLoading = false;

  previewLink: string;
  temp = null;

  docType =
    {
      oid: "",
      title: "",
    };

  fieldAutoList = [];
  inGed = false;
  showForm = false;
  done = false;

  templateId = "";
  spaceId = "";

  autoComplete;
  listeSpaces = [];
  listeTemplates = [];

  formFieldsData = {};
  docTypeLabel;
  docTypeValue;

  @ViewChild("drawer") public drawer: MatDrawer;

  docInfos;
  title: string = environment.stringsFile.docInfos;

  constructor(
    //  public dialogRef: MatDialogRef<DocTypeAddComponent>,
    private gedService: GedService,
    private spaceService: SpaceService,
    private documentService: DocumentService,
    private router: Router
  )
  {
  }


  ngOnInit(): void
  {
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if(changes.dataLink)
    {
      if(this.dataDialog)
      {
        this.parentId = this.dataDialog.parentId
        this.docInfos= this.dataDialog["add"][0];
      }

      if(this.dataLink &&  this.parentId)
      {
        this.parentId = this.parentId
        this.docInfos = this.dataLink[0];
      }

      this.loadFormData();
    }

  }
  @Input("dataLink") dataLink;
  @Input("parentId") parentId;

  @Inject(MAT_DIALOG_DATA) public dataDialog: DoctypeDialogData;
  public dialogRef: MatDialogRef<DocTypeAddComponent>;

  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<any>();

  closeModal()
  {
    this.close.emit();
  }


  onCancel(): void
  {
    this.close.emit();
    // this.dialogRef.close();
  }



  async getSpacebyType(type)
  {
    const space = this.spaceService.getSpacebySid(type);

    return space;
  }

  onSelectedValue(field, value)
  {
    if (field.key == "template")
    {
      for (const item of field.fieldAutoList)
      {
        if (value.html == item.html)
        {
          const id = item?.infos.id || null;

          this.previewLink = `https://drive.google.com/thumbnail?sz=w300&id=${id}`;
        }
      }
    }
  }

  loadFormData()
  {
    this.gedService
      .getMetaDocByType(this.docInfos.oid, this.parentId, true, true,true)
      .then((res) =>
      {
        const dd = Object.entries(res["data"]);

        this.docTypeLabel = dd[0][0] || null;
        this.docTypeValue = dd[0][1] || null;

        //this.showForm = true;
        const data = res["data"];
        const metadata = res["metadata"]["fields"];

        for (const key of Object.keys(metadata))
        {
          if(metadata[key]["x-show"] !=false && metadata[key]["show"] !=false)
          {
            let element: DocType;

            element = new DocType(
              key,
              data[key],
              metadata[key],
              this.gedService
            );

            //temporary
            //begin
            if (element.getAuto() != null  && element.getControl() =="select" )
            {

              this.gedService.getAutocompleteListSelect(element.getUrlAuto())
                .then((autolist) =>
                {

                  let tempList = [];

                  tempList = autolist || [];

                  for (const item of tempList)
                  {

                    element.fieldAutoList.push(item);
                  }
                });

            }

            if (element.getAuto() != null) 
            {
              if (
                element.getKey() == "space" ||
                element.getKey() == "template"
              ) 
              {
                const Query = metadata[key]["x-dynamic-values"]["query"];

                if(Query)
                {
                  this.gedService
                    .getAutocompleteList(
                      element.getValue(),
                      element.getUrlAuto(),
                      this.docInfos.oid,
                      Query
                    )
                    .then((autolist) => 
                    {
                      let tempList = [];

                      if (autolist?.length != 0 && autolist != undefined) 
                      {
                      //  element.setValue(autolist[0]);
                        element.value = { ...autolist[0] };
                        element.setPreviewLink(autolist[0]);
                      }

                      tempList = autolist || [];

                      for (const item of tempList)
                      {
                        if (element.getKey() == "space") 
                        {
                          this.listeSpaces = element.fieldAutoList;

                          const space = this.getSpacebyType(item.html);
                        }
                        element.fieldAutoList.push(item);
                      }

                      if (element.getKey() == "template")
                      {
                        this.listeTemplates = element.fieldAutoList;
                        this.previewLink = element.getPreviewLink();
                      }
                    });
                }
              }
            }
            else
            {
              this.gedService
                .getAutocompleteList(element.getValue(), element.getAuto())
                .then((autolist) =>
                {
                  let tempList = [];

                  tempList = autolist || [];

                  for (const item of tempList) 
                  {
                    element.fieldAutoList.push(item);
                  }
                });
            }

            //end.

            if (element?.value?.value != this.docTypeValue.value)
            {
              this.formFields.push(element);
              this.temporaryList.push(element);
            }

          }
        }
      });
  }

  async getIdSpace(field)
  {
    const spaces = this.listeSpaces;

    for (let i = 0; i < spaces.length; i++)
    {
      const space = spaces[i];

      if (space["html"] == field) 
      {
        return space;
      }
    }

    return null;
  }

  async getIdTemplate(field)
  {
    const themes = this.listeTemplates;

    for (let i = 0; i < themes.length; i++) 
    {
      const t = themes[i];

      if (t["html"] == field) return t;
    }

    return null;
  }

  async onCreateDoc(values)
  {
    const spaceId = await this.getIdSpace(values["space"]);
    const templeteId = await this.getIdTemplate(values["template"]);

    this.done = true;
    this.isLoading = true;
    const data = {};

    for (const item of this.formFields)
    {
      const keey = item.key;

      if (item.auto == null)
      {
        this.formFieldsData[item.key] = item.value;
      }
      else
      {
        if (item.key == "space")
        {
          this.formFieldsData[item.key] = {
            value: spaceId["value"],
            html: item.value.html,
          };
        }

        if (item.key == "template")
        {
          this.formFieldsData[item.key] = {
            value: templeteId["value"],
            html: item.value.html,
          };
        }
      }
    }
    for (const item of this.formFields) 
    {
      if (item.key == "template") 
      {
        this.templateId = templeteId["uid"] || null;
      }
    }

    this.formFieldsData[ this.docTypeLabel] =
    {
      value:  this.docTypeValue['value'],
      html:this.docTypeValue['html'],
    }

    this.formFieldsData["file_oid"] = "";

    if (this.templateId != null)
    {
      this.gedService
        .createNewDocInGED(
          this.docInfos.oid,
          this.formFieldsData,
          this.templateId,
          "root"
        )
        .then((resp) =>
        {
          this.isLoading = false;
          if (resp != undefined) 
          {
            let url = this.documentService.getRoutebyMimeType(resp?.mimeType);

            if (resp?.id) 
            {
              url += resp.id;
              //  this.dialogRef.close(resp);
              this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => 
                {
                  this.router.navigate([url]);
                });
            }
          }
        });
    }
  }
}
