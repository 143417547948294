import { environment } from "src/environments/environment";
import { IUserData } from "./gcontributor-item.model";
import { TableRow } from "@interfaces/table";
const DOTS_ENABLED = environment.DOTS_ENABLED

const gmailSearch = environment.gmailSearchLink
const contactLink = environment.contactLink


export class GcontributorPerson 
{

  mailAddresses: string[] | null;
  primaryMail: string | null;
  photo: string | '';
  name: string;
  resourceName: string | null;
  contactId?: string;
  keyWord: string | undefined;
  files: string[][];
  displayProperties: TableRow[];
  nbFiles: number;

  /**
     * Constructor for GcontributorPerson class.
     * @param item - The ContributorObject containing contributor information.
     * @param keyWord - Optional keyword for filtering.
     */
  constructor(item: IUserData, keyWord?: string)
  {
    this.keyWord = keyWord;
    this.setMail(item);
    this.setName(item);
    this.setPhoto(item);
    //this.setResourceName(item);
    this.setNbFiles(item);
    this.getDisplayProperties();
  }

  /**
     * Retrieves and formats display properties for the contributor.
     * @returns An array of display properties.
     */
  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties =  [
      {
        id: 'name',
        value: this.getName(),
        iconImg: this.getPhoto(),
        onClick: function () 
        {
          window.open(contactLink+''+self?.primaryMail, '_blank');

        }
      },
      {
        id: 'details',
        value: this.getNbFiles()+' documents referring to "'+ this.getKeyWord()+'"',
      },
      {
        id: 'mail',
        value: this.getMail(),
      }

    ]

    if (DOTS_ENABLED) {
      this.displayProperties.push(

        {
        id: 'Menu',
        value: '',
        menuItems: [
          {
            label: 'Exchanged emails',
            callback: function () 
            {
              window.open(gmailSearch+self.primaryMail, '_blank');

            },
                      
          },
          /*{
                    label: 'Contact',
                    callback: function () {
                        window.open(contactLink+'person'+self?.resourceName.substring(self?.resourceName.indexOf('/')), '_blank');

                    },
                    
                 },*/

        ],
      }
      )

    }

    return this.displayProperties;
  }


  /**
     * Sets the primary mail property based on the ContributorObject.
     * @param item - The ContributorObject containing contributor information.
     */
  setMail(item: IUserData)
  {
    this.primaryMail = item?.emailAddress || '';
  }

  /**
     * Sets the name property based on the ContributorObject.
     * @param item - The ContributorObject containing contributor information.
     */
  setName(item: IUserData)
  {
    this.name = item?.displayName || '';
  }

  /**
     * Sets the photo property based on the ContributorObject.
     * @param item - The ContributorObject containing contributor information.
     */
  setPhoto(item: IUserData)
  {
    this.photo = item?.photoLink || '';
  }

  /**
     * Sets the resource name property based on the ContributorObject.
     * @param item - The ContributorObject containing contributor information.
     */    
  /*setResourceName(item: IUserData){
        this.resourceName = item?.resourceName;
    }
*/
    

  /**
     * Gets the primary mail property.
     * @returns The primary mail address.
     */
  getMail()
  {
    return this.primaryMail;
  }

  /**
     * Gets the name property.
     * @returns The contributor's name.
     */
  getName()
  {
    return this.name;
  }

  /**
     * Gets the photo property.
     * @returns The URL of the contributor's photo.
     */
  getPhoto()
  {
    return this.photo;
  }

  /**
     * Gets the resource name property.
     * @returns The resource name of the contributor.
     */
  getResourcename()
  {
    return this.resourceName;
  }

  /**
     * Gets the contact ID property.
     * @returns The contact ID of the contributor.
     */
  getContactId()
  {
    return this.contactId;
  }

  /**
     * Gets the keyword property.
     * @returns The keyword for filtering.
     */
  getKeyWord()
  {
    return this.keyWord;
  }

  /**
     * Counts and returns the number of files property.
     * @returns The number of files associated with the contributor.
     */
  setNbFiles(item: IUserData)
  {
    return this.nbFiles = item?.nbFiles;
  }

  /**
     * Gets the nbFiles property.
     * @returns The nbFiles for filtering.
     */
  getNbFiles()
  {
    return this.nbFiles;
  }
}
