import { Injectable, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AppTitleService implements OnDestroy 
{

  private _routerSubscription?: Subscription;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _titleService: Title
  ) 
  { }

  ngOnDestroy(): void 
  {
    this._routerSubscription?.unsubscribe();
  }

  autoUpdateTitle(): void 
  {
    this._routerSubscription = this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => 
        {
          const input = "/search?";
          const isSearch =
            this._activatedRoute.snapshot["_routerState"].url.substring(
              0,
              input.length
            ) === input;

          if (!isSearch)
          {
            this._router.navigate([], {
              queryParams: { d: localStorage.host_domain },
              queryParamsHandling: "merge",
            });
          }

          let child = this._activatedRoute.firstChild;

          while (child) 
          {
            if (child.firstChild) 
            {
              child = child.firstChild;
            }
            else if (child.snapshot.data && child.snapshot.data["title"]) 
            {
              return child.snapshot.data["title"];
            }
            else 
            {
              return null;
            }
          }

          return null;
        }),
        filter(Boolean)
      ).subscribe((data: string) => 
      {
        this._titleService.setTitle(data + " - " + environment.appName);
      });
  }

}
